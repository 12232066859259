import { Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
export const NAVIGATE_ROUTES = {
    // LOGIN: 'user/sign-in',
    // LOGOUT: environment.API_URL + 'user/logout',
    // FORGOT_PASSWORD: environment.url + 'user/forgot-password',
    // VERIFY_FORGOT_PASSWORD_TOKEN: 'user/token-verify',
    // SET_NEW_PASSWORD: environment.url + 'user/password',
    // SIGN_UP_VERIFICATION: 'user/verification',
    // SIGN_UP: environment.url + 'user/signup',
    // CHANGE_PASSWORD: environment.userUrl + 'profile/reset-password',
    // UPDATE_PROFILE: environment.userUrl + 'profile/update-profile',
    // GET_USER_DETAILS: environment.userUrl + 'profile/user-details/',
    SWITCH_COMPANY: environment.userUrl + 'profile/switch-token',
    // LOGOUT_USER: '/logout',
    // TEAM_GROUP_MANAGEMENT: environment.API_URL + 'organization/group',
    // COMPANY: environment.API_URL + 'organization/company',
    // COMPANY_COUNT: environment.API_URL + 'organization/company/count',
    // ADD_NEW_MEMBER: environment.API_URL + 'organization/members/check-user',
    // DELETE_MEMBER: environment.API_URL + 'organization/members',
    // RolManagement: environment.API_URL + 'organization/role',
    // ADD_MEMBER: environment.API_URL + 'organization/members',
    // GET_MEMBER: environment.API_URL + 'organization/members',
    // UPDATE_MEMBER: environment.API_URL + 'organization/members',
    // ROLE_MANAGEMENT: environment.API_URL + 'organization/role',
    // GET_STAFF_MEMBER: environment.API_URL + 'organization/members',
    // SERVICE_MANAGEMENT: environment.API_URL + 'organization/service',
    // SERVICE_MANAGEMENT_EXPORT: environment.API_URL + 'organization/service/export-service-list',
    // CLIENT: environment.API_URL + 'organization/client/',
    // STATUS: environment.API_URL + 'organization/status',
    // MEMBERLISTFORSERVICE: environment.API_URL + 'organization/',
    // ORGANIZATION_SETTING: environment.API_URL + 'organization/generalSettings',
    // EMAIL_SETTING: environment.API_URL + 'organization/emailSettings',
    // RE_EMAIL_SYNC: environment.API_URL + 'organization/emailSettings/re-sync-email',
    // MARK_AS_COMPLETE:
    // environment.API_URL + 'organization/company/markAsApproveSetting',
    // GET_MARK_AS_COMPLETE:
    // environment.API_URL + 'organization/company/getApproveSetting',
    // MEMBER_LIST: environment.API_URL + 'organization/',
    SUBSCRIPTION_MANAGEMENT: environment.subscriptionUrl + 'subscription',
    // BILLING_DETAIL: environment.userUrl + 'profile/billingDetails',
    // GET_BILLING_DETAIL: environment.userUrl + 'profile/billingDetails',
    GET_SUBSCRIPTION_ACTIVE_PLAN:
    environment.subscriptionUrl + 'subscription/currentActivePlan',
    FREE_TRIAL_SUBSCRIPTION:
    environment.subscriptionUrl + 'subscription/subscribe-free-trial',
    // GET_ACTIVE_USER: environment.API_URL + 'organization/members/totalMembers',
    // CUSTOM_FIELD_SETTING: environment.API_URL + 'custom-fields',
    // TASK_CLIENT_CUSTOM_FIELD:
    // environment.API_URL + 'custom-fields/task-custom-fields',
    // TIMESHEET_SETTING_BFF: environment.API_URL + 'timesheet',


    // TIMESHEET_SETTING: environment.API_URL + 'organization/timesheet',



    // CHECKLIST_SETTING: environment.API_URL + 'check-list-template',
    ADD_COMMENT: environment.API_URL + 'chat',
    GET_COMMENT: environment.API_URL + 'chat',
    // TASKS_URL: environment.API_URL + 'task',
    TASKS_URL_OPEN_SEARCH: environment.API_URL + 'task/atlas-tasks',
    // MODULE_DETAIL: environment.API_URL + 'custom-fields/modules',
    // CUSTOM_FIELD_CONFIG: environment.API_URL + 'configuration',
    // TASK_PRIORITY: environment.API_URL + 'task/task-priority',
    // TASK_STATUS: environment.API_URL + 'task/task-status',
    // TASK_REMINDER: environment.API_URL + 'task-reminder',
    // TASK_UPDATE_REMINDER: environment.API_URL + 'task-reminder/toggle-reminder',
    // REMOVE_TASK_ATTATCHMENT: environment.API_URL + 'bins/file',
    // PIN_TASK_URL: environment.API_URL + 'task/pin-unpin',
    // TASK_ATTACHMENT: environment.API_URL + 'dms/get-task-attachment',
    // TASK_PROGRESS: environment.API_URL + 'task/update-percentage-by-taskId',
    // TASK_ORDER: environment.API_URL + 'task/task-order',
    // TASK_READ_UNREAD: environment.API_URL + 'task/task-read',
    // TASK_RECYCLE_BIN_TASKS_LISTS: environment.API_URL + 'task/recycle-bin',
    // RESTORE_RECYCLE_BIN_TASK: environment.API_URL + 'task/restore',
    // PERMANENT_DELETE_TASK: environment.API_URL + 'task/recycle-bin-task',
    // BULK_DELETE_TASK: environment.API_URL + 'task/bulk-remove',
    READ_UNREAD_DATA: environment.API_URL + 'task/task-read-status',
    TASK_ACTIVITY_LOG: environment.API_URL + 'task/activity-log',
    // GET_DMS_LIST: environment.API_URL + 'dms',
    // ADD_FOLDER: environment.API_URL + 'dms/folder',
    // ADD_FILE: environment.API_URL + 'dms/file',
    UPLOAD_FILE: environment.API_URL + 'dms/upload-file',
    // CHANGE_FOLDER_COLOR: environment.API_URL + 'dms/color',
    // GET_COMPANY_ACCESSES: environment.API_URL + 'dms/getCompanyAccesses',
    // GET_PATH: environment.API_URL + 'dms/path',
    // GET_PROGRESS: environment.API_URL + 'dms/progress',
    // RECENT: environment.API_URL + 'recents',
    // GET_BIN: environment.API_URL + 'bins',
    // EMPTY_BIN: environment.API_URL + 'bins/empty',
    // DELETE_FOLDER_BIN: environment.API_URL + 'bins/folder',
    // DELETE_FILE_BIN: environment.API_URL + 'bins/file',
    // RESTORE_BIN: environment.API_URL + 'bins/restore',
    // FAVOURTE_DMS: environment.API_URL + 'favourite',
    // UNFAVOURTE_DMS: environment.API_URL + 'favourite/unfavourite',
    // FAVOURTE_DMS_LIST: environment.API_URL + 'favourite',
    // GET_ROOT_TEAM_FOLDERS: environment.API_URL + 'team/root',
    // GET_TEAM_FOLDER: environment.API_URL + 'team',
    // MARK_AS_APPROVAL_FLOW:
    // environment.API_URL + 'organization/company/isMarsAsApprovalFlow',
    // MARK_AS_UNDER_REVIEW: environment.API_URL + 'task/markAsUnderReview',
    // MARK_AS_APPROVE_REJECT: environment.API_URL + 'task/taskApproveOrReject',
    GET_TIME_LOG: environment.API_URL + 'timesheet/task/timesheet-list',
    ON_OFF_TIMER: environment.API_URL + 'timesheet/save',
    TIMESHEET_FIELD_CONFIG: environment.API_URL + 'timesheet/config-fields',
    DOC_ADD_IN_TASK: environment.API_URL + 'dms/add-task-attachment',
    // GET_SHARED_DMS_LIST: environment.API_URL + 'share',
    // SHARE_DMS: environment.API_URL + 'share',
    // COPY_FILE: environment.API_URL + 'dms/copy',
    taskDetailById: environment.API_URL + 'task/open-search-task',
    taskDetailByIdAtlas: environment.API_URL + 'task/atlas-search-task',
    TASK_SAMPLE_FILE: environment.API_URL + 'task/sampleFile',
    // TASKS_KANBAN_URL: environment.API_URL + 'pipeline',
    UPDATE_TOKEN: environment.API_URL + 'update-token',
    // BULK_IMPORT_TASK: environment.API_URL + 'task/bulk-import',
    // DMS_DETAILS: environment.API_URL + 'dms/details',
    GET_PIPELINE_TASK_KANBAN: environment.API_URL + 'pipeline/get-pipeline-task',
    GET_PIPELINE_ATLAS_TASK_KANBAN: environment.API_URL + 'pipeline/get-pipeline-task-atlas',
    // DRAG_DROP_TASK: environment.API_URL + 'pipeline/update-task-index',
    GET_PROJECT_DEFAULT_VIEW: environment.API_URL + 'project/default-view',
    PROJECT: environment.API_URL + 'project',
    PROJECT_DETAIL_BY_ID: environment.API_URL + 'project/detail',
    PROJECT_FAVORITE: environment.API_URL + 'project/favourite',
    PROJECT_REMOVE_MEMBERS: environment.API_URL + 'project/member-remove',
    // DISCUSSION_LIST: environment.API_URL + 'discussion/list',
    // DISCUSSION_ADD: environment.API_URL + 'discussion/add',
    // DISCUSSION_EDIT: environment.API_URL + 'discussion/edit',
    // DISCUSSION_DELETE: environment.API_URL + 'discussion/delete',
    // DISCUSSION_SEARCH: environment.API_URL + 'discussion/search',
    // DISCUSSION_FAVOURITE: environment.API_URL + 'discussion/favourite',
    // DISCUSSION_DETAILS: environment.API_URL + 'discussion/details',
    DISCUSSION_MEDIA_DETAILS: environment.API_URL + 'discussion/details',
    PROJECT_LIST: environment.API_URL + 'project/list',
    PROJECT_DETAIL: environment.API_URL + 'project/detail',
    // DISCUSSION_IMAGES: environment.API_URL + 'discussion/images',
    // ADD_NOTE: environment.API_URL + 'note/',
    // PIN_NOTE: environment.API_URL + 'note/pin/',
    // SHARE_NOTE: environment.API_URL + 'note/share-notes',
    // SHARE_NOTES: environment.API_URL + 'note/share/',
    // TASK_FAV_FILTER: environment.API_URL + 'task/favorite-filter/',
    // NOTIFICATION_USER: environment.API_URL + 'notification/settings/users/',
    // NOTIFICATION_SETTING: environment.API_URL + 'notification/settings',
    // NOTIFICATION_PERMISSION: environment.API_URL + 'notification/settings/change-permission',
    // NOTIFICATION_LIST: environment.API_URL + 'notification/',
    // NOTIFICATION_COUNT: environment.API_URL + 'notification/unread/count',
    // READ_ALL_NOTIFICATION: environment.API_URL + 'notification/mark-as-read-notifications',
    // DISCUSSIONLIST_SEARCH: environment.API_URL + 'discussion/search',
    // GET_FAVOURITE_MEMBER: environment.API_URL + 'task' + '/favourite-members',
    GET_TASK_LIST_FOR_LOG: environment.API_URL + 'timesheet/taskDetails',
    // GET_PROJECT_ALL_TASKS: environment.API_URL + 'task/project-tasks',
    GET_TIMESHEET_TIMER: environment.API_URL + 'timesheet/timeSheetTimer',
    DELETE_TIMELOG_DATA: environment.API_URL + 'timesheet/timeLog',
    // SAVE_OFFLINE_COMMENT: environment.API_URL + 'discussion/saveComments',
    GET_TIMESHEET_TIMELOG_REPORT:
    environment.API_URL + 'timesheet/timesheet-report/time-log',
    GET_TIMESHEET_PENDING_APPROVAL_REPORT:
    environment.API_URL + 'timesheet/timesheet-report/pending-approval',
    GET_COMMENT_BY_DATE: environment.API_URL + 'timesheet/timesheet-comment',
    SUBMIT_TIMESHEET: environment.API_URL + 'timesheet/submit',
    APPROVE_REJECT_TIMESHEET: environment.API_URL + 'timesheet/approve',
    APPROVE_REJECT_ALL_TIMESHEET: environment.API_URL + 'timesheet/approve-all',
    GET_DETAILS_TIMELOG:
    environment.API_URL + 'timesheet/timesheet-report/task-time-log',
    GET_ESTIMATED_TIME: environment.API_URL + 'timesheet/estimated-time',
    // GET_USER_LIST_BY_GROUP:
    // environment.API_URL + 'organization/group/group-member-details',
    GET_PROJECT_CHART_DATA: environment.API_URL + 'project/chart',
    // GET_TASK_ANALYSIS: environment.API_URL + 'task' + '/analytics',
    // GET_DASHBOARD_TASKS: environment.API_URL + 'dashboard/atlas-tasks',
    // GET_DASHBOARD_SUMMARY: environment.API_URL + 'dashboard/summary',
    GET_RECENT_PROJECTS: environment.API_URL + 'dashboard/recent-project',
    GET_DASHBOARD_STATISTICS: environment.API_URL + 'task/statistics',
    GET_DASHBOARD_TEAM_INCOMPLETE_TASK:
    environment.API_URL + 'dashboard/team-incomplete-tasks',
    GET_NEXT_PREVIOUS_TASK: environment.API_URL + 'task/atlas-search-subtasks/',
    // BULK_CLIENT_DELETE: environment.API_URL + 'organization/client/delete',
    // BULK_EXPORT_CLIENT: environment.API_URL + 'organization/client/export',
    // BULK_EXPORT_MEMBER: environment.API_URL + 'organization/members/export',
    CREDIT_MEMBER:
    environment.subscriptionUrl + 'subscription/create-employee-plan',
    // EMAIL_COMPANY_LIST:
    // environment.API_URL + 'organization/emailSettings/companies',
    // STOP_RECURRING_TASK: environment.API_URL + 'task/stop-recurring',
    // CLIENT_SAMPLE_FILE: environment.API_URL + 'organization/client/sampleFile',
    // GET_EMAIL_LIST:
    // environment.API_URL + 'organization/emailSettings/get-configure-email',
    // GET_EMAIL_DETAIL:
    // environment.API_URL + 'organization/emailSettings/get-email-list',
    // GET_COUNT_CREDIT: environment.API_URL + 'organization/members/members-count',
    // ADD_TASK_EMAIL:
    // environment.API_URL + 'organization/emailSettings/add-email-task',
    // GET_TIME_LOG_USER_LIST: environment.API_URL + 'report/time-report',
    // BULK_IMPORT_CLIENT: environment.API_URL + 'organization/client/import',
    // BULK_IMPORT_MEMBER: environment.API_URL + 'organization/members/import',
    // GET_EMAIL_BT_ID: environment.API_URL + 'organization/emailSettings/get-email',
    // REPORT_CUSTOMIZATION: environment.API_URL + 'configuration',
    // USER_REPORT: environment.API_URL + 'report/user-report',
    // PROJECT_REPORT: environment.API_URL + 'report/project-report',
    // REPORT_CONFIG: environment.API_URL + 'report/saved-report/report-config',
    // GET_TIME_LOG_GRAPH: environment.API_URL + 'report/time-report/date-wise-tasks',
    // SAVED_REPORT: environment.API_URL + 'report/saved-report',
    // USER_REPORT_TASK: environment.API_URL + 'report/user-report/user-tasks',
    // GET_ACTIVITY_REPORT: environment.API_URL + 'report/user-report/user-activity-report',
    // GET_ACTIVITY_TABLE: environment.API_URL + 'report/user-report/activity',
    GET_TIME_SHEET_REPORT_MEMBERS:
    environment.API_URL + 'organization/company/reporting-members',
    // GET_TIME_SHEET_DETAILS: environment.API_URL + 'report/time-sheet-report/',
    GET_CALENDAR_DATA: environment.API_URL + 'task/atlas-calendar',
    // QUICK_REPLY_LIST: environment.API_URL + 'discussion/quickReply',
    // WHATSAPP_NOTIFICATION: environment.API_URL + 'notification/whatsapp',
    // DASHBOARD_SUMMARY: environment.API_URL + 'dashboard/summary',
    // DASHBOARD_TODAY_SUMMARY: environment.API_URL + 'dashboard/todaySummrayTask',
    // DASHBOARD_PRIORITY: environment.API_URL + 'dashboard/prioritySummrayTask',
    GET_IP: environment.API_URL + '/user/getIp',
    // JSON_IP:'https://ipv4.jsonip.com/',
    JSON_IP:'https://api.ipify.org?format=json',
    IP_INFO: environment.API_URL + 'user/getCountry',
    // RESENT_VERIFY_EMAIL_LINK: environment.API_URL + 'user/resend-verification-link',
    // UNSUBSCRIBE_MAIL: environment.API_URL + 'notification/unsubscribe-email',
    EXPORT_TASK: environment.API_URL + 'task/export',
    // GET_CLIENT_LIST_FROM_SERVICE:environment.API_URL +'organization/service/service-detail?id=',
    // SERVICE_MANAGEMENT_MASTER:environment.API_URL +'organization/service/service-list',
    SUBSCRIPTION_EMPLOYEE : environment.subscriptionUrl  +'subscription/employees',
    // TASK_UNREAD_MESSAGES : environment.API_URL  +'discussion/task-unread-message',
    ORGANIZATION_DMS_DETAILS: environment.API_URL + 'dms/total-storage',
    GET_ORG_MEMBER_LIST: environment.API_URL + 'dms/storage/storage-list',
    GET_MEMBER_DOCUMENT_LIST: environment.API_URL + 'dms/storage/storage-details',
    STORAGE_BULK_DELETE: environment.API_URL + 'dms/storage/bulk-delete',
    // DAILY_TASK_NOTIFICATION_PERMISSION: environment.API_URL + 'notification/settings/daily-mail',
    // SAVE_IP_ADDRESS_CONFIG: environment.API_URL + 'company-ip-settings/add',
    // GET_COMPANY_IP_SETTINGS: environment.API_URL + 'company-ip-settings/settings',
    // SET_HRMS_CONFIG: environment.API_URL + 'hrms/config',
    // GET_HRMS_CONFIG: environment.API_URL + 'hrms/config',
    // SET_SANDWICH_LEAVE_CONFIG: environment.API_URL + 'hrms/sandwich-leave/config',
    // GET_SANDWICH_LEAVE_CONFIG: environment.API_URL + 'hrms/sandwich-leave/config',
    // ADD_LEAVE_TYPE: environment.API_URL + 'hrms/leaves',
    // DELETE_LEAVE_TYPE: environment.API_URL + 'hrms/leaves',
    // SAVE_EMPLOYEE_LEAVE_CONFIG: environment.API_URL + 'hrms/employee-management/config',
    // GET_EMPLOYEE_LEAVE_CONFIG: environment.API_URL + 'hrms/employee-management/config',
    // GET_LEAVE_INFO_BY_ID: environment.API_URL +'hrms/leaves',
    // SET_HOLIDAY: environment.API_URL + 'hrms/calendar/holiday',
    // SET_LEAVE_APPROVE_CONFIG: environment.API_URL + 'hrms/leave-approval/config',
    // GET_LEAVE_CALENDER_CONFIG: environment.API_URL + 'hrms/calendar/config',
    // GET_LEAVE_TYPE_LIST: environment.API_URL + 'leave/balance',
    // APPLY_LEAVE: environment.API_URL + 'leave/apply-leave',
    // GET_LEAVE_MY_LEAVE_RECORD: environment.API_URL + 'leave/records',
    // GET_PENDING_APPROVE_LEAVE_RECORD: environment.API_URL + 'leave/pending-approval',
    // PENDING_APPROVE_LEAVE: environment.API_URL + 'leave/approve-reject',
    // GET_TEAM_LEAVES: environment.API_URL + 'leave/team-leaves',
    // CANCEL_LEAVE: environment.API_URL + 'leave/cancel-leave',
    // CHECK_LEAVE_DAY_AVAILABILITY: environment.API_URL + 'leave/check-date',
    GET_TIMESHEET_SETTIGNS: environment.API_URL + 'timesheet/calendar-config',
    // SET_FINANCIAL_CONFIG: environment.API_URL + 'hrms/calendar/config',
    // SET_TIMESHEET_HOLIDAY: environment.API_URL + 'timesheet/holiday',
    // OPEN_LEAVE_BALANCE: environment.API_URL + 'hrms/opening-leave-balance',
    // SAVE_OPEN_LEAVE_BALANCE: environment.API_URL + 'hrms/employee-opening-leave',
    // SAVE_OPEN_LEAVE_MONTH: environment.API_URL + 'hrms/opening-leave-balance-config',
    // GET_LEAVE_OPEN_BALANCE: environment.API_URL + 'hrms/opening-balance-config', 
    // GET_EMPLOYEE_LEAVE_OPEN_BALANCE: environment.API_URL + 'hrms/employee-opening-balance', 
    // EMPLOYEE_LEAVE_BALANCE: environment.API_URL + 'leave/regularize/employee-leave-balance',
    // CREDIT_DEDUCT_LEAVE: environment.API_URL + 'leave/regularize',
    // ACTION_LEAVE_RECORD: environment.API_URL + 'leave/regularize/list',
    // ATTENDANCE_CONFIG: environment.API_URL + 'attendance-admin/attendance-config',
    // MEMBER_ATTENDANCE_CONFIG: environment.API_URL + 'attendance-admin/member-permissions',
    // GET_MEMBER_ATTENDANCE_CONFIG: environment.API_URL + 'attendance-admin/member-permissions',
    // MEMBERS_ATTENDANCE_CONFIG: environment.API_URL + 'attendance-admin/enabled-member-permissions',
    // ADD_FACE_IMAGE: environment.API_URL + 'attendance-admin/register-face',
    // DELETE_FACE: environment.API_URL + 'attendance-admin/face',
    // ATTENDANCE_LOCATION: environment.API_URL + 'attendance-admin/location-config',
    // ATTENDANCE_APPROVE_CONFIG: environment.API_URL + 'attendance-admin/approval-config',
    // ATTENDANCE_TIMESHEET_SETTING_BFF: environment.API_URL + 'attendance-admin/calendar',
    // ATTENDANCE_WEEK_CONFIG: environment.API_URL + 'attendance-admin/calendar/weeks-config',
    // SET_ATTENDANCE_TIMESHEET_HOLIDAY: environment.API_URL + 'attendance-admin/calendar/holiday',
    // GET_ELIGIBILITY: environment.API_URL + 'attendance-admin/check-attendance-eligibility',
    // GET_LAST_CHECK_IN_OUT_DETAILS: environment.API_URL + 'attendance/my-last-attendance',
    // CHECK_IN_FACE: environment.API_URL + 'attendance/check-in',
    // ATTENDANCE_LOG_RECORDS: environment.API_URL + 'attendance/records',
    // APPLY_LEAVE_ATTENDANCE_REGULARIZATION: environment.API_URL + 'attendance/regularize',
    // APPLY_LEAVE_ATTENDANCE_REGULARIZATION_RECORDS: environment.API_URL + 'attendance/regularized-records',
    // CHECK_HRMS_ELIGIBILITY: environment.API_URL +'hrms/check-hrms-eligibility',
    // GET_USER_LEAVE_REPORT: environment.API_URL + 'leave/leave-report', 
    // LOCATION_CONFIG: environment.API_URL +'attendance-admin/location-config/permissions',
    // SET_LOCATION_PERMISSION: environment.API_URL + 'attendance-admin/location-config/permissions',
    // DELETE_LEAVE_REGULARIZATION : environment.API_URL + 'leave/regularize',
    // TEAM_ATTENDANCE_WITH_COUNT_REPORT: environment.API_URL +'attendance/team-attendance',
    // GET_FINANCIAL_YEAR : environment.API_URL + 'hrms/calendar/financial-year',


    //  ===============  Microservice Change URLs  ===============

    LOGIN: environment.MS_BFF + 'user/sign-in',
    LOGOUT: environment.MS_BFF + 'user/logout',
    FORGOT_PASSWORD: environment.MS_AUTH + 'user/forgot-password',
    VERIFY_FORGOT_PASSWORD_TOKEN: environment.MS_AUTH + 'user/token-verify',
    SET_NEW_PASSWORD: environment.MS_AUTH + 'user/password',
    SIGN_UP_VERIFICATION: environment.MS_AUTH + 'user/verification',
    SIGN_UP: environment.MS_BFF + 'user/signup',
    RESENT_VERIFY_EMAIL_LINK: environment.MS_AUTH + 'user/resend-verification-link',
    CLIENT: environment.MS_ORG + 'client/',
    CLIENT_SAMPLE_FILE: environment.MS_ORG + 'client/sampleFile',
    BULK_CLIENT_DELETE: environment.MS_ORG + 'client/delete',
    BULK_EXPORT_CLIENT: environment.MS_ORG + 'client/export',
    BULK_IMPORT_CLIENT: environment.MS_ORG + 'client/import',
    SAVE_IP_ADDRESS_CONFIG: environment.MS_ORG + 'company-ip-settings/add',
    GET_COMPANY_IP_SETTINGS: environment.MS_ORG + 'company-ip-settings/settings',
    ADD_NEW_MEMBER: environment.MS_ORG + 'members/check-user',
    ADD_MEMBER: environment.MS_ORG + 'members',
    UPDATE_MEMBER: environment.MS_ORG + 'members',
    GET_MEMBER: environment.MS_ORG + 'members',
    DELETE_MEMBER: environment.MS_ORG + 'members',
    GET_ACTIVE_USER: environment.MS_ORG + 'members/totalMembers',
    GET_COUNT_CREDIT: environment.MS_ORG + 'members/members-count',
    BULK_IMPORT_MEMBER: environment.MS_ORG + 'members/import',
    BULK_EXPORT_MEMBER: environment.MS_ORG + 'members/export',
    TEAM_GROUP_MANAGEMENT: environment.MS_ORG + 'group',
    TEAM_GROUP_DETAILS_MANAGEMENT: environment.MS_ORG + 'group/groupDetail',
    GET_USER_LIST_BY_GROUP: environment.MS_ORG + 'group/group-member-details',
    ROLE_MANAGEMENT: environment.MS_ORG + 'role',
    ROLE_DETAIL_MANAGEMENT: environment.MS_ORG + 'role/detail',
    STATUS: environment.MS_ORG + 'status',
    CUSTOM_FIELD_SETTING: environment.MS_ORG + 'custom-fields',
    TASK_CLIENT_CUSTOM_FIELD: environment.MS_ORG + 'custom-fields/task-custom-fields',
    SERVICE_MANAGEMENT_MASTER: environment.MS_ORG +'service/service-list',
    SERVICE_MANAGEMENT: environment.MS_ORG + 'service',
    GET_CLIENT_LIST_FROM_SERVICE:environment.MS_ORG +'service/service-detail?id=',
    SERVICE_MANAGEMENT_EXPORT: environment.MS_ORG + 'service/export-service-list',
    ORGANIZATION_SETTING: environment.MS_ORG + 'generalSettings',
    COMPANY: environment.MS_ORG + 'company',
    COMPANY_COUNT: environment.MS_ORG + 'company/count',
    MODULE_DETAIL: environment.MS_ORG + 'custom-fields/modules',
    MARK_AS_APPROVAL_FLOW: environment.MS_ORG + 'company/isMarsAsApprovalFlow',
    GET_MARK_AS_COMPLETE: environment.MS_ORG + 'company/getApproveSetting',
    MARK_AS_COMPLETE: environment.MS_ORG + 'company/markAsApproveSetting',
    TIMESHEET_SETTING: environment.MS_ORG + 'timesheet',
    EMAIL_SETTING: environment.MS_ORG + 'emailSettings',
    EMAIL_COMPANY_LIST: environment.MS_ORG + 'emailSettings/companies',
    GET_EMAIL_LIST: environment.MS_ORG + 'emailSettings/get-configure-email',
    GET_EMAIL_DETAIL: environment.MS_ORG + 'emailSettings/get-email-list',
    GET_EMAIL_BT_ID: environment.MS_ORG + 'emailSettings/get-email',
    ADD_TASK_EMAIL: environment.MS_ORG + 'emailSettings/add-email-task',
    ADD_TASK_EMAIL_MS_URL: environment.MS_ORG + 'emailSettings/ms-url',
    RE_EMAIL_SYNC: environment.MS_ORG + 'emailSettings/re-sync-email',
    TIMESHEET_SETTING_MS: environment.MS_TIMESHEET + 'timesheet',
    SET_TIMESHEET_HOLIDAY: environment.MS_TIMESHEET + 'timesheet/holiday',
    TIMESHEET_SETTING_BFF: environment.MS_BFF + 'timesheet',
    USER_REPORT: environment.MS_REPORT + 'user-report',
    USER_REPORT_TASK: environment.MS_REPORT + 'user-report/user-tasks',
    GET_ACTIVITY_REPORT: environment.MS_REPORT + 'activity-report/user-activity-report',
    GET_ACTIVITY_TABLE: environment.MS_REPORT + 'activity-report/activity',
    PROJECT_REPORT: environment.MS_REPORT + 'project-report',
    REPORT_CONFIG: environment.MS_REPORT + 'saved-report/report-config',
    SAVED_REPORT: environment.MS_REPORT + 'saved-report',
    GET_TIME_LOG_USER_LIST: environment.MS_REPORT + 'time-report',
    GET_TIME_LOG_GRAPH: environment.MS_REPORT + 'time-report/date-wise-tasks',
    GET_TIME_SHEET_DETAILS: environment.MS_REPORT + 'time-report/time-sheet-report',
    UPDATE_PROFILE: environment.MS_USER + 'profile/update-profile',
    CHANGE_PASSWORD: environment.MS_USER + 'profile/reset-password',
    GET_USER_DETAILS: environment.MS_USER + 'profile/user-details/',
    BILLING_DETAIL: environment.MS_USER + 'profile/billingDetails',
    GET_BILLING_DETAIL: environment.MS_USER + 'profile/billingDetails',
    DISCUSSION_LIST: environment.MS_DISCUSSION + 'discussion/list',
    DISCUSSION_ADD: environment.MS_DISCUSSION + 'discussion/add',
    DISCUSSION_EDIT: environment.MS_DISCUSSION + 'discussion/edit',
    DISCUSSION_DELETE: environment.MS_DISCUSSION + 'discussion/delete',
    DISCUSSION_SEARCH: environment.MS_DISCUSSION + 'discussion/search',
    DISCUSSION_FAVOURITE: environment.MS_DISCUSSION + 'discussion/favourite',
    DISCUSSION_DETAILS: environment.MS_DISCUSSION + 'discussion/details',
    QUICK_REPLY_LIST: environment.MS_DISCUSSION + 'discussion/quickReply',
    TASK_UNREAD_MESSAGES : environment.MS_DISCUSSION  +'discussion/task-unread-message',
    DISCUSSION_IMAGES: environment.MS_DISCUSSION + 'discussion/images',
    DISCUSSIONLIST_SEARCH: environment.MS_DISCUSSION + 'discussion/search',
    SAVE_OFFLINE_COMMENT: environment.MS_DISCUSSION + 'discussion/saveComments',
    ADD_FOLDER: environment.MS_DMS + 'dms/folder',
    ADD_FILE: environment.MS_DMS + 'dms/file',
    GET_PATH: environment.MS_DMS + 'dms/path',
    GET_PROGRESS: environment.MS_DMS + 'dms/progress',
    DMS_DETAILS: environment.MS_DMS + 'dms/details',
    TASK_ATTACHMENT: environment.MS_DMS + 'dms/get-task-attachment',
    CHANGE_FOLDER_COLOR: environment.MS_DMS + 'dms/color',
    COPY_FILE: environment.MS_DMS + 'dms/copy',
    GET_COMPANY_ACCESSES: environment.MS_DMS + 'dms/getCompanyAccesses',
    GET_DMS_LIST: environment.MS_DMS + 'dms',
    SHARE_DMS: environment.MS_DMS + 'share',
    GET_SHARED_DMS_LIST: environment.MS_BFF + 'share',
    GET_ROOT_TEAM_FOLDERS: environment.MS_DMS + 'team/root',
    GET_TEAM_FOLDER: environment.MS_DMS + 'team',
    GET_BIN: environment.MS_DMS + 'bins',
    EMPTY_BIN: environment.MS_DMS + 'bins/empty',
    DELETE_FOLDER_BIN: environment.MS_DMS + 'bins/folder',
    DELETE_FILE_BIN: environment.MS_DMS + 'bins/file',
    RESTORE_BIN: environment.MS_DMS + 'bins/restore',
    FAVOURTE_DMS: environment.MS_DMS + 'favourite',
    UNFAVOURTE_DMS: environment.MS_DMS + 'favourite/unfavourite',
    FAVOURTE_DMS_LIST: environment.MS_DMS + 'favourite',
    RECENT: environment.MS_DMS + 'recents',
    ADD_NOTE: environment.MS_NOTE + 'note/',
    PIN_NOTE: environment.MS_NOTE + 'note/pin/',
    SHARE_NOTE: environment.MS_NOTE + 'note/share-notes',
    SHARE_NOTES: environment.MS_NOTE + 'note/share/',
    SET_HRMS_CONFIG: environment.MS_NOTE + 'hrms/config',
    GET_HRMS_CONFIG: environment.MS_NOTE + 'hrms/config',
    ADD_LEAVE_TYPE: environment.MS_NOTE + 'hrms/leaves',
    DELETE_LEAVE_TYPE: environment.MS_NOTE + 'hrms/leaves',
    GET_LEAVE_INFO_BY_ID: environment.MS_NOTE +'hrms/leaves',
    SET_SANDWICH_LEAVE_CONFIG: environment.MS_NOTE + 'hrms/sandwich-leave/config',
    GET_SANDWICH_LEAVE_CONFIG: environment.MS_NOTE + 'hrms/sandwich-leave/config',
    SAVE_EMPLOYEE_LEAVE_CONFIG: environment.MS_NOTE + 'hrms/employee-management/config',
    GET_EMPLOYEE_LEAVE_CONFIG: environment.MS_NOTE + 'hrms/employee-management/config',
    GET_LEAVE_CALENDER_CONFIG: environment.MS_NOTE + 'hrms/calendar/config',
    SET_FINANCIAL_CONFIG: environment.MS_NOTE + 'hrms/calendar/config',
    SET_HOLIDAY: environment.MS_NOTE + 'hrms/calendar/holiday',
    SET_LEAVE_APPROVE_CONFIG: environment.MS_NOTE + 'hrms/leave-approval/config',
    OPEN_LEAVE_BALANCE: environment.MS_NOTE + 'hrms/opening-leave-balance',
    SAVE_OPEN_LEAVE_MONTH: environment.MS_NOTE + 'hrms/opening-leave-balance-config',
    GET_LEAVE_OPEN_BALANCE: environment.MS_NOTE + 'hrms/opening-balance-config', 
    SAVE_OPEN_LEAVE_BALANCE: environment.MS_NOTE + 'hrms/employee-opening-leave',
    GET_EMPLOYEE_LEAVE_OPEN_BALANCE: environment.MS_NOTE + 'hrms/employee-opening-balance',
    CHECK_HRMS_ELIGIBILITY: environment.MS_NOTE +'hrms/check-hrms-eligibility',
    GET_FINANCIAL_YEAR : environment.MS_NOTE + 'hrms/calendar/financial-year',
    GET_LEAVE_TYPE_LIST: environment.MS_NOTE + 'leave/balance',
    APPLY_LEAVE: environment.MS_NOTE + 'leave/apply-leave',
    GET_LEAVE_MY_LEAVE_RECORD: environment.MS_NOTE + 'leave/records',
    GET_PENDING_APPROVE_LEAVE_RECORD: environment.MS_NOTE + 'leave/pending-approval',
    PENDING_APPROVE_LEAVE: environment.MS_NOTE + 'leave/approve-reject',
    GET_TEAM_LEAVES: environment.MS_NOTE + 'leave/team-leaves',
    CANCEL_LEAVE: environment.MS_NOTE + 'leave/cancel-leave',
    CHECK_LEAVE_DAY_AVAILABILITY: environment.MS_NOTE + 'leave/check-date',
    EMPLOYEE_LEAVE_BALANCE: environment.MS_NOTE + 'leave/regularize/employee-leave-balance',
    CREDIT_DEDUCT_LEAVE: environment.MS_NOTE + 'leave/regularize',
    ACTION_LEAVE_RECORD: environment.MS_NOTE + 'leave/regularize/list',
    GET_USER_LEAVE_REPORT: environment.MS_NOTE + 'leave/leave-report', 
    DELETE_LEAVE_REGULARIZATION : environment.MS_NOTE + 'leave/regularize',
    ATTENDANCE_CONFIG: environment.MS_NOTE + 'attendance-admin/attendance-config',
    MEMBER_ATTENDANCE_CONFIG: environment.MS_NOTE + 'attendance-admin/member-permissions',
    GET_MEMBER_ATTENDANCE_CONFIG: environment.MS_NOTE + 'attendance-admin/member-permissions',
    MEMBERS_ATTENDANCE_CONFIG: environment.MS_NOTE + 'attendance-admin/enabled-member-permissions',
    ADD_FACE_IMAGE: environment.MS_NOTE + 'attendance-admin/register-face',
    DELETE_FACE: environment.MS_NOTE + 'attendance-admin/face',
    ATTENDANCE_LOCATION: environment.MS_NOTE + 'attendance-admin/location-config',
    ATTENDANCE_APPROVE_CONFIG: environment.MS_NOTE + 'attendance-admin/approval-config',
    ATTENDANCE_TIMESHEET_SETTING_BFF: environment.MS_NOTE + 'attendance-admin/calendar',
    ATTENDANCE_WEEK_CONFIG: environment.MS_NOTE + 'attendance-admin/calendar/weeks-config',
    SET_ATTENDANCE_TIMESHEET_HOLIDAY: environment.MS_NOTE + 'attendance-admin/calendar/holiday',
    GET_ELIGIBILITY: environment.MS_NOTE + 'attendance-admin/check-attendance-eligibility',
    LOCATION_CONFIG: environment.MS_NOTE +'attendance-admin/location-config/permissions',
    SET_LOCATION_PERMISSION: environment.MS_NOTE + 'attendance-admin/location-config/permissions',
    GET_LAST_CHECK_IN_OUT_DETAILS: environment.MS_NOTE + 'attendance/my-last-attendance',
    CHECK_IN_FACE: environment.MS_NOTE + 'attendance/check-in',
    ATTENDANCE_LOG_RECORDS: environment.MS_NOTE + 'attendance/records',
    APPLY_LEAVE_ATTENDANCE_REGULARIZATION: environment.MS_NOTE + 'attendance/regularize',
    APPLY_LEAVE_ATTENDANCE_REGULARIZATION_RECORDS: environment.MS_NOTE + 'attendance/regularized-records',
    TEAM_ATTENDANCE_WITH_COUNT_REPORT: environment.MS_NOTE +'attendance/team-attendance',
    ATTENDANCE_TIMESHEET_SETTING_MS: environment.MS_NOTE + 'attendance-admin/calendar',
    TASKS_URL: environment.MS_TASK + 'task',
    BULK_IMPORT_TASK: environment.MS_TASK + 'task/bulk-import',
    GET_PROJECT_ALL_TASKS: environment.MS_TASK + 'task/project-tasks',
    TASK_PRIORITY: environment.MS_TASK + 'task/task-priority',
    TASK_STATUS: environment.MS_TASK + 'task/task-status',
    TASK_PROGRESS: environment.MS_TASK + 'task/update-percentage-by-taskId',
    TASK_READ_UNREAD: environment.MS_TASK + 'task/task-read',
    TASK_RECYCLE_BIN_TASKS_LISTS: environment.MS_TASK + 'task/recycle-bin',
    RESTORE_RECYCLE_BIN_TASK: environment.MS_TASK + 'task/restore',
    PERMANENT_DELETE_TASK: environment.MS_TASK + 'task/recycle-bin-task',
    TASK_ORDER: environment.MS_TASK + 'task/task-order',
    BULK_DELETE_TASK: environment.MS_TASK + 'task/bulk-remove',
    MARK_AS_UNDER_REVIEW: environment.MS_TASK + 'task/markAsUnderReview',
    MARK_AS_APPROVE_REJECT: environment.MS_TASK + 'task/taskApproveOrReject',
    PIN_TASK_URL: environment.MS_TASK + 'task/pin-unpin',
    TASK_FAV_FILTER: environment.MS_TASK + 'task/favorite-filter/',
    GET_FAVOURITE_MEMBER: environment.MS_BFF + 'task' + '/favourite-members',
    POST_FAVOURITE_MEMBER: environment.MS_TASK + 'task' + '/favourite-members',
    GET_TASK_ANALYSIS: environment.MS_TASK + 'task' + '/analytics',
    STOP_RECURRING_TASK: environment.MS_TASK + 'task/stop-recurring',
    CHECKLIST_SETTING: environment.MS_TASK + 'check-list-template',
    CUSTOM_FIELD_CONFIG: environment.MS_TASK + 'configuration',
    REPORT_CUSTOMIZATION: environment.MS_TASK + 'configuration',
    TASKS_KANBAN_URL: environment.MS_TASK + 'pipeline',
    DRAG_DROP_TASK: environment.MS_TASK + 'pipeline/update-task-index',
    TASK_REMINDER: environment.MS_TASK + 'task-reminder',
    TASK_UPDATE_REMINDER: environment.MS_TASK + 'task-reminder/toggle-reminder',
    GET_DASHBOARD_SUMMARY: environment.MS_TASK + 'dashboard/summary',
    DASHBOARD_TODAY_SUMMARY: environment.MS_TASK + 'dashboard/todaySummrayTask',
    DASHBOARD_SUMMARY: environment.MS_TASK + 'dashboard/summary',
    DASHBOARD_PRIORITY: environment.MS_TASK + 'dashboard/prioritySummrayTask',
    GET_DASHBOARD_TASKS: environment.MS_BFF + 'dashboard/atlas-tasks',
    NOTIFICATION_USER: environment.MS_NOTIFICATION + 'notification/settings/users/',
    NOTIFICATION_SETTING: environment.MS_NOTIFICATION + 'notification/settings',
    NOTIFICATION_PERMISSION: environment.MS_NOTIFICATION + 'notification/settings/change-permission',
    NOTIFICATION_LIST: environment.MS_NOTIFICATION + 'notification/',
    NOTIFICATION_COUNT: environment.MS_NOTIFICATION + 'notification/unread/count',
    READ_ALL_NOTIFICATION: environment.MS_NOTIFICATION + 'notification/mark-as-read-notifications',
    WHATSAPP_NOTIFICATION: environment.MS_NOTIFICATION + 'notification/whatsapp',
    UNSUBSCRIBE_MAIL: environment.MS_NOTIFICATION + 'notification/unsubscribe-email',
    DAILY_TASK_NOTIFICATION_PERMISSION: environment.MS_NOTIFICATION + 'notification/settings/daily-mail',
    PRESIGN_URL: environment.API_URL + 'task/get-presignedURL',
};

export const SocketEvents = {
    CONNECT: 'connectSuccess',
    JOIN_ROOM: 'emitJoinRoom',
    EMIT_NEW_MESSAGE: 'emitGroupMessage',
    EMIT_RECEIVE_MESSAGE: 'emitRequestGroupMessages',
    RECEIVE_MESSAGES: 'onNewGroupMessage',
    RECEIVE_LIVE_FILES: 'onNewGroupFile',
    RECEIVE_FILES: 'onRequestFiles',
    REQUEST_OLD_MESSAGES: 'requestOldMessages',
    RECEIVE_OLD_MESSAGES: 'receiveOldMessages',
    ERROR_SOCKET_MESSAGE: 'onSocketError',
    READ_MESSAGES: 'readMessages',
    EMIT_START_TYPING: 'emitStartTyping',
    ON_START_TYPING: 'onStartTyping',
    ON_GET_USER_CONNECTED: 'newUserConnected',
    ON_GET_USER_DISCONNECTED: 'userDisconnected',
    COMMENT_NOTIFICATION: 'commentNotification',
    TASK_CHAT_MESSAGE: 'taskChatMessage',

    EMIT_ADD_USER: 'emitAddUser',

    EMIT_USER_TYPING: 'emitUserTyping',
    ON_USER_TYPING: 'onUserTyping',

    EMIT_GET_MESSAGES: 'emitGetMessages',
    ON_NEW_MESSAGES: 'onNewMessages',

    EMIT_CONVERSATIONS: 'emitConversations',
    ON_CONVERSATIONS: 'onConversations',
    ON_USER_ACTIVE_STATUS: 'onUserActiveStatus',
    ON_USER_ACTIVE_UPDATE: 'onUserActiveUpdate',
};

export const discussionSocketEvents = {
    GET_CHAT_LIST: 'GET_CHAT_LIST',
    JOIN_ROOM: 'JOIN_ROOM',
    LEAVE_ROOM: 'LEAVE_ROOM',
    MESSAGE: 'MESSAGE',
    NEW_MESSAGE: 'NEW_MESSAGE',
    TYPING: 'TYPING',
    COMPANY_TYPING: 'COMPANY_TYPING',
    COMPANY_NEW_MESSAGE: 'COMPANY_NEW_MESSAGE',
    READ_MESSAGES: 'READ_MESSAGES',
    CHAT_ROOM_TYPING: 'CHAT_ROOM_TYPING',
    SEARCH_MESSAGES: 'SEARCH_MESSAGES',
    DELETE_MESSAGE: 'DELETE_MESSAGE',
    CHAT_ROOM_DELETE_MESSAGE: 'CHAT_ROOM_DELETE_MESSAGE',
    COMPANY_DELETE_MESSAGE: 'COMPANY_DELETE_MESSAGE',
    JOIN_COMPANY_ROOM: 'JOIN_COMPANY_ROOM',
    COMPANY_ONLINE_OFFLINE: 'COMPANY_ONLINE_OFFLINE',
    JOIN_SOCKET: 'JOIN_SOCKET',
};

export const constant = {
    CURRENT_PAGE: 1,
    COMMENT_LIMIT: 10,
    NO_INTERNET_CONNECTION_MSG: 'No Internet Connection',
    DOCUMENT_UPLOAD_FILE_EXTENSION: '.pdf,.jpg,.jpeg, .png',
    setFileSize: 50000000,
};

export const SIDEBAR_MENU = [
    {
        label: 'Dashboard',
        route: '/dashboard',
    },
    {
        label: 'Tasks',
        route: '/task',
    },
    {
        label: 'Projects',
        route: '/project',
    },
    {
        label: 'Discussion',
        route: '/discussion',
    },
    {
        label: 'Documents',
        route: '/drive/my/0',
    },
    {
        label: 'Time sheet',
        route: '/time-sheet',
    },
    {
        label: 'Notes',
        route: '/notes',
    },
    {
        label: 'Attendance',
        route: '/attendance',
    },
    {
        label: 'Leave',
        route: '/leave',
    },
    {
        label: 'Reports',
        route: '/report',
    },
    {
        label: 'Users',
        route: '/user-profile',
    },
    {
        label: 'HelpSection',
        route: '/help',
    },
    {
        label: 'Settings',
        route: '/management',
    }
];
export const taskbifurcatedlabels = [
    {
        label: 'Ongoing',
        count: 1,
        type: 'inProgress',
    },
    {
        label: 'Today',
        count: 2,
        type: 'today',
    },
    {
        label: 'Overdue',
        count: 3,
        type: 'overdue',
    },
    {
        label: 'Pin Task',
        count: 4,
        type: 'pin',
    },
    {
        label: 'Upcoming',
        count: 5,
        type: 'upcoming',
    },
    {
        label: 'Total',
        count: 6,
        type: 'total',
    },
];

export const withoutTotal = [
    {
        label: 'Ongoing',
        count: 1,
        type: 'inProgress',
    },
    {
        label: 'Today',
        count: 2,
        type: 'today',
    },
    {
        label: 'Overdue',
        count: 3,
        type: 'overdue',
    },
    {
        label: 'Pin Task',
        count: 4,
        type: 'pin',
    },
    {
        label: 'Upcoming',
        count: 5,
        type: 'upcoming',
    },
];

export const HEADER_NAVIGATION = [
    {
        name: 'ORGANIZATION_MANAGEMENT',
        route: '/organization',
    },
    {
        name: 'SUBSCRIPTION_MANAGEMENT',
        route: 'management/subscription',
    },
    {
        name: 'EMAIL_MANAGEMENT',
        route: '/email-management',
    },
    {
        name: 'HELP',
    },
    {
        name: 'Logout',
        route: '/logout',
    },
];

export const COLOR_CODE = [
    {
        label: 'darkgreen',
        value: '#16B975',
    },
    {
        label: 'darkorange',
        value: '#F2793D',
    },
    {
        label: 'darkblue',
        value: '#3477DC',
    },
    {
        label: 'darkcyan',
        value: '#148E96',
    },
    {
        label: 'darkgreen',
        value: '#12945E',
    },
    {
        label: 'darkorange',
        value: '#F18006',
    },
    {
        label: 'darkPurple',
        value: '#594AB6',
    },
    {
        label: 'darkroyalblue',
        value: '#2A5FB0',
    },
    {
        label: 'darkYellow',
        value: '#C88E1B',
    },
    {
        label: 'darkViolate',
        value: '#276EF1',
    },
    {
        label: 'darkSaffron',
        value: '#C26131',
    },
    {
        label: 'darkPopati',
        value: '#E39813',
    },
    {
        label: 'darkCream',
        value: '#7CA508',
    },
    {
        label: 'darkGreen1',
        value: '#3ABB4F',
    },
    {
        label: 'darkPink',
        value: '#9647CE',
    },
    {
        label: 'darkVadali',
        value: '#337AE4',
    },
    {
        label: 'darkpink1',
        value: '#D74589',
    },
    {
        label: 'darkblue1',
        value: '#515BE5',
    },
    {
        label: 'darkred1',
        value: '#AB2626',
    },
    {
        label: 'darkvialote1',
        value: '#66179F',
    },
    {
        label: 'darksyama',
        value: '#8F446D',
    },
    {
        label: 'darkgreen12',
        value: '#127A61',
    },
    {
        label: 'darkmehndi12',
        value: '#9A3B1A',
    },
    {
        label: 'darkrama',
        value: '#96336C',
    },
    {
        label: 'darkrama1',
        value: '#0D968D',
    },
    {
        label: 'darkwine',
        value: '#A08249',
    },
    {
        label: 'darkpinkshdow',
        value: '#C621E2',
    },
    {
        label: 'darkredshdow',
        value: '#DC143C',
    },
    {
        label: 'darkorangeshdow',
        value: '#EF6E05',
    },
    {
        label: 'darksyamashdow',
        value: '#06ACA7',
    },
];

export const RAZOR_PAY_CONFIG = {
    RAZOR_PAY_FROOGODO_TEXT: 'TaskOpad',
    RAZOR_PAY_CURRENCY: 'USD',
    RAZOR_PAY_DESCRIPTION: 'Test Payment',
};

export const PLAN_TYPE = {
    MONTHLY: 'monthly',
    YEARLY: 'yearly',
    FREE: 'free',
};
export const FREQUENCY_DATA = {
    MONTHLY: 'Monthly',
    CUSTOM: 'Custom',
    QUARTERLY: 'Quarterly',
    HALF_YEARLY: 'halfyearly',
    YEARLY: 'Yearly',
    WEEKLY: 'Weekly',
    FREE: 'Free',
};

export const FIELD_TYPE = [
    'text',
    'textarea',
    'numeric',
    'currency',
    'date',
    'email',
    'phone',
    'url',
    'checklist',
    'radio',
];
export const SUB_NUMERIC_FIELD_TYPE = [
    'number',
    'decimal',
    'percentage',
    'autonumber',
];
export const PLAN_PRICE = {
    MONTHLY_USD: 6,
    MONTHLY_INR: 300,
    YEARLY_USD: 4,
    YEARLY_INR: 200,
    FREE_PLAN_PRICE: 0,
    GST_PERCETAGE: 18,
};

export const CURRENCY_CONFIG = {
    INR_SYMBOL: '₹',
    USD_SYMBOL: '$',
    INR_TYPE: 'INR',
    USD_TYPE: 'USD',
};

export const COUNTRY_NAME = {
    IND: 'India',
};



export const DESIGNATION = {
    OWNER: 'owner',
};

export const FILE_TYPE = {
    IMAGE_PNG: 'image/png',
    IMAGE_JPEG: 'image/jpeg',
    IMAGE_JPG: 'image/jpg',
    IMAGE_GIF: 'image/gif',
    IMAGE_SVG: 'image/svg+xml',
    PDF: 'application/pdf',
    SHEET: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    MS_WORD: 'application/msword',
    TEST: 'text/plain',
    DOCUMENT:
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    VIDEO: 'video/mp4',
    AUDIO: 'audio/mpeg',
    XLSX: '.xlsx',
    DOCS: '.docx',
    DOC:'.doc',
    VIDEO_FILE: '.mp4',
    AUDIO_FILE: '.mp3',
    JPEG: '.jpeg',
    PDF_FILE: '.pdf',
    ZIP: 'application/zip',
    ZIP_FILE: '.zip',
    CSV: 'text/csv',
    CSV_FILE: '.csv',
    ODT: 'application/vnd.oasis.opendocument.text',
    ODT_FILE: '.odt',
    HTML_FILE: '.html',
    HTML: 'text/html',
    PNG: '.png',
    JPG: '.jpg',
    IMG: 'img',
    VIDEO_TYPE: '.webm',
    VIDEO_QUICKTIME: '.quicktime',
    VIDEO_OGG: '.ogg',
    AUDIO_M4A: '.m4a',
    AUDIO_WAVE: '.wave',
    AUDIO_WAV: '.wav'
};

export const notSupportedFileType = [FILE_TYPE.VIDEO, FILE_TYPE.VIDEO_FILE,FILE_TYPE.VIDEO_TYPE,FILE_TYPE.VIDEO_QUICKTIME, FILE_TYPE.VIDEO_OGG,FILE_TYPE.PDF,FILE_TYPE.SHEET, FILE_TYPE.AUDIO_FILE ,FILE_TYPE.MS_WORD,FILE_TYPE.AUDIO_M4A,FILE_TYPE.DOCS,FILE_TYPE.DOC,FILE_TYPE.XLSX]

export const FREQUENCY_FOR_RECURRING = {
    MONTHLY: 'monthly',
    CUSTOM: 'custom',
    QUARTERLY: 'quarterly',
    HALF_YEARLY: 'halfyearly',
    YEARLY: 'yearly',
    WEEKLY: 'weekly',
};

export const MODULE_NAME = {
    CLIENT: 'client',
    TASK: 'task',
    TASK_PIPELINE: 'TASK',
    TIME_SHEET: 'Timesheet',
    ATTENDANCE: 'ATTENDANCE',
    MODULE_ID_TASK: 2,
    MODULE_ID_CLIENT: 1,
};

export const REMIND_TYPE = {
    EMAIL: 'Email',
    PUSH_NOTIFICATION: 'Push notification',
    WHATSAPP: 'Whatsapp',
    MAIL: 'email',
    PUSHUP_NOTIFICATION: 'pushup_notification',
    WHATSAPP1: 'whatsapp',
};

export const task_field_type = {
    client: 'client',
    service: 'service',
    follower: 'follower',
    member: 'member',
    status: 'status',
    project: 'project',
    activity: 'activity',
    userType: 'usertype',
    group:'group'
};

export const project_field_type = {
    member: 'member',
    manager: 'manager',
    client: 'client',
};

export const priority_name = {
    low: 'low',
    high: 'high',
    medium: 'medium',
};

export const DMS_COLOR_CODE = [
    {
        label: 'Green',
        value: '#16B975',
    },
    {
        label: 'Yellow',
        value: '#FAB222',
    },
    {
        label: 'Red',
        value: '#DC3535',
    },
    {
        label: 'Purple',
        value: '#6F5CE3',
    },
    {
        label: 'Teal',
        value: '#19B1BB',
    },
    {
        label: 'Orange',
        value: '#F2793D',
    },
    {
        label: 'Blue',
        value: '#3477DC',
    },
];

export const status_name = {
    CLOSED: 'closed',
    PENDING: 'pending',
    REJECTED: 'rejected',
    UNDER_REVIEW: 'under review',
    UNDER_REVIEW_SEND: 'under review - send',
    UNDER_REVIEW_RECEIVE: 'under review - received',
};

export const TIME_LOG_TYPE = {
    BOTH: 'both',
    MANUAL: 'manual',
    NO_OF_HRS: 'No.of Hrs',
    NO_OF_HOURS: 'noOfHours',
    AUTO: 'auto',
};

export const FILE_CONFIGURATION = {
    MAX_FILE_SIZE: 5,
    MAX_FILE_UPLOAD_LIMIT: 5,
    TOTAL_UPLOAD_SIZE: 0,
    DEFAULT_FILE_SIZE:5,
    DEFAULT_NUMBER_OF_FILES_UPLOADS:15
};

export const FILE_VALIDATION = {
    EMPTY_SHEET_ERROR:
    'Sheet can\'t be empty, Please provide sufficient data in sheet',
    HEADER_NAVIGATION_ERROR:
    'Sheet can\'t be created or header is invalid. you need to download sample file and upload it',
};

export const DMS_DOCUMENT_TYPE = {
    FILE: 'File',
    FOLDER: 'Folder',
};

export const FileSizeType = {
    MB: 'MB',
    GB: 'GB',
};

export const globalTaskTableElements = [
    {
        columnDef: 'task_name',
        header: 'Task Name',
        isDefault: true,
        index: -1,
        isSet: true,
    },
    { columnDef: 'due_date', header: 'Due Date', isDefault: false, isSet: true },
    {
        columnDef: 'assignee',
        header: 'Assignee(s)',
        isDefault: false,
        isSet: true,
    },
    { columnDef: 'status', header: 'Status', isDefault: false, isSet: true },
    { columnDef: 'reporter', header: 'Follower', isDefault: false, isSet: false },
    { columnDef: 'project', header: 'Project', isDefault: false, isSet: false },
    { columnDef: 'service', header: 'Service', isDefault: false, isSet: false },
    { columnDef: 'client', header: 'Client', isDefault: false, isSet: false },
    {
        columnDef: 'start_date',
        header: 'Start Date',
        isDefault: false,
        isSet: false,
    },
    {
        columnDef: 'created_date',
        header: 'Created Date',
        isDefault: false,
        isSet: false,
    },
    {
        columnDef: 'closed_date',
        header: 'Closed Date',
        isDefault: false,
        isSet: false,
    },
    { columnDef: 'group', header: 'Group', isDefault: false, isSet: false },
    {
        columnDef: 'parent_task',
        header: 'Parent Task',
        isDefault: false,
        isSet: false,
    },
    { columnDef: 'progress', header: 'Progress', isDefault: false, isSet: false },
    {
        columnDef: 'start_time',
        header: 'Start Time',
        isDefault: false,
        isSet: false,
    },
    { columnDef: 'end_time', header: 'End time', isDefault: false, isSet: false },
    {
        columnDef: 'estimated_hours',
        header: 'Estimated Hours',
        isDefault: false,
        isSet: false,
    },{
        columnDef : 'days_variance',
        header: 'Day variance',
        isDefault: false,
        isSet: false,
    },
    {
        columnDef: 'actual_hours',
        header: 'Actual Hours',
        isSet: false,
        isDefault: false,
    }
];
export const dashboardTaskTableElements = [
    { columnDef: 'task_name', header: 'Task Name', isDefault: true, index: -1 },
    { columnDef: 'due_date', header: 'Due Date', isDefault: false },
    { columnDef: 'assignee', header: 'Assignee(s)', isDefault: false },
    { columnDef: 'status', header: 'Status', isDefault: false },
];

export const SORTING_TASK: any = [
    { name: 'Default', value: 'default' },
    { name: 'All Tasks Team', value: 'all_tasks_team' },
    { name: 'Just My tasks', value: 'just_my_tasks' },
    { name: 'Created By me', value: 'created_by_me' },
    { name: 'Due Today', value: 'due_today' },
    { name: 'Due This Week', value: 'due_this_week' },
    { name: 'Due Next Week', value: 'due_next_week' },
    { name: 'Over Due', value: 'over_due' },
    { name: 'My Team', value: 'my_teams' },
    { name: 'Followers', value: 'followers' },
    { name: 'Tasks-Sub Tasks', value: 'task_subtasks' },
    { name: 'Sub Tasks', value: 'subtasks' },
    // { name: 'Recurring Tasks', value: 'recurring_task' },
    { name: 'Non Recurring Tasks', value: 'non_recurring_task' },
    { name: 'Unread Tasks', value: 'unread_task' },
    { name: 'Unassigned Tasks', value: 'unassign_task' },
];
export const FILTER_TASK: any = [
    { label: 'Task', index: 1, value: 'task_name', type: 'text' },
    { label: 'Description', index: 2, value: 'description', type: 'text'},
    { label: 'Client', index: 3, value: 'client', type: 'checklist' },
    { label: 'Service', index: 4, value: 'service', type: 'checklist' },
    { label: 'Assignee', index: 5, value: 'assignees', type: 'checklist' },
    { label: 'Followers', index: 6, value: 'follower', type: 'checklist' },
    { label: 'Owner', index: 7, value: 'owner', type: 'checklist' },
    { label: 'Comment', index: 8, value: 'comment', type: 'text' },
    { label: 'Files', index: 9, value: 'files', type: 'text' },
    { label: 'Project', index: 10, value: 'project', type: 'checklist' },
    { label: 'Percentage', index: 11, value: 'percentage', type: 'checklist' },
    { label: 'Priority', index: 12, value: 'priority', type: 'checklist' },
    { label: 'Groups', index: 13, value: 'groups', type: 'checklist' },
    { label: 'Day Variance', index: 14, value: 'day_variance', type: 'form' ,
        formControlsName:{
            status: [], // Nested form control (array for mat-select multiple)
            start_delay: [null], // Nested form control
            end_delay: [null], // Nested form control
        }, 
        formControlsNameTypeAsIndexWise:['multi_toogles','number','number'],
        formControlsNameConfig: [
            { key: 'status', label: 'Day Variance', type: 'multi_toggles', placeholder: '' },
            { key: 'start_delay', label: 'Start Delay', type: 'number', placeholder: 'Enter start delay' },
            { key: 'end_delay', label: 'End Delay', type: 'number', placeholder: 'Enter end delay' },
        ],
    },
];

export const FILTER_TYPE: any = {
    text: ['currency', 'text', 'numeric', 'textarea', 'email', 'phone', 'url'],
    checkBox: ['radio', 'checklist'],
    date: ['date'],
    formArray:['form']
};

export const FILTER_LABEL = {
    CLIENT: 'Client',
    SERVICE: 'Service',
    ASSIGNEE: 'Assignee',
    FOLLOWER: 'Followers',
    OWNER: 'Owner',
    PERCENTAGE: 'Percentage',
    PRIORITY: 'Priority',
    GROUPS: 'Groups',
    PROJECT: 'Project',
    DAY_VARIANCE: 'Day Variance'
};

export const DATE_TYPE: any = [
    { label: 'Due Date', value: 'due_date' },
    { label: 'Created Date', value: 'created_date' },
    { label: 'Closed Date', value: 'closed_date' },
    { label: 'Start Date', value: 'start_date' },
];

export const REPORT_DATE_TYPE: any = [
    { label: 'Due Date', value: 'due_date' },
    { label: 'Created Date', value: 'created_at' },
    { label: 'Closed Date', value: 'closed_date' },
    { label: 'Start Date', value: 'start_date' },
];

export const TASK_TYPE: any = [
    { label: 'Recurring Task', value: 'recurring_task',  isCheck:false},
    { label: 'Service Recurring Task', value: 'service_task', isCheck:false},
    { label: 'Both', value: 'both', isCheck:false },
];
export const RECURRING_FREQUENCY: any = [
    { label: 'Custom', value: 'custom' ,  isCheck:false },
    { label: 'Weekly', value: 'weekly' ,  isCheck:false},
    { label: 'Monthly', value: 'monthly',  isCheck:false },
    { label: 'Yearly', value: 'yearly',  isCheck:false },
    { label: 'Quarterly', value: 'quarterly',  isCheck:false },
    { label: 'Half Yearly', value: 'halfyearly',  isCheck:false },
];

export const RANGE_TYPE: any = [
    { label: 'Range', value: 'range' },
    // { label: 'Before', value: 'before' },
    { label: 'On', value: 'on' },
    // { label: 'After', value: 'after' },
];

export const DISCUSSION_TYPES = {
    GENERAL: 'GENERAL',
    TASK: 'TASK',
    MEMBER: 'MEMBER',
};
export const NOTES_COLOR_CODE = [
    {
        label: '#148E96',
        value: '#D1EFF1',
    },
    {
        label: '#12945E',
        value: '#D0F1E3',
    },
    {
        label: '#F18006',
        value: '#FEF0D3',
    },
    {
        label: '#DC3535',
        value: '#F8D7D7',
    },
    {
        label: '#594AB6',
        value: '#E2DEF9',
    },
    {
        label: '#2A5FB0',
        value: '#D6E4F8',
    },
    {
        label: '#757575',
        value: '#EEEEEE',
    },
    {
        label: '#276EF1',
        value: '#EDF3FD',
    },
    {
        label: '#C26131',
        value: '#FCE4D8',
    },
    {
        label: '#E39813',
        value: '#FFF2BF',
    },
    {
        label: '#7CA508',
        value: '#F0FFB9',
    },
    {
        label: '#3ABB4F',
        value: '#CCFFDB',
    },
    {
        label: '#9647CE',
        value: '#F3DAFF',
    },
    {
        label: '#337AE4',
        value: '#E2EEFF',
    },
    {
        label: '#D74589',
        value: '#FFDEEE',
    },
    {
        label: '#515BE5',
        value: '#D7D9FD',
    },
    {
        label: '#792B9A',
        value: '#EAE1F8',
    },
    {
        label: '#AB2626',
        value: '#FFDBDB',
    },
    {
        label: '#66179F',
        value: '#F1E0FD',
    },
    {
        label: '#8F446D',
        value: '#FFE0F0',
    },
    {
        label: '#127A61',
        value: '#CFF4EB',
    },
    {
        label: '#9A3B1A',
        value: '#FFE2D4',
    },
    {
        label: '#96336C',
        value: '#FAE7FD',
    },
    {
        label: '#0D968D',
        value: '#C8FBF8',
    },
    {
        label: '#A08249',
        value: '#EBE0CC',
    },
    {
        label: '#C621E2',
        value: '#F9D7FF',
    },
    {
        label: '#DC143C',
        value: '#FFD3DC',
    },
    {
        label: '#EF6E05',
        value: '#FFDDC0',
    },
    {
        label: '#06ACA7',
        value: '#D1FDFC',
    },
];

export const NOTES_PERMISSION = [
    { label: 'Select People', value: 'select_people', checked: false },
    { label: 'Edit Permisson', value: 'can_edit', checked: false, hidden: true },
    {
        label: 'Delete Permisson',
        value: 'can_delete',
        checked: false,
        hidden: true,
    },
    { label: 'Both Permisson', value: 'can_both', checked: false, hidden: true },
];

export const MESSAGE_TYPES = {
    TEXT: 'text',
    INFO: 'info',
    DOCUMENT: 'document',
    CONTACT: 'contact',
    LOCATION: 'location',
    AUDIO: 'audio',
    ALL: 'all',
    MOBILE_CONTACT: 'mobile contact',
};

export const DISCUSSION_COMMENT_TYPE = {
    DISCUSSION: 'DISCUSSION',
    TASK: 'TASK',
    CHACKLIST: 'CHACKLIST',
};

export const DEFAULT_FOLDER_NAME = {
    TASKS: 'tasks',
    PROJECTS: 'projects',
    CLIENTS: 'clients',
    DISCUSSIONS: 'discussions',
};

export const MODULE_NOTIFICATION = [
    { label: 'Timesheet', value: 'timesheet' },
    { label: 'Task', value: 'task' },
    { label: 'Project', value: 'project' },
    { label: 'Discussion', value: 'discussion' },
    { label: 'Dms', value: 'dms' },
    { label: 'Notes', value: 'notes' },
    { label: 'Task Comment', value: 'task_comment' },
];

export const commonRequiredPropertiesReport = [
    { key: 'project', message: 'Please provide a value for project.' },
    { key: 'userType', message: 'Please provide a value for usertype.' },
    { key: 'memberIds', message: 'Please provide a value for member.' },
    { key: 'statusList', message: 'Please provide a value for status.' },
    {
        key: 'dateFilterType',
        message: 'Please provide a value for dateFilterType.',
    }
];

export const requiredPropertiesActivityReport = [
    { key: 'project', message: 'Please provide a value for project.' },
    { key: 'memberIds', message: 'Please provide a value for member.' },
    { key: 'activityList', message: 'Please provide a value for activity' },
    { key: 'statusList', message: 'Please provide a value for status.' },
    {
        key: 'dateFilterType',
        message: 'Please provide a value for dateFilterType.',
    },
];

export const ReportTypes = {
    USER_REPORT: 'user-report',
    USER_PERFORMANCE_REPORT: 'user-performance-report',
    STATUS_REPORT: 'status-report',
    ACTIVITY_REPORT: 'activity-report',
    TIME_SHEET_REPORT: 'time_sheet_report',
    TIME_LOG_REPORT: 'time_log_report',
    TIME_REPORT: 'time_report',
    PROJECT_USER_REPORT: 'project-user-report',
    PROJECT_USER_PERFORMANCE_REPORT: 'project-user-performance-report',
    PROJECT_STATUS_REPORT: 'project-status-report',
    PROJECT_ACTIVITY_REPORT: 'project-activity-report',
};
export const reportTableElements = [
    { columnDef: 'priority', header: 'Priority', isSet: true, isDefault: true },
    { columnDef: 'task_name', header: 'Task Name', isSet: true, isDefault: true },
    {
        columnDef: 'assignees',
        header: 'Assignee(s)',
        isSet: true,
        isDefault: false,
    },
    { columnDef: 'status', header: 'Status', isSet: true, isDefault: false },
    {
        columnDef: 'start_date',
        header: 'Start Date',
        isSet: true,
        isDefault: false,
    },
    { columnDef: 'due_date', header: 'Due Date', isSet: true, isDefault: false },
    {
        columnDef: 'actual_hours',
        header: 'Actual Hours',
        isSet: true,
        isDefault: false,
    },
    {
        columnDef: 'project',
        header: 'Project',
        isSet: false,
        isDefault: false,
    },
    {
        columnDef : 'days_variance',
        header: 'Day variance',
        isDefault: false,
        isSet: false,
    }
];
export const timesheetReport = [
    { columnDef: 'priority', header: 'Priority', isSet: true, isDefault: true },
    { columnDef: 'task_name', header: 'Task Name', isSet: true, isDefault: true },
    {
        columnDef: 'start_date',
        header: 'Start Date',
        isSet: true,
        isDefault: false,
    },
    { columnDef: 'due_date', header: 'Due Date', isSet: true, isDefault: false },
    {
        columnDef: 'closed_date',
        header: 'Closed Date',
        isSet: true,
        isDefault: false,
    },
    {
        columnDef: 'estimated_hours',
        header: 'Estimated Hours',
        isSet: true,
        isDefault: false,
    },
    {
        columnDef: 'actual_hours',
        header: 'Actual Hours',
        isSet: true,
        isDefault: false,
    },
    { columnDef: 'variance', header: 'Variance', isSet: true, isDefault: false },
    {
        columnDef: 'performance',
        header: 'Performance',
        isSet: true,
        isDefault: false,
    },
    {
        columnDef: 'users_report',
        header: 'User\'s Report',
        isSet: true,
        isDefault: false,
    },
    { columnDef: 'status', header: 'Status', isSet: false, isDefault: false },
    {
        columnDef: 'created_date',
        header: 'Created Date',
        isSet: false,
        isDefault: false,
    },
    { columnDef: 'service', header: 'Service', isSet: false, isDefault: false },
    {
        columnDef: 'client_name',
        header: 'Client',
        isSet: false,
        isDefault: false,
    },
    {
        columnDef: 'project',
        header: 'Project Name',
        isSet: false,
        isDefault: false,
    },
    { columnDef: 'progress', header: 'Progress', isSet: false, isDefault: false },
    {
        columnDef : 'days_variance',
        header: 'Day variance',
        isDefault: false,
        isSet: false,
    }
];

export const performanceStatus: any = [
    {
        status_name: 'Before Time',
        status_color: '#FAB222',
    },
    {
        status_name: 'On Track',
        status_color: '#16B975',
    },
    {
        status_name: 'Delayed',
        status_color: '#DC3535',
    },
];

export const userReportFieldConfig = [
    { columnDef: 'priority', header: 'Priority', isSet: true, isDefault: true },
    { columnDef: 'task_name', header: 'Task Name', isSet: true, isDefault: true },
    { columnDef: 'status', header: 'Status', isSet: true, isDefault: false },
    {
        columnDef: 'start_date',
        header: 'Start Date',
        isSet: true,
        isDefault: false,
    },
    { columnDef: 'due_date', header: 'Due Date', isSet: false, isDefault: false },
    {
        columnDef: 'createdAt',
        header: 'Create Date',
        isSet: false,
        isDefault: false,
    },
    {
        columnDef: 'closed_date',
        header: 'Closed Date',
        isSet: false,
        isDefault: false,
    },
    {
        columnDef: 'owner',
        header: 'Creator',
        isSet: false,
        isDefault: false,
    },
    {
        columnDef: 'assignees',
        header: 'Assignee',
        isSet: false,
        isDefault: false,
    },
    {
        columnDef: 'follower',
        header: 'Follower',
        isSet: false,
        isDefault: false,
    },
    {
        columnDef: 'service',
        header: 'Service',
        isSet: false,
        isDefault: false,
    },
    {
        columnDef: 'client',
        header: 'Client',
        isSet: false,
        isDefault: false,
    },
    {
        columnDef: 'project',
        header: 'Project',
        isSet: false,
        isDefault: false,
    },
    {
        columnDef: 'start_time',
        header: 'Start Time',
        isSet: false,
        isDefault: false,
    },
    {
        columnDef: 'end_time',
        header: 'End Time',
        isSet: false,
        isDefault: false,
    },
    {
        columnDef: 'time_sheet_estimated_hr',
        header: 'Estimated Hours',
        isSet: false,
        isDefault: false,
    },
    {
        columnDef: 'actualHours',
        header: 'Actual Hours',
        isSet: false,
        isDefault: false,
    },
    {
        columnDef: 'performance',
        header: 'Performance',
        isSet: false,
        isDefault: false,
    },
    {
        columnDef: 'percentage',
        header: 'Progress',
        isSet: false,
        isDefault: false,
    },
    {
        columnDef : 'days_variance',
        header: 'Day variance',
        isDefault: false,
        isSet: false,
    }
];

export const activityReportElement: any = [
    { columnDef: 'priority', header: 'Priority', isSet: true, isDefault: true },
    { columnDef: 'task_name', header: 'Task Name', isSet: true, isDefault: true },
    { columnDef: 'activity', header: 'Activity', isSet: true, isDefault: true },
    {
        columnDef: 'activity_date',
        header: 'Activity Date',
        isSet: true,
        isDefault: true,
    },
    {
        columnDef: 'activity_description',
        header: 'Activity Description',
        isSet: true,
        isDefault: true,
    },
    {
        columnDef: 'assignees',
        header: 'Assignee(s)',
        isSet: true,
        isDefault: false,
    },
    { columnDef: 'status', header: 'Status', isSet: true, isDefault: false },
    {
        columnDef: 'start_date',
        header: 'Start Date',
        isSet: true,
        isDefault: false,
    },
    { columnDef: 'due_date', header: 'Due Date', isSet: true, isDefault: false },
    {
        columnDef: 'createdAt',
        header: 'Created Date',
        isSet: false,
        isDefault: false,
    },
    {
        columnDef: 'closed_date',
        header: 'Closed Date',
        isSet: false,
        isDefault: false,
    },
    { columnDef: 'owner', header: 'Creator', isSet: false, isDefault: false },
    { columnDef: 'follower', header: 'Follower', isSet: false, isDefault: false },
    { columnDef: 'service', header: 'Service', isSet: false, isDefault: false },
    {
        columnDef: 'client_name',
        header: 'Client Name',
        isSet: false,
        isDefault: false,
    },
    {
        columnDef: 'project',
        header: 'Project',
        isSet: false,
        isDefault: false,
    },
    {
        columnDef: 'start_time',
        header: 'Start Time',
        isSet: false,
        isDefault: false,
    },
    { columnDef: 'end_time', header: 'End Time', isSet: false, isDefault: false },
    {
        columnDef: 'actualHours',
        header: 'Actual Hours',
        isSet: false,
        isDefault: false,
    },
    {
        columnDef: 'time_sheet_estimated_hr',
        header: 'Estimated Hours',
        isSet: false,
        isDefault: false,
    },
    { columnDef: 'progress', header: 'Progress', isSet: false, isDefault: false },
    {
        columnDef: 'task_description',
        header: 'Task Description',
        isSet: false,
        isDefault: false,
    },
    {
        columnDef : 'days_variance',
        header: 'Day variance',
        isDefault: false,
        isSet: false,
    }
];

export const timeSheetConfigFieldArr: any = [
    {
        name: 'Start Date',
        value: 'start_date',
        isSet: false,
    },
    {
        isSet: false,
        name: 'Start Time',
        value: 'start_time',
    },
    {
        isSet: false,
        name: 'End Time',
        value: 'end_time',
    },
    {
        isSet: false,
        name: 'Actual Hours',
        value: 'actualHours',
    },
    {
        isSet: false,
        name: 'Estimated Hours',
        value: 'time_sheet_estimated_hr',
    },
];

export const globalClientTableElements = [
    {
        columnDef: 'staticClientName',
        header: 'Client Name',
        isDefault: true,
        index: -1,
    },
    { columnDef: 'companyName', header: 'Company Name', isDefault: false },
    { columnDef: 'email', header: 'Email', isDefault: false },
    { columnDef: 'mobileNumber', header: 'Mobile Number', isDefault: false },
    { columnDef: 'services', header: 'Services', isDefault: false },
    { columnDef: 'company_address', header: 'Company Address', isDefault: false },
];

export type DateInput = string | number | Date;
export type Diff =
  | 'years'
  | 'quarters'
  | 'months'
  | 'weeks'
  | 'days'
  | 'hours'
  | 'minutes'
  | 'seconds'
  | 'milliseconds';


  export const TIMESHEET_STATUS_TYPES = {
    APPROVED: 'approved',
    SUBMITTED: 'submitted',
};


export const DATE_TYPES: any = {
    DUE_DATE : 'due_date' ,
    CREATED_DATE : 'created_date',
    CLOSED_DATE : 'closed_date',
    START_DATE : 'start_date',
    COMPLETED_DATE: 'completed_at'
};

export const EMAIL_UNSUBSCRIBE_REASONS: any = {
    EMAIL_RELEVANT : 'The emails are not relevant to me.' ,
    EMAIL_FREQUENT : 'The emails too frequent.',
    REMEBER_SIGNING : "I don't remember signing up for this.",
    SPAM : 'I find the emails inappropriate or spam.',
    OTHER: 'Other(fill in the reason below)',
};
export const PRIORITY = [
    {
      priority_name: "medium",
      priority_color: "#FAB222",
      _id: "64955ef1059e95b61b3b7ca5"
    },
    {
      priority_name: "high",
      priority_color: "#DC3535",
      _id: "64955ef1059e95b61b3b7c9f"
    },
    {
      priority_name: "low",
      priority_color: "#16B975",
      _id: "64955ef1059e95b61b3b7ca3"
    }
  ];
  
export const DAY_VARIANCE_TYPES = [
    {
      id:'delayed',
      name:'Delayed'
    },
    {
        id:'on track',
        name:'On Track'
    },
    {
        id:'before time',
        name:'Before Time'
    }
]

export const IMAGE_UPLOAD_TYPE: any = {
    WEB:'web',
    CAMERA:'camera',
    GALLERY:'gallery'
};  

export const KEYNAME: any = {
    ALLOW_RIGHT:'ArrowRight',
    ALLOW_LEFT:'ArrowLeft',
}

export const MY_LEAVE_STATUS: any =[ 
  {
    name: 'Approved',
    value: 'approved',
    isCheck: false,
    status_color:'#16b975'
  },
  {
    name: 'Rejected',
    value: 'rejected',
    isCheck: false,
    status_color:'#dc3535'
  },
  {
    name: 'Pending',
    value: 'pending',
    isCheck: false,
    status_color:'#fab222'
  },
  {
    name: 'Cancelled',
    value: 'cancelled',
    isCheck: false,
    status_color:'#dc3535'
  }
]

export const PENDING_APPROVE_LEAVE_STATUS: any =[ 
    {
      name: 'Approved',
      value: 'approved',
      isCheck: false,
      status_color:'#16b975'
    },
    {
      name: 'Pending',
      value: 'pending',
      isCheck: false,
      status_color:'#fab222'
    },
 
];

export const HALFDAY_TYPE: any =[ 
    {
        name:'1st Half',
        value:'first-half'
    },
    {
        name:'2nd Half',
        value:'second-half'
    }
];


export const SHORTLEAVE_TYPE: any =[ 
    {
        name:'1st Quarter',
        value:'first-quarter'
    },
    {
        name:'2nd Quarter',
        value:'second-quarter'
    },
    {
        name:'3rd Quarter',
        value:'third-quarter'
    },
    {
        name:'4th Quarter',
        value:'fourth-quarter'
    }
];




export const FULL_LEAVE_TYPE: any =[ 
    {
        name:'Full Day',
        value:'fullday'
    }
];


export const LEAVE_TYPE: any = {
    FULLDAY : 'fullday',
    HALFDAY : 'halfday',
    FIRSTHALF : 'first-half',
    SECONDHALF : 'second-half',
    SHORTDAY : 'shortday',
    FIRSTQUARTER : 'first-quarter',
    SECONDQUARTER : 'second-quarter',
    THIRDQUARTER : 'third-quarter',
    FOURTHQUARTER : 'fourth-quarter'
}; 



export const LEAVE_DURATION_TYPE: any[] = [
    {
      name: 'Yearly',
      value: 'yearly'
    },
    {
      name: 'Monthly',
      value: 'monthly'
    }
  ];


  export const ATTENDANCE_STATUS: any =[ 
    {
      name: 'Present',
      value: 'present',
      status_color:'#16b975'
    },
    {
      name: 'Absent',
      value: 'absent',
      status_color:'#dc3535'
    },
    {
        name: 'Manual',
        value: 'manual',
        status_color:'#fab222'
    },
    {
      name: 'Holiday',
      value: 'holiday',
      status_color:'#2a5fb0'
    },
    {
      name: 'Weekend',
      value: 'weekend',
      status_color:'#fab222'
    }
  ];

  export const DYNAMIC_ATTENDANCE_HEADER: any = {
    WORKING_HOURS:'Working Hours',
    SPENT_HOURS:'Spent Hours',
    VARIANCE:'Variance',
    STATUS:'Status',
    LOCATION:'Location'
}; 

export const CUSTOM_FIELD_KEY:any={
    TASK_DESCRIPTION:'task_description',
    DESCRIPTION:'description',
    PROGRESS:'progress',
    PERCENTAGE:'percentage'
}

export const USER_SCREEN_HEIGHT:any={
    PX_720:720,
    PX_800:800,
    PX_1050:1050,
    PX_1280:1280,
    PX_1024:1024,
    PX_1080:1080,
    PX_1920:1920
}

export const NO_STORE_CATCH_URL_DATA:any[] = ['dms'];

export const BULK_EDIT_SECATIONS:any[] = [
    {
        title:'Pin Task',
        type:'pin',
        taskArr:[],
        count:0,
        pageIndex:1,
        limit:25,
        isSelectAll:false
    },
    {
        title:'Today',
        type:'today',
        taskArr:[],
        count:0,
        pageIndex:1,
        limit:25,
        order_by: "desc",
        isSelectAll:false
    },
    {
        title:'Overdue',
        type:'overdue',
        taskArr:[],
        count:0,
        pageIndex:1,
        limit:25,
        order_by: "desc",
        isSelectAll:false
    },
    {
        title:'Upcoming',
        type:'upcoming',
        taskArr:[],
        count:0,
        pageIndex:1,
        limit:25,
        isSelectAll:false
    }
];

export const BULK_EDIT_TOTAL_SECATION:any[] = [
    {
        title:'Total',
        type:'total',
        taskArr:[],
        count:0,
        pageIndex:1,
        limit:25,
        isSelectAll:false
    }
];

