import { QuickReplyService } from 'src/app/shared/services/task-service/quick-reply.service';
import { UserDetailsService } from './../../../shared/services/user-details.service';
import { StatusServiceService } from 'src/app/shared/services/organization/status-service.service';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { TasksService } from 'src/app/shared/services/task-service/tasks.service';
import { GetNewMemberService } from 'src/app/shared/services/organization/get-new-member.service';
import {
    Component,
    OnInit,
    ViewChild,
    Inject,
    Injector,
    Output,
    EventEmitter,
    OnDestroy,
    ElementRef,
    SimpleChanges,
    ChangeDetectorRef,
} from '@angular/core';
import { ApiService } from 'src/app/shared/services/api-service/api.service';
import { CustomFieldTaskClientService } from 'src/app/shared/services/custom-field-client-task/custom-field-task-client.service';
import { CustomFieldService } from 'src/app/shared/services/organization-settings/custom-field.service';
import { COUNTRY_ISD_CODE } from 'src/app/shared/global/isd-code';
import {
    MODULE_NAME,
    task_field_type,
    priority_name,
    status_name,
    TIME_LOG_TYPE,
    DISCUSSION_TYPES, discussionSocketEvents, DISCUSSION_COMMENT_TYPE, PRIORITY, IMAGE_UPLOAD_TYPE, FILE_CONFIGURATION
} from 'src/app/shared/global/constants';
import { RegexEnum } from 'src/app/shared/global/regex-enum';
import { TimesheetSettingService } from 'src/app/shared/services/organization-settings/timesheet-setting.service';
import { CountryISO } from 'ngx-intl-tel-input';
import { GroupAssigneeCommonCheckboxComponent } from 'src/app/shared/components/group-assignee-common-checkbox/group-assignee-common-checkbox.component';
import { CheckListTemplateService } from 'src/app/shared/services/organization-settings/check-list-template.service';
import { MatMenuTrigger } from '@angular/material/menu';
import * as moment from 'moment';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { OrganizationSettingsService } from 'src/app/shared/services/organization-settings/organization-settings.service';
import { UtilsService } from 'src/app/shared/services/utils-service/utils.service';
import { MarkAsApprovalService } from 'src/app/shared/services/task-service/mark-as-approval.service';
import * as CryptoJS from 'crypto-js';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import * as _ from 'lodash';
import { AttachmentServiceService } from 'src/app/shared/services/attachment-service/attachment-service.service';
import { TaskEmitService } from 'src/app/shared/services/task-service/task-emit.service';
import { Subscription, lastValueFrom, take } from 'rxjs';
import { LocalStorageService } from 'src/app/shared/services/storage-service/local-storage.service';
import { DiscussionService } from 'src/app/shared/services/discussion/discussion.service';
import { Router } from '@angular/router';
import { difference, isEqual } from 'lodash';
import { TimeSheetService } from 'src/app/shared/services/time-sheet-service/time-sheet.service';
import { TimerNoteModalComponent } from '../timer-note-modal/timer-note-modal.component';
import { DiscussionSocketService } from 'src/app/shared/services/discussion/discussion-socket.service';
import { ChecklistCommentComponent } from 'src/app/pages/task-management/checklist-comment/checklist-comment.component';
import { DmsService } from 'src/app/shared/services/dms-service/dms.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import * as localforage from 'localforage';
import { EmailManagementService } from 'src/app/shared/services/email-management/email-management.service';
import { Store } from '@ngrx/store';
import { taskState } from 'src/app/shared/ngrx/action/task-state.actions';
import { taskState as TaskState } from 'src/app/shared/ngrx/reducer/task-state.reducer';
import { CreateSubTaskComponent } from '../create-sub-task/create-sub-task.component';
import { AddClientComponent } from '../../organization/add-client/add-client.component';
import { SaveReportService } from 'src/app/shared/services/report-service/save-report.service';
import { AddServiceComponent } from '../../organization/add-service/add-service.component';
import { CompanyDetailsService } from 'src/app/shared/services/organization/company-details.service';
import { ClientServiceService } from 'src/app/shared/services/organization/client-service.service';
import { htmlToText } from 'html-to-text';
import { environment } from 'src/environments/environment';
@Component({
    selector: 'app-create-task',
    templateUrl: './create-task.component.html',
    styleUrls: ['./create-task.component.scss'],
    providers: [
        GroupAssigneeCommonCheckboxComponent,
        {
            provide: MAT_SELECT_CONFIG,
            useValue: { overlayPanelClass: 'customClass' },
        },
    ],
})
export class CreateTaskComponent implements OnInit, OnDestroy {
    toppingsControl = new FormControl([]);
    toppingList: string[] = [
        'Extra cheese',
        'Mushroom',
        'Onion',
        'Pepperoni',
        'Sausage',
        'Tomato',
    ];
    private _location: any;
    onToppingRemoved(topping: string) {
        const toppings = this.toppingsControl.value as string[];
        this.removeFirst(toppings, topping);
        this.toppingsControl.setValue(toppings); // To trigger change detection
    }

    private removeFirst<T>(array: T[], toRemove: T): void {
        const index = array.indexOf(toRemove);
        if (index !== -1) {
            array.splice(index, 1);
        }
    }
    taskDetail: any;
    discussion_id: any;
    userId: any;
    statusList: any = [];
    selectedStatus: any;
    selectedPriority: any;
    selectedCompanyId: any;
    taskDetailForm!: FormGroup;
    taskDueDateEpochTime: any;
    minDueDate: any;
    subtaskFormArr: any = [];
    selectedAssignees: any = [];
    selectedGroup: any = [];
    selectedReminderDate: Date | null;
    isTimeSheetSetting = false;
    fileSize: any;
    fileData: any;
    bigFile = false;
    newFiles: any[] = [];
    membersListData: any = [];
    myFiles: any;
    updateFiles: any[] = [];
    docId: any;
    taskStartDate: any;
    progressValue: any = 0;
    emailTask: any = false;
    selectedAssigneesFromBE: any;
    dueDateDataObj: any = {
        frequency: '',
        startTime: '00:00',
        startDate: '',
        endDate: '',
        endTime: '00:00',
        isTaskRecurring: false,
        weekDays: '',
        recurringDate: '',
        onSelectedDay: '',
        customOnDay: '',
        taskDueDate: '',
        isUpdate: false,
    };
    subTaskArr: any = [];
    primaryTask = true;
    reminderValue = false;
    reminderDetail: any;
    attachmentData: any;
    task_id: any;
    reminderObj: any = {};
    subTasks: any;
    subTaskCount: any;
    progressVisible: boolean = false;
    priority: any = [];
    isActiveContent = true;
    fields: any = [];
    moduleId: any;
    moduleName: string;
    isCustomize = 0;
    public customForm: FormGroup;
    unSubscribe: any;
    companyId: any;
    parentId: any;
    is_task_active = false;
    attachment_count: any;
    public clientCustomList: CustomFieldTaskClientService;
    public customFieldService: CustomFieldService;
    public organizationSettings: OrganizationSettingsService;
    public attachmentService: AttachmentServiceService;
    public emitTaskService: TaskEmitService;
    public notificationService: NotificationService;
    tArray: any = [];
    is_active = false;
    countries: any = [];
    preferredCountries: any = CountryISO;
    selectedCountry: any = [];
    clientListData: any = [];
    clientListName = 'Client';
    serviceListData: any = [];
    selectedClient: any = [];
    serviceListName = 'Service';
    selectedService: any = [];
    followerListName = 'Follower';
    followerListData: any = [];
    selectedFollower: any = [];
    originalFollowerListData: any = [];
    followerList: any = [];
    reminderOn: any = false;
    isClose: any;
    originalServiceListData: any = [];
    originalClientListData: any = [];
    groupList: any = [];
    removeFollower: any = [];
    isReminder: any = false;
    isShowCheckList: boolean;
    checklistData: any = [];
    selectedChecklistData: any = [];
    public checklistService: CheckListTemplateService;
    markAsMandatory = false;
    selectedMatPreview: any = [];
    originalGroupListData: any = [];
    clientMatPreview: any = [];
    followerMatPreview: any = [];
    serviceMatPreview: any = [];
    @ViewChild('menuTrigger') trigger: any;
    minDate: any;
    @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;
    @ViewChild('menuSubtaskCheckList') menuSubtaskCheckList: any;
    @ViewChild(MatMenuTrigger) alarmmenu: MatMenuTrigger;
    listArrName = '';
    tempFollower: any = [];
    userRolePermission: any;
    markAsCompleted = false;
    decryptedUserId: any;
    isReopen: any;
    userEmail: any;
    toggleValue = false;
    assigneeFollowerCount = 0;
    timeLogDetail: any = [];
    @Output('onTimerEvent') onTimerEvent = new EventEmitter();
    @Output('totalMemberCount') totalMemberCount = new EventEmitter();
    taskCreatorInfo: any;
    markAsApproval = false;
    allStatusList: any = [];
    statusNameFormConst = status_name;
    markAsApprovalData: any;
    public markAsApprovalService: MarkAsApprovalService;
    memberId: any;
    decryptSelectedCompanyId: any;
    disableAllFunctionality = true;
    canMarkAsApprove: any;
    timeLogData: any;
    timerShow = false;
    timerToggle = false;
    timerObj: any;
    eventType: any;
    decryptedCompanyId: any;
    timerId: any;
    setTimerId: any;
    isTimerRunning = false;
    timeSpent = 0;
    openSearchTaskId: any;
    timeSpentHMS = ['00', '00', '00'];
    timerInterval!: any;
    docPrimaryId: any;
    deleteDocObj: any;
    primary_id: any;
    finalCode: any;
    fieldsCtrl: any = {};
    statusObj: any;
    selectedCompanyName: any;
    companyData: any;
    loginUserData: any;
    newSubTaskArr: any = [];
    addSubTask = 0;
    attatchmentDetail: any[] = [];
    numSubtask: any;
    statusSubscription: Subscription;
    maxDateProject: any;
    minDateProject: any;
    projectListData: any = [];
    projectList: any = [];
    selectedProjectId: any = [];
    projectMatPreview: any = [];
    projectMembers: any = [];
    taskData: any;
    selectedRoom: any = {};
    currentTask: any[] = [];
    companyEmployees: any;
    weekValue: any = [];
    changeValueObj: any = {};
    checkListData: any = [];
    customFieldOldArr: any = [];
    customFieldNewArr: any = [];
    checklistTitle: any = [];
    assigneeUser: any = [];
    assigneeGroup: any = [];
    currentSubTaskId: any = null;
    checklistCommentComponent: MatDialogRef<ChecklistCommentComponent>;
    allSelectedUsersList: any = [];
    timerEvent: any = null;
    timerValue = '00:00:00';
    eventName: any;
    intervalId: any;
    timerResponseObj: any;
    ActualHours: any;
    public timeSheetService: any;
    isAnyTaskOngoingOfUser: any;
    estimatedHours: any;
    groupsInfo: any = [];
    multipleEmailTask = false;
    selectedEmailId: any;
    taskUsers: any[] = [];
    taskState: any;
    subscription: Subscription;
    isShowClientList = true;
    isShowService = true;
    submitBtnDisable = false;
    content = '';
    coMembersList: any;
    coMembersDataListArr: any;
    @ViewChild('textareaRef', { static: false })
    textareaRef: ElementRef<HTMLTextAreaElement>;
    @ViewChild('elementRefDiv', { static: false }) elementRef: ElementRef;
    @ViewChild('elementRefDivClient', { static: false })
    elementRefDivClient: ElementRef;

    dynamicWidth: any;
    dynamicClientWidth: any;

    // this is for quick reply
    quickReplyList: any = [];
    quickReplyFilter: any = [];
    isEnableDropdown: any = [];
    taskNameValue = '';
    taskDescValue = '';
    inputValue = '';
    @ViewChild(CreateSubTaskComponent) createSubtask: CreateSubTaskComponent;
    allMemberListData: any[] = [];
    disableClient = false;
    setReadStatus: any;
    @Output('isUpdateTaskid') isUpdateTaskid = new EventEmitter();
    public taskListData: TasksService;
    markAsSubtaskMandatory = false;
    keyByValueFollower: any;
    updateStatusChange: boolean = false;
    clientDataPreview:any;
    company_all_members_with_deleted_members:any[] = [];
    constructor(
        public matDialog: MatDialog,
        public dialogRef: MatDialogRef<CreateTaskComponent>,
        private toastrService: ToastrService,
        private dateAdapter: DateAdapter<Date>,
        public translate: TranslateService,
        public formBuilder: FormBuilder,
        public userDetailService: UserDetailsService,
        private statusService: StatusServiceService,
        public getMemberList: GetNewMemberService,
        private taskService: TasksService,
        private apiService: ApiService,
        private localStorage: LocalStorageService,
        public timeSheetSettingService: TimesheetSettingService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public injector: Injector,
        public dialog: MatDialog,
        public utils: UtilsService,
        public discussionService: DiscussionService,
        public router: Router,
        private dmsService: DmsService,
        private discussionSocketService: DiscussionSocketService,
        public emailService: EmailManagementService,
        public quickReplyService: QuickReplyService,
        private store: Store<{ taskState: TaskState }>,
        public reportService: SaveReportService,
        public companyDetailService: CompanyDetailsService,
        private cdr: ChangeDetectorRef,
        private clientService:ClientServiceService
    ) {
        this.isEnableDropdown.isCheckListEnable = [];
        this.dialogRef.disableClose = true;
        translate.setDefaultLang('en');
        this.dateAdapter.setLocale('en-GB');
        this.clientCustomList = injector.get<CustomFieldTaskClientService>(
            CustomFieldTaskClientService
        );
        this.organizationSettings = injector.get<OrganizationSettingsService>(
            OrganizationSettingsService
        );
        this.customFieldService =
            injector.get<CustomFieldService>(CustomFieldService);
        this.checklistService = injector.get<CheckListTemplateService>(
            CheckListTemplateService
        );
        this.minDate = new Date();
        this.markAsApprovalService = injector.get<MarkAsApprovalService>(
            MarkAsApprovalService
        );
        this.attachmentService = injector.get<AttachmentServiceService>(
            AttachmentServiceService
        );
        this.emitTaskService = injector.get<TaskEmitService>(TaskEmitService);
        this.timeSheetService = injector.get<TimeSheetService>(TimeSheetService);
        this.notificationService =
            injector.get<NotificationService>(NotificationService);
        this.store.select('taskState').subscribe((ele: any) => {
            if (!ele.taskState) return;
            this.taskState = ele?.taskState;
        });
        this.taskListData = injector.get<TasksService>(TasksService);
    }

    async ngOnInit() {
        this.taskDetailFormInit();
        this.userId = await this.userDetailService.encryptUserId;
        this.decryptedUserId = await this.userDetailService.userId;
        await this.userDetailService.getUserRolePermission(this.decryptedUserId, this.decryptSelectedCompanyId);
        this.userRolePermission = await this.userDetailService.getUserRolePermissions();
        await this.getclientList();
        await this.getCoMemberInCompany();
        this.getProjectList();
        this.removedIdFromMat();
        this.getKanbanStatus();
        this.isEnableDropdown.isCheckListEnable = [];
        localforage.removeItem('tasklocalcomment');

        this.userEmail = await this.userDetailService.getUserEmail();
        this.companyId = await this.userDetailService.getCompanyId();
        this.selectedCompanyId =
            await this.userDetailService.getSelectedCompanyId();
        this.decryptedCompanyId = CryptoJS.AES.decrypt(
            this.selectedCompanyId?.trim(),
            ''
        ).toString(CryptoJS.enc.Utf8);

        this.decryptSelectedCompanyId = parseInt(
            CryptoJS.AES.decrypt(this.selectedCompanyId?.trim(), '').toString(
                CryptoJS.enc.Utf8
            )
        );
        this.memberId = this.userRolePermission.memberId;
        this.selectedCompanyName =
            await this.userDetailService.getSelectedCompanyName();
        this.companyData = await this.userDetailService.getSelectedCompanyData();
        const loginUserData: any = await this.userDetailService.getLoginUserData();
        const trimmedString = loginUserData?.user_name.replace(/\s+/g, ' ');
        const name = trimmedString?.split(' ');
        this.loginUserData = {
            user_id: loginUserData?.id,
            first_name: name[0].trim(),
            last_name: name[1].trim(),
            profile_image:
                loginUserData?.profile_image?.charAt(0) == 'h'
                    ? loginUserData?.profile_image
                    : '#FAB222',
        };
        this.companyData['name'] = this.companyData?.company_name;
        delete this.companyData?.company_name;
        delete this.companyData?.user_company_relations;
        delete this.companyData?.member_count;
        delete this.companyData?.file_size;
        await this.getMarsAsApprovalFlowData();
        try{
            this.getGeneralSetting();
        }catch(e){}
        try{
            // await this.getCompanyDetails(this.selectedCompanyId);
        }catch(e){}
        try {await this.getModuleData()} catch (error) { }
        await this.getFollowerListData();
        await this.getCompanyAllMemberListData()
        await this.getGroupList();
        try{
            await this.getManageFieldDetail(this.selectedCompanyId);
        }catch(e){}
        await this.getTimeSheetDetail(false);
        try{
            await this.getStatusList();
        }catch(e){ }
        this.checkTimer();
        if (this.is_task_active && this.is_active) {
            await this.getCustomField();
            this.customForm = new FormGroup({
                fields: new FormControl(JSON.stringify(this.fields)),
            });
            this.unSubscribe = this.customForm.valueChanges.subscribe(
                (update: any) => {
                    this.fields = JSON.parse(update.fields);
                }
            );
        } else {
            this.customForm = this.formBuilder.group({});
            this.customForm?.updateValueAndValidity();
        }
        await this.getPriority();
        if (this.data?.editTaskId) {
            await this.getTaskDetailById();
            if (
                !this.data?.isEditTask ||
                this.selectedStatus?.status_name === status_name.UNDER_REVIEW ||
                this.selectedStatus?.status_name === status_name.CLOSED ||
                this.timerResponseObj?.task_id === this.data?.editTaskId
            ) {
                this.disableAllFunctionality = false;
            }
        }
        this.minDueDate = new Date();
        this.getCheckTemplateList(this.selectedCompanyId, this.userId);
        this.patchProjectOnAdd();
        if (this.router.url.includes('/user-profile') && !this.data.isEditTask) {
            const user_profile_data: any = await JSON.parse(
                this.localStorage.getData('user-profile-details')
            );
            this.taskDetailForm.controls['taskAssignee'].setValue([
                user_profile_data.favourite_member_id,
            ]);
            this.selectedAssigneesFromBE = {
                assignees: [user_profile_data.favourite_member_id],
                groups: [],
                mainTask: true,
            };
        }
        if (this.data.email) {
            await this.getTimeSheetDetail(true);
            this.convertTaskFromEmail();
        }
        this.adjustTextareaHeight();
        this.taskState?.reportConfig?.company_all_members.forEach(
            (element: any) => {
                if (
                    !this.allMemberListData.some(
                        (item: any) => item.user_id === element.user_id
                    )
                ) {
                    this.allMemberListData.push(element);
                }
            }
        );
        this.taskState?.reportConfig?.co_members.forEach(
            (element: any) => {
                if (
                    !this.allMemberListData.some(
                        (item: any) => item.user_id === element.user_id
                    )
                ) {
                    this.allMemberListData.push(element);
                }
            }
        );
        this.readOnNav();
    }

    async patchProjectOnAdd() {
        if (this.data && !this.data.editTaskId && this.data.projectData) {
            this.projectSelection([this.data.projectData._id]);
        }
    }

    async getProjectList() {
        (await this.taskService.getProjectList((this.userRolePermission?.owner || this.userRolePermission?.superAdmin) ? '1' : '0')).subscribe(async (res: any) => {
            if (res) {
                await res.forEach((project: any) => {
                    const data = {
                        name: project.project_name,
                        id: project._id,
                        isCheck: false,
                        projectMembers: project.assignees,
                        start_date: project.start_date,
                        end_date: project.end_date,
                        client: project.client,
                        owner: project.owner,
                        project_admin: project.project_admin,
                        co_members: project.co_members,
                        groups: project.groups
                    };
                    this.projectListData.push(data);
                });
                this.projectList = this.projectListData;
            }
        });
    }

    async projectSelection(selectedId: any, fromChildProject = false) {
        this.disableClient = false;
        this.resetFormData();
        let selectedProject: any;
        if (selectedId.length) {
            selectedProject = this.projectListData.find(
                (projectData: any) => projectData.id === selectedId[0]
            );
            if (selectedProject) {
                if (selectedProject?.client?.id) {
                    this.disableClient = true;
                    this.onSelectClientCheckBox([selectedProject.client.id]);
                }


                let projectEndDate: any = moment(selectedProject.end_date);
                let inputEndDate: any = moment(this.taskDetailForm.get('taskDueDate').value);
                let projectStartDate: any = moment(selectedProject.start_date);
                let inputstartDate: any;


                // Compare the moments directly
                if (projectEndDate.isBefore(inputEndDate)) {
                    this.taskDetailForm.get('taskDueDate').setValue('');
                    this.maxDateProject = selectedProject.end_date
                        ? new Date(selectedProject.end_date)
                        : null;
                    this.minDateProject = selectedProject.start_date
                        ? new Date(selectedProject.start_date)
                        : new Date();
                    this.toastrService.error(
                        'Task Due Date cannot be greater than Project Due date'
                    );
                }
                else if (projectEndDate.isAfter(inputEndDate)) {
                    if (this.isTimeSheetSetting)
                        inputstartDate = moment(this.dueDateDataObj?.startDate);
                    else {
                        inputstartDate = moment(this.dueDateDataObj?.taskstartDate);
                    }
                    if (inputstartDate.isBefore(projectStartDate) && inputEndDate.isBefore(projectEndDate)) {
                        this.maxDateProject = selectedProject.end_date
                            ? new Date(selectedProject.end_date)
                            : null;
                        this.minDateProject = new Date(selectedProject.start_date)
                    }
                }
                else {
                    if (selectedProject.end_date != null) {
                        this.maxDateProject = selectedProject.end_date
                            ? new Date(selectedProject.end_date)
                            : null;
                        this.minDateProject = new Date(selectedProject.start_date)
                    }
                }
            } else {
                this.maxDateProject = null;
                this.minDateProject = null;
            }
            await this.projectListData.forEach((project: any) => {
                if (selectedId[0] === project.id) {
                    project.isCheck = true;
                } else {
                    project.isCheck = false;
                }
            });
        } else {
            this.dueDateDataObj.startDate = '';
            this.dueDateDataObj.endDate = '';
            selectedProject = [];
            this.projectMatPreview = [];
            this.taskDetailForm.get('taskDueDate').setValue('');
            this.maxDateProject = null;
            this.minDateProject = null;
            this.clientMatPreview=null;
            await this.projectListData.forEach((project: any) => {
                project.isCheck = false;
            });
        }

        this.selectedProjectId = selectedId;
        this.setMatPreview('project');
        selectedId.length > 0
            ? await (this.projectMembers = this.projectListData
                .find((ele: any) => ele.id === this.selectedProjectId[0])
                ?.projectMembers.map((ele: any) => ele.id))
            : '';
        if (selectedProject?.owner?.member_id) {
            this.projectMembers.push(selectedProject.owner.member_id);
        }
        if (selectedProject?.co_members?.length > 0) {
            selectedProject?.co_members.forEach((proj: any) => {
                this.projectMembers.push(proj.id);
            });
        }
        if (selectedProject?.project_admin?.length > 0) {
            selectedProject?.project_admin.forEach((proj: any) => {
                this.projectMembers.push(proj.id);
            });
        }


        let matchedObjectsArray3: any[] = [];
        selectedProject?.groups?.forEach((item1: any) => {
            let matchedObjectArray2 = this.taskState?.reportConfig?.group_details?.find((obj2: any) => obj2.id === item1.id);
            if (matchedObjectArray2) {
                matchedObjectArray2?.members_details?.forEach((memberId: any) => {
                    let matchingObjectArray3 = this.taskState?.reportConfig?.company_members?.find((obj3: any) => obj3.id === memberId);
                    if (matchingObjectArray3) {
                        this.projectMembers.push(matchingObjectArray3.id);
                    }
                });
            }
        });
        const groupId: any[] = await selectedProject?.groups?.map((ele: any) => ele?.id) || [];
        this.projectMembers = _.uniq(this.projectMembers);
        if (selectedId?.length > 0) {
            this.taskService.projectIdEvent({ projectMembers: [...this.projectMembers], projectGroup: [...groupId] });
            const arr: any = Object.assign([], this.coMembersDataListArr);
            this.followerList = [...this.followerList, ...arr];
            this.followerListData = this.followerList.filter((ele: any) =>
                this.projectMembers.includes(ele.id)
            );

            this.originalFollowerListData = this.followerListData.map((item: any) =>
                Object.assign({}, item)
            );
            !this.data?.editTaskId
                ? this.onSelectCheckBox({ groups: [], assignees: [] })
                : '';
            !this.data?.editTaskId
                ? this.createSubtask.resetAssigneeOnProjectChange()
                : '';
        } else {
            const coMemberId: any = this.coMembersDataListArr.map((id: any) => id.id);
            this.followerList = this.followerList.filter(
                (ele: any) => !coMemberId.includes(ele.id)
            );
            this.followerListData = this.followerList;
            this.originalFollowerListData = this.followerList;
            this.selectedMatPreview = [];
            this.taskService.projectIdEvent([]);
            !this.data?.editTaskId
                ? this.onSelectCheckBox({ groups: [], assignees: [] })
                : '';
            !this.data?.editTaskId
                ? this.createSubtask?.resetAssigneeOnProjectChange()
                : '';
        }
        this.setMatPreview('follower');
        if (this.selectedFollower) {
            this.selectedFollower.forEach((follower: any) => {
                this.followerListData = this.originalFollowerListData.filter(
                    (data: any) => {
                        if (follower === data.id) {
                            this.selectedFollower.push(data.id);
                            return (data.isCheck = true);
                        }
                    }
                );
            });
        }
    }

    async getCoMemberInCompany() {
        this.coMembersDataListArr = [];
        if (this.taskState?.reportConfig?.co_members) {
            const coMember: any = JSON.parse(
                JSON.stringify(this.taskState?.reportConfig?.co_members)
            );
            const comembers: any = [];
            coMember?.forEach((element: any) => {
                const dataobj: any = {
                    name: element.first_name + ' ' + element.last_name,
                    id: element.id,
                    profile_image: element.profile_image || '#f2793d',
                    isCheck: false,
                    first_name: element.first_name,
                    last_name: element.last_name,
                    email: element.email,
                };
                comembers.push(dataobj);
            });
            this.coMembersDataListArr = Object.assign([], comembers);
            this.coMembersList = _.keyBy(comembers, 'id');
        }
    }

    resetFormData() {
        this.followerListData.forEach((follower: any) => {
            follower.isCheck = false;
        });
        this.selectedFollower = [];
        this.followerMatPreview = [];
    }

    async getTimeSheetDetail(isApiCall: boolean) {
        if (isApiCall) {
            (
                await this.timeSheetSettingService.getTimesheetMasterSetting(
                    this.decryptedCompanyId
                )
            ).subscribe((dataRes: any) => {
                this.timeLogData = dataRes?.result?.timesheetConfig;
                if (dataRes?.result) {
                    this.isTimeSheetSetting =
                        dataRes?.result?.is_enabled === 1 ||
                            dataRes?.result?.is_enabled === true
                            ? true
                            : false;

                }
                this.checkTimeLog();
            });
        } else {
            this.timeLogData = this.taskState?.timesheetConfig;
            if (this.taskState?.timesheetConfig) {
                this.isTimeSheetSetting =
                    this.taskState.timesheetConfig?.is_enabled === 1 ||
                        this.taskState.timesheetConfig?.is_enabled === true
                        ? true
                        : false;
            }
            this.checkTimeLog();
        }
    }
    taskDetailFormInit() {
        this.taskDetailForm = this.formBuilder.group({
            taskName: ['', [Validators.required,this.utils.noWhitespaceValidator]],
            taskDesc: [''],
            taskDueDate: ['', [Validators.required]],
            taskPriority: ['', [Validators.required]],
            taskStatus: ['', [Validators.required]],
            taskAssignee: ['', [Validators.required]],
            subTask: [''],
            checkList: this.formBuilder.array([]),
            recurringFrequency: [''],
            recurringFrequencyData: [''],
        });
    }

    addMultipleSubTask() {
        this.addSubTask += 1;
        this.scrollDownOnAddSubtask();
    }

    async deleteSubTask(index: number) {
        if (index !== -1 && !this.data) {
            this.subtaskFormArr.removeAt(index);
        } else if (this.data.editTaskId) {
            this.subtaskFormArr.value[index]._id !== ''
                ? this.deleteTask(this.subtaskFormArr.value[index]._id, true)
                : this.subtaskFormArr.removeAt(index);
            this.subtaskFormArr.removeAt(index);
        }
        this.taskDetailForm.updateValueAndValidity();
    }

    get subTasksList() {
        return this.taskDetailForm?.get('subTask') as FormArray;
    }

    setSubTaskStatus(selectedStatus: any, index: number) {
        const myForm = (<FormArray>this.taskDetailForm.get('subTask')).at(index);
        myForm.patchValue({
            subTaskStatus: selectedStatus,
        });
    }

    setSubTaskPriority(priorityObj: any, index: number) {
        const myForm = (<FormArray>this.taskDetailForm.get('subTask')).at(index);
        myForm.patchValue({
            subTaskPriority: priorityObj,
        });
    }

    async onSelectSubTaskCheckBox(selectedId: any, index: number) {
        const myForm: any = (<FormArray>this.taskDetailForm.get('subTask')).at(
            index
        );
        if (selectedId.assignees?.length === 0 && selectedId.groups?.length === 0) {
            myForm.controls['subTaskGroup'].setValue([]);
            myForm.controls['subTaskAssignee'].setValue([]);
            myForm.controls['subTaskGroup'].setValidators(Validators.required);
            myForm.controls['subTaskAssignee'].setValidators(Validators.required);
            myForm.updateValueAndValidity();
            this.taskDetailForm.markAsDirty();
            return;
        }

        if (selectedId.groups.length) {
            myForm.controls['subTaskGroup'].setValue(selectedId.groups);
            myForm.controls['subTaskAssignee'].setValue(null);
            myForm.controls['subTaskAssignee'].clearValidators();
            myForm.controls['subTaskAssignee'].setErrors(null);
            myForm.controls['subTaskGroup'].setValidators(Validators.required);
            myForm.updateValueAndValidity();
            this.taskDetailForm.markAsDirty();
            return;
        }

        if (selectedId.assignees.length) {
            myForm.controls['subTaskAssignee'].setValue(selectedId.assignees);
            myForm.controls['subTaskGroup'].setValue(null);
            myForm.controls['subTaskGroup'].clearValidators();
            myForm.controls['subTaskGroup'].setErrors(null);
            myForm.controls['subTaskAssignee'].setValidators(Validators.required);
            myForm.updateValueAndValidity();
            this.taskDetailForm.markAsDirty();
            return;
        }
    }

    selectedSubTaskDate(data: any, index: number) {
        const myForm = (<FormArray>this.taskDetailForm.get('subTask')).at(index);
        if (
            this.isTimeSheetSetting &&
            data.startDate !== 'Invalid Date' &&
            data.endDate !== 'Invalid Date'
        ) {
            myForm.patchValue({
                subTaskDueDate: data?.endDate?.getTime(),
                subTaskStartDate: data?.startDate?.getTime(),
            });
            data.inValidStartEndTime
                ? myForm.setErrors({ required: true })
                : myForm.setErrors(null);
            this.taskDetailForm.updateValueAndValidity();
        } else if (!this.isTimeSheetSetting) {
            myForm.patchValue({
                subTaskDueDate: data?.taskDueDate?.getTime(),
            });
        }
    }

    selectedDate(data: any) {
        this.dueDateDataObj = data;
        data.inValidStartEndTime || !data.taskDueDate
            ? this.taskDetailForm.controls['taskDueDate'].setErrors({
                required: true,
            })
            : this.taskDetailForm.controls['taskDueDate'].setErrors(null);
        if (
            this.isTimeSheetSetting &&
            data.startDate &&
            data.endDate &&
            data.startDate !== 'Invalid Date' &&
            data.endDate !== 'Invalid Date'
        ) {
            this.taskDetailForm.controls['taskDueDate'].setValue(
                new Date(data.endDate).getTime()
            );
            this.taskDetailForm.updateValueAndValidity();
        } else if (!this.isTimeSheetSetting && data.taskDueDate) {
            this.taskDetailForm.controls['taskDueDate'].setValue(
                new Date(data.taskDueDate).getTime()
            );
        }
        if (this.dueDateDataObj?.isTaskRecurring) {
            this.taskDetailForm.controls['recurringFrequency'].setValue(
                this.dueDateDataObj?.frequency
            );
            !this.dueDateDataObj?.frequency
                ? this.taskDetailForm.controls['recurringFrequency'].setErrors({
                    required: true,
                })
                : this.taskDetailForm.controls['recurringFrequency'].setErrors(null);
            if (
                this.dueDateDataObj?.weekDays?.length == 0 &&
                !this.dueDateDataObj?.onSelectedDay &&
                !this.dueDateDataObj?.recurringDate &&
                !this.dueDateDataObj?.customOnDay
            ) {
                this.taskDetailForm.controls['recurringFrequencyData'].setValue(
                    this.dueDateDataObj?.weekDays ||
                    this.dueDateDataObj?.onSelectedDay ||
                    this.dueDateDataObj?.recurringDate ||
                    this.dueDateDataObj?.customOnDay
                );
                this.taskDetailForm.controls['recurringFrequencyData'].setErrors({
                    required: true,
                });
            } else {
                this.taskDetailForm.controls['recurringFrequencyData'].setValue(
                    this.dueDateDataObj?.weekDays ||
                    this.dueDateDataObj?.onSelectedDay ||
                    this.dueDateDataObj?.recurringDate ||
                    this.dueDateDataObj?.customOnDay
                );
                this.taskDetailForm.controls['recurringFrequencyData'].setErrors(null);
            }
        } else {
            this.taskDetailForm.controls['recurringFrequency'].setErrors(null);
            this.taskDetailForm.controls['recurringFrequencyData'].setErrors(null);
            this.taskDetailForm.updateValueAndValidity();
        }
    }

    async getStatusList() {
        try {
            (await this.statusService.getStatus(this.selectedCompanyId)).subscribe(
                async (data: any) => {
                    const statusName = Object.assign([], data[0]);
                    this.allStatusList = statusName;
                    statusName?.forEach((element: any) => {
                        if (element?.is_active) {
                            delete element?.company_id;
                            delete element?.is_default;
                            delete element?.hide_filter;
                            if (
                                element?.status_name === status_name.REJECTED ||
                                element?.status_name === status_name.UNDER_REVIEW ||
                                element?.status_name === status_name.CLOSED
                            ) {
                                return;
                            } else {
                                this.statusList.push({
                                    status_color: element.status_color,
                                    status_name: element.status_name,
                                    id: element.id,
                                });
                            }
                        }
                    });
                    this.statusList = await this.statusList.filter(
                        (ele: any) => ele.status_name !== 'status deleted'
                    );
                    this.statusList = [...this.statusList];
                    if (!this.selectedStatus) {
                        this.selectedStatus = this.statusList.filter(
                            (_data: any) => _data.status_name === status_name.PENDING
                        )[0];
                    }
                    this.taskDetailForm.controls['taskStatus'].setValue(
                        this.selectedStatus
                    );
                }
            );
        } catch (error) {
            if(!this.data.isEditTask && this.allStatusList?.length == 0){
                const pendingStatus:any = JSON.parse(JSON.stringify(this.taskState?.reportConfig?.status?.find((ele:any)=>ele.status_name == "pending")));
                this.selectedStatus = pendingStatus;
                delete this.selectedStatus.is_active;
                this.statusList.push(this.selectedStatus)
                this.taskDetailForm.controls['taskStatus'].setValue(
                    this.selectedStatus
                );
                
            }
        }
    }

    async getPriority() {

        this.priority = PRIORITY;
        this.priority = [...this.priority];
        this.selectedPriority = this.priority.find(
            (_data: any) => _data?.priority_name === priority_name.low
        );
        this.taskDetailForm.controls['taskPriority'].setValue(
            this.selectedPriority
        );

    }

    async getclientList() {
        this.clientListData = [];
        this.originalClientListData = [];
    }

    async onSelectClientCheckBox(selectedId: any) {
        if (selectedId.length && this.projectMatPreview) {
            let clientId:any=CryptoJS.AES.encrypt(
                selectedId[0]?.toString().trim(),
                ''
            ).toString();
            let clientData:any;
            (await this.clientService.getClientDetailsById(clientId)).subscribe((res:any)=>{
                clientData={ id: res?.id, name: res?.client_name, isCheck: true, profile_image: res?.profile_image };
                this.receiveClientData([clientData])
            });
        }
    }

    async getRegularService() {
        if (this.data?.editTaskId) {
            return
        }
        this.serviceListData = [];
        this.originalServiceListData = [];
        (await this.taskService.getRegularServiceList(this.selectedCompanyId)).subscribe(
            (resData: any) => {
                if (resData) {
                    resData?.forEach((ele: any) => {
                        if (!ele.is_recurring) {
                            const dataObj = {
                                id: ele.id,
                                name: ele.service_name,
                                isCheck: false,
                            };
                            if (ele.is_active) {
                                this.serviceListData.push(dataObj);
                            }
                            this.originalServiceListData.push(dataObj);
                        }
                    });
                }
            }
        );
    }

    async onSelectServiceCheckBox(selectedId: any) {
        if (selectedId.length) {
            await selectedId.forEach((ele: any) => {
                this.serviceListData.forEach((data: any) => {
                    if (data.id === ele) {
                        data.isCheck = true;
                    } else {
                        data.isCheck = false;
                    }
                });
            });
            this.serviceListData = [...this.serviceListData];
        }
        this.selectedService = selectedId;
        this.setMatPreview('service');
    }

    async getGroupList() {
        const groupData = this.taskState?.reportConfig?.group_details;
        this.groupList = [];
        if (groupData) {
            this.groupsInfo = groupData;
            await groupData.forEach((ele: any) => {
                const data = {
                    name: ele.group_name,
                    id: ele.id,
                    members: ele.members_details,
                    profile_image: ele.color_id,
                };
                this.groupList.push(data);
            });
        }
        this.originalGroupListData = this.groupList;
    }

    async getFollowerListData() {
        const member = this.taskState?.reportConfig?.company_members;
        if (member) {
            this.companyEmployees = member;
            await member.forEach((ele: any) => {
                const data = {
                    name: ele.first_name + ' ' + ele.last_name,
                    first_name: ele.first_name,
                    last_name: ele.last_name,
                    id: ele.id,
                    email: ele.email,
                    profile_image: ele.profile_image,
                    isCheck: false,
                };
                this.followerListData.push(data);
            });
            this.originalFollowerListData = this.followerListData;
            this.followerList = this.followerListData;
            this.keyByValueFollower = _.keyBy(this.companyEmployees, 'user_id');
        }

    }



    async getCompanyAllMemberListData() {
        const member:any[] = JSON.parse(JSON.stringify(this.taskState?.reportConfig?.company_all_members_with_deleted_members));
        if (member) {
            await member.forEach((ele: any) => {
                const data = {
                    name: ele.first_name + ' ' + ele.last_name,
                    first_name: ele.first_name,
                    last_name: ele.last_name,
                    id: ele.id,
                    email: ele.email,
                    profile_image: ele.profile_image,
                };
                this.company_all_members_with_deleted_members.push(data);
            });
        }
    } 

    openFollower() {
        if (this.selectedAssignees.length > 0) {
            this.followerListData = this.originalFollowerListData.filter(
                (ele: any) => {
                    if (!this.selectedAssignees.includes(ele.id)) {
                        return ele;
                    }
                }
            );
        } else if (this.removeFollower.length > 0) {
            this.followerListData = this.originalFollowerListData.filter(
                (ele: any) => {
                    if (!this.removeFollower.includes(ele.id)) {
                        return ele;
                    }
                }
            );
        } else {
            this.followerListData = this.originalFollowerListData;
        }
        this.followerListData = this.followerListData.filter(
            (data: any) => (data.email !== this.userEmail && data.id !== this.keyByValueFollower[this.taskData?.owner?.user_id]?.id)
        );
        this.followerListData = [...this.followerListData];
        this.tempFollower = this.followerListData;
    }

    async onSelectFollowerCheckBox(selectedId: any) {
        this.selectedFollower = [];
        this.selectedFollower = await this.tempFollower
            .filter((ele: any) => ele.isCheck)
            .map((data: any) => data.id);
        this.taskService.removeAssignees(this.selectedFollower);
        this.setMatPreview('follower');
    }

    search(event: any, originalArr: any, listArr: any, listArrName: any) {
        const searchInput = event.target.value.toLowerCase();
        listArrName === task_field_type.follower
            ? (originalArr = this.tempFollower)
            : '';
        listArr = originalArr.filter((ele: any) => {
            const name = ele.name.toLowerCase();
            return name.includes(searchInput);
        });

        switch (listArrName) {
            case task_field_type.client:
                this.clientListData = [...listArr];
                for (const element of this.clientListData) {
                    this.selectedClient && this.selectedClient[0] === element.id
                        ? (element.isCheck = true)
                        : (element.isCheck = false);
                }
                return this.clientListData;
            case task_field_type.follower:
                this.followerListData = [...listArr];
                return this.followerListData;
            case task_field_type.service:
                this.serviceListData = [...listArr];
                for (const element of this.serviceListData) {
                    this.selectedService && this.selectedService[0] === element.id
                        ? (element.isCheck = true)
                        : (element.isCheck = false);
                }
                return this.serviceListData;
            case task_field_type.project:
                this.projectListData = [...listArr];
                for (const element of this.projectListData) {
                    this.selectedProjectId && this.selectedProjectId[0] === element.id
                        ? (element.isCheck = true)
                        : (element.isCheck = false);
                }
                return this.projectListData;
            default:
                break;
        }
    }

    setMatPreview(listArrName: any) {
        if (listArrName === task_field_type.client) {
            this.clientMatPreview = this.originalClientListData.filter((ele: any) => {
                if (this.selectedClient.includes(ele.id)) {
                    return ele;
                }
            });
        } else if (listArrName === task_field_type.follower) {
            this.followerMatPreview = this.originalFollowerListData.filter(
                (ele: any) => {
                    if (ele.isCheck) {
                        return ele;
                    }
                }
            );
        } else if (listArrName === task_field_type.service) {
            if (!this.data.editTaskId) {
                this.serviceMatPreview = this.originalServiceListData.filter(
                    (ele: any) => {
                        if (this.selectedService.includes(ele.id)) {
                            return ele;
                        }
                    }
                );
            } else if (this.data.editTaskId && this.taskData?.service && this.taskData?.service?.id !== 0) {
                this.serviceMatPreview = [{ id: this.taskData?.service?.id, name: this.taskData?.service?.name }];
                this.selectedService.push(this.serviceMatPreview?.id)
            }
        } else if (listArrName === task_field_type.project) {
            this.projectMatPreview = this.projectList.filter((ele: any) => {
                if (this.selectedProjectId.includes(ele.id)) {
                    return ele;
                }
            });
        }
    }

    removedIdFromMat() {
        this.subscription = this.taskService.removeIdFromPreview.subscribe(
            async (data: any) => {
                if (data.listArrName === task_field_type.client && data) {
                    this.clientListData = await this.originalClientListData.filter(
                        (ele: any) => {
                            if (data.removeId === ele.id) {
                                ele.isCheck = false;
                            }
                            return ele;
                        }
                    );
                    this.selectedClient = await this.clientListData
                        .filter((clientData: any) => {
                            if (clientData.isCheck) {
                                return clientData.id;
                            }
                        })
                        .map((ele: any) => ele.id);
                    this.onSelectClientCheckBox(this.selectedClient);
                } else if (data.listArrName === task_field_type.follower && data) {
                    this.followerListData = await this.originalFollowerListData.filter(
                        (ele: any) => {
                            if (data.removeId === ele.id) {
                                ele.isCheck = false;
                            }
                            return ele;
                        }
                    );
                    this.selectedFollower = await this.followerListData
                        .filter((followerData: any) => {
                            if (followerData.isCheck) {
                                return followerData.id;
                            }
                        })
                        .map((ele: any) => ele.id);

                    this.setMatPreview('follower');
                    this.taskService.removeAssignees(this.selectedFollower);
                } else if (data.listArrName === task_field_type.service && data) {
                    this.serviceListData = await this.originalServiceListData.filter(
                        (ele: any) => {
                            if (data.removeId === ele.id) {
                                ele.isCheck = false;
                            }
                            return ele;
                        }
                    );
                    this.selectedService = await this.serviceListData
                        .filter((serviceData: any) => {
                            if (serviceData.isCheck) {
                                return serviceData.id;
                            }
                        })
                        .map((ele: any) => ele.id);
                    this.onSelectServiceCheckBox(this.selectedService);
                } else if (data.listArrName === task_field_type.project && data) {
                    this.selectedProjectId = [];
                    this.projectSelection(this.selectedProjectId);
                    this.projectListData.forEach((ele: any) => {
                        ele.isCheck = false;
                    });
                    this.projectList.forEach((ele: any) => {
                        ele.isCheck = false;
                    });
                    this.selectedClient = [];
                    this.onSelectClientCheckBox(this.selectedClient);
                    this.clientListData.forEach((ele: any) => {
                        ele.isCheck = false;
                    });
                    this.projectListData = [...this.projectListData];
                }
            }
        );
    }

    async setStatus(status: any) {
        this.selectedStatus = status;
        this.taskDetailForm.controls['taskStatus'].setValue(this.selectedStatus);
        if (this.data?.editTaskId) {
            const formData = new FormData();
            formData.append('status', JSON.stringify(this.selectedStatus));
            formData.append(
                "changedFieldValues",
                JSON.stringify({
                  status: { old_value: this.taskData.status, new_value: this.selectedStatus },
                  changeFromTaskList: true,
                })
              );
            (
                await this.taskService.editTask(formData, this.data.editTaskId, 1)
            ).subscribe((res: any) => {
                if (res.message) {
                    this.toastrService.success(res.message);
                }
            });
        }
    }

    async setPriority(priorityObj: any) {
        this.selectedPriority = priorityObj;
        this.taskDetailForm.controls['taskPriority'].setValue(priorityObj);
    }

    async closeDialog() {
        this.timerValue !== '00:00:00' || (this.timerResponseObj && this.timerValue === '00:00:00') ? this.dialogRef.close(true) : this.dialogRef.close(false);
        this.taskService.subTaskDetails([]);
        this.taskService.selectedTaskDetails.next('');
        this._location?.back();
    }

    async onSelectCheckBox(selectedData: any) {
        if (
            selectedData.groups.length === 0 &&
            selectedData.assignees.length === 0
        ) {
            this.selectedAssignees = [];
            this.selectedGroup = [];
            this.removeFollower = [];
            this.taskDetailForm.controls['taskAssignee'].setValue([]);
            this.taskDetailForm.controls['taskAssignee'].setErrors({
                required: true,
            });
            this.taskDetailForm.controls['taskAssignee'].setValidators(
                Validators.required
            );
            this.setAssigneeMatPreview();
            this.taskDetailForm.updateValueAndValidity();
            this.taskDetailForm.markAsDirty();
            return;
        }
        if (selectedData.groups.length) {
            this.selectedGroup = selectedData.groups;
            const uniqueSelectedIdArr = this.selectedGroup.filter(
                (ele: any, index: number) => index === this.selectedGroup.indexOf(ele)
            );
            this.selectedGroup = uniqueSelectedIdArr;
            this.selectedAssignees = [];
            this.taskDetailForm.controls['taskAssignee'].clearValidators();
            this.taskDetailForm.controls['taskAssignee'].setErrors(null);
            this.taskDetailForm.controls['taskAssignee'].setValue(this.selectedGroup);
            this.listArrName = 'Team';
            this.setAssigneeMatPreview();
            this.taskDetailForm.updateValueAndValidity();
            this.taskDetailForm.markAsDirty();
            await this.selectedGroup.forEach((ele: any) => {
                this.groupList.forEach((data: any) => {
                    if (ele === data.id) {
                        data.members.forEach((id: any) => {
                            this.removeFollower.push(id);
                            this.selectedFollower = this.selectedFollower.filter(
                                (e: any) => e !== id
                            );
                        });
                    }
                });
            });
            this.followerListData.forEach((ele: any) => {
                if (this.removeFollower.includes(ele.id)) {
                    ele.isCheck = false;
                }
            });
            this.setMatPreview('follower');
            return;
        }
        if (selectedData.assignees.length) {
            this.selectedAssignees = selectedData.assignees;
            const uniqueSelectedIdArr = this.selectedAssignees.filter(
                (ele: any, index: number) =>
                    index === this.selectedAssignees.indexOf(ele)
            );
            this.selectedAssignees = uniqueSelectedIdArr;
            this.selectedGroup = [];
            this.taskDetailForm.controls['taskAssignee'].clearValidators();
            this.taskDetailForm.controls['taskAssignee'].setErrors(null);
            this.taskDetailForm.controls['taskAssignee'].setValue(
                this.selectedAssignees
            );
            this.listArrName = 'assignee';
            this.setAssigneeMatPreview();
            this.taskDetailForm.updateValueAndValidity();
            this.taskDetailForm.markAsDirty();
        }
    }

    setAssigneeMatPreview() {
        if (this.selectedAssignees.length > 0) {
            this.selectedMatPreview = this.originalFollowerListData.filter(
                (ele: any) => {
                    if (this.selectedAssignees.includes(ele.id)) {
                        return ele;
                    }
                }
            );
        } else if (this.selectedGroup.length > 0) {
            this.selectedMatPreview = this.originalGroupListData.filter(
                (ele: any) => {
                    if (this.selectedGroup.includes(ele.id)) {
                        return ele;
                    }
                }
            );
        } else {
            this.selectedMatPreview = [];
        }
    }

    async uploadFiles(fileData: any) {
        if(fileData?.length > FILE_CONFIGURATION.DEFAULT_NUMBER_OF_FILES_UPLOADS){
         this.apiService.showError(this.translate.instant('MAXIMUM_FILES_UPLOAD_LIMIT'));
         return;
        }
        const myFilesTemp: any = Array.from(fileData);

        const companyAllowFileSize:any = await this.userDetailService.getSelectedCompanyFileSize();
        let fileSize = 0;
        for (const file of myFilesTemp) {
            fileSize += file.size;
        }
        const mb = 0.000001;
        const result = fileSize * mb;
        if (result > parseInt(companyAllowFileSize)) {
            this.bigFile = true;
            this.apiService.showError(this.translate.instant('DYNAMIC_FILE_SIZE_VALIDATION') + ` ${companyAllowFileSize} ` +'MB');
            return;
        } else {
            this.myFiles = myFilesTemp;
            this.bigFile = false;
            this.updateFiles.push([...fileData]);
            this.updateFiles = this.updateFiles.flat();
        }
        if (this.data?.editTaskId && !this.bigFile) {
            this.addDocument();
        }
        
        this.scrollDownOnAddSubtask();

    }

    async addDocument() {
        let docFormData = new FormData();
        let fileArr: any = []
        fileArr = this.updateFiles.filter((ele: any) => !ele._id);
        
        if (fileArr.length > 0 || this.newFiles.length > 0) {
            let url:any = await this.fileUploadInS3Bucket(this.updateFiles);
            url = await this.utils.setThumbPathAndUpdateFilePath(url);
            docFormData.append('attachments', JSON.stringify(url));
            docFormData.append('taskId', this.data.editTaskId || this.task_id);
        }
        (
            await this.taskService.uploadDoc(
                docFormData,
                this.decryptedUserId,
                this.decryptSelectedCompanyId
            )
        ).subscribe(async (res: any) => {
            let indexWithoutId = 0;
            this.updateFiles.forEach((element, index) => {
                if (!element?._id) {

                    if (indexWithoutId in res.data) {

                        element._id = res.data[indexWithoutId];
                        indexWithoutId++;
                    }
                }
            });

            this.apiService.showSuccess(res.message);
        });
    }


    async fileUploadInS3Bucket(fileArr: any): Promise<string[]> {
        let finalURL: any[] = [];
        const fileArrURL = fileArr.map((fileData: any) => ({
            folderName: "/uploads",
            fileName: fileData.name,
            contentType: '.'+fileData.name.split('.')[1]
        }));
    
        try {
            const observableRes = await this.userDetailService.getPresignURL(fileArrURL);
            const res = await lastValueFrom(observableRes);
            await Promise.all(
                fileArr.map(async (fileData: any, index: number) => {
                    await this.userDetailService.s3MediaUpload(res?.urls[index]?.url, fileData);
                    finalURL.push(
                        {
                            data_path: res?.urls[index]?.url?.replace(environment.MEDIA_BUCKET_NAME, environment.DOMAIN).split('?')[0],
                            size: fileArr[index]?.size,
                            name: fileArr[index]?.name,
                            type: '.'+fileArr[index]?.name?.split('.')[1]
                        }
                    )
                })
            );
            return finalURL;
        } catch (error) {
            console.error("Error in file upload:", error);
            return [];
        }
    }

    removeFiles(i: any, doc: any) {
        this.docId = doc._id;
        if (this.docId) {
            this.deleteDocument();
        }
        if (doc.thumb_path) {
            this.newFiles.splice(i, 1);
        } else {
            this.updateFiles.splice(i, 1);
        }
    }

    async deleteDocument() {
        (await this.taskService.deleteDocumentFile(this.docId)).subscribe(
            (res: any) => {
                this.apiService.showSuccess(res.message);
            }
        );
    }

    async getTaskDetailById(subTaskId?: any) {
        if (subTaskId) this.data.editTaskId = subTaskId;
        (
            await this.taskService.getTaskDetailById(
                this.data.editTaskId,
                this.selectedCompanyId,
                this.moduleId
            )
        ).subscribe(async (res) => {
            if (res._source.mark_as_read) {
                this.emitTaskService.readByUserList(res._source.mark_as_read);
                this.setReadStatus = res._source.mark_as_read;
            }
            if (res._source) {
                this.allSelectedUsersList.push(res._source.owner);
                res._source?.assignees.forEach((element: any) => {
                    this.allSelectedUsersList.push(element);
                });
                res._source?.follower.forEach((element: any) => {
                    this.allSelectedUsersList.push(element);
                });
                if (res._source.groups.length > 0) {
                    const groupId: any = [];
                    res._source.groups.forEach((group: any) => {
                        groupId.push(group.id);
                    });
                    const data_post = { groupIds: groupId };
                    (await this.taskService.getUserListByGroup(data_post)).subscribe(
                        (res: any) => {
                            res.forEach((groupRel: any) => {
                                this.allSelectedUsersList.push(groupRel.user_company_relation);
                            });
                        }
                    );
                }
                this.allSelectedUsersList = this.allSelectedUsersList.filter(
                    (value: any, index: any) => {
                        const _value = JSON.stringify(value);
                        return (
                            index ===
                            this.allSelectedUsersList.findIndex((obj: any) => {
                                return JSON.stringify(obj) === _value;
                            })
                        );
                    }
                );
                this.emitTaskService.setUserList(this.allSelectedUsersList);
                this.taskDetail = res._source;
                this.selectedClient=this.taskDetail?.client?.id;
                this.isAnyTaskOngoingOfUser = this.taskDetail?.is_any_ongoing_task;
                this.disableAllFunctionality = this.taskDetail?.on_going ? false : true;
                this.openSearchTaskId = res?._id;
                this.canMarkAsApprove = res?._source?.canMarkAsApprove;
                this.updateFiles = [];
                this.attatchmentDetail = [];
                this.newFiles = [];
                this.newFiles = this.attatchmentDetail;
                this.isReminder = res._source.reminder_on;
                this.reminderOn = res._source.reminder_on;
                if (this.isReminder) {
                    await this.getReminderDetail();
                }
                this.parentId = res._source.parent_id;
                if (this.parentId) {
                    this.currentSubTaskId = res._id;
                    (await this.taskService.getNextPreviousTask(this.parentId)).subscribe(
                        (res: any) => {
                            this.subTasks = res?.data;
                        }
                    );
                } else {
                    this.subTasks = res._source.sub_task;
                }
                this.subTaskCount = res._source.subtask_count;

                this.progressValue = res._source.percentage;
                this.markAsCompleted = res._source.is_completed === 1 ? true : false;
                this.isReopen = res._source.is_completed === 1 ? true : false;
                this.discussion_id = res._source.discussion_id;
                this.discussion_id = res._source.discussion_id;
                await this.setFormData(res._source);
                this.markAsApproval =
                    this.selectedStatus?.status_name === status_name.UNDER_REVIEW
                        ? true
                        : false;
                if (this.is_task_active && this.is_active) {
                    const customData = res._source?.custom_fields_values;
                    let selectedVehicles: any;
                    selectedVehicles = _.groupBy(customData, function (item) {
                        return item.custom_field_id;
                    });
                    const mapData = _.map(selectedVehicles, function (data) {
                        return data;
                    });
                    this.customFieldOldArr = mapData.flatMap((innerArray) => innerArray);
                    this.customFieldOldArr = this.customFieldOldArr.map((obj: any) => {
                        return {
                            ...obj,
                            _id: obj.custom_field_id,
                        };
                    });
                    this.setCustomFieldData(mapData);
                }
            }
        });
        this.getAttachmentDetail();
        await this.getTimer();
        await this.getTimeLogData();
        if (
            !this.data?.isEditTask ||
            this.selectedStatus?.status_name === status_name.UNDER_REVIEW ||
            this.selectedStatus?.status_name === status_name.CLOSED ||
            this.timerResponseObj?.task_id === this.data?.editTaskId
        ) {
            this.disableAllFunctionality = false;
        }
    }

    async setCustomFieldData(fieldData: any) {
        for (const data of fieldData) {
            const fieldID = this.fields.find(
                (field: any) => field.id == data[0].custom_field_id
            );
            switch (fieldID?.field_type) {
                case 'date':
                    this.customForm
                        .get(fieldID.name)
                        .patchValue(
                            data[0]?.value
                                ? new Date(parseInt(data[0]?.value))
                                : data[0]?.value
                        );
                    break;
                case 'phone':
                    if (data[0]?.value?.length === 0) {
                        this.getFinalCode(fieldID?.country_id);
                        fieldID['country_short_code'] = this.selectedCountry[0];
                        const foundIndex = this.fields.findIndex(
                            (x: any) => x.id === data[0]?.custom_field_id
                        );
                        this.fields[foundIndex] = fieldID;
                    }
                    this.customForm.controls[fieldID?.name].setValue(data[0]?.value);
                    break;
                case 'radio':
                    this.customForm
                        .get(fieldID.name)
                        .patchValue(data[0]?.custom_field_option_id);
                    break;
                case 'checklist':
                    if (!fieldID?.is_single_select) {
                        let value = data;
                        value = value?.map((v: any) => {
                            return v.custom_field_option_id;
                        });
                        this.customForm.controls[fieldID?.name].setValue(value);
                    } else {
                        this.customForm.controls[fieldID?.name].setValue(
                            data[0]?.custom_field_option_id
                        );
                    }
                    break;
                case 'currency':
                    this.customForm.controls[fieldID?.name].setValue(data[0]?.value);
                    this.customForm.controls[fieldID.country_id].setValue(
                        data[0].custom_field_country_id
                            ? data[0].custom_field_country_id
                            : fieldID.country_id
                    );
                    break;
                default:
                    this.customForm.controls[fieldID?.name]?.setValue(data[0]?.value);
                    break;
            }
        }
    }

    async deleteTask(taskId: any, isSubTask: boolean) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                message: this.translate.instant('SOFT_DELETE_TASK_MSG'),
                buttonText: {
                    ok: 'Yes',
                    cancel: 'No',
                },
            },
        });

        dialogRef.afterClosed().subscribe(async (confirmed: boolean) => {
            if (confirmed) {
                this.apiService.showLoading();
                (
                    await this.taskService.deleteTask(
                        taskId,
                        this.userId,
                        this.selectedCompanyId,1
                    )
                ).subscribe((res) => {
                    if(res.message){
                        this.toastrService.success(res.message);
                        setTimeout(() => {
                            !isSubTask ? this.dialogRef.close(true) : '';
                            this.apiService.hideLoading();
                        }, 1550);
                    }
                });
            }
        });
    }

    async onChangeSetAssignee(event: any) {
        this.toggleValue = event?.checked;
        this.taskService.mainAssigneeGroupId({
            toggleValue: this.toggleValue,
            group: this.selectedGroup,
            assignees: this.selectedAssignees,
        });
    }

    subtaskDetails: any = [];
    async setFormData(taskData: any) {
        this.taskData = taskData;
        this.isUpdateTaskid.emit(this.taskData);
        this.primaryTask = taskData.primary_task;
        this.estimatedHours = taskData.time_sheet_estimated_hr;
        this.taskDetailForm.controls['taskName'].setValue(taskData.task_name);
        this.taskService.taskName = taskData.task_name;
        this.taskDetailForm.controls['taskDesc'].setValue(taskData.description);
        this.selectedStatus = this.allStatusList?.filter(
            (data: any) => data.id === taskData.status.id
        )[0];
        if(!this.allStatusList?.length){
            this.selectedStatus = taskData.status
        }
        this.taskDetailForm.controls['taskStatus'].setValue(this.selectedStatus);
        this.taskDetailForm.controls['taskPriority'].setValue(taskData.priority);
        this.taskDetailForm.controls['taskDueDate'].setValue(taskData.due_date);
        this.selectedPriority = taskData.priority;
        this.projectListData = this.projectList?.filter((ele: any) => {
            if (taskData?.project?._id === ele.id) {
                this.selectedProjectId.push(ele.id);
                ele.isCheck = true;
            }
            return ele;
        });

        if (taskData.assignees.length > 0) {
            this.assigneeFollowerCount = taskData.assignees.length;
            const assignee = await taskData?.assignees?.map((v: any) => {
                return v.id;
            });
            this.taskDetail.assignee = assignee;
            this.taskDetailForm.controls['taskAssignee'].setValue(assignee);
            this.selectedAssigneesFromBE = {
                assignees: assignee,
                groups: [],
                mainTask: true,
            };
        } else {
            const groups = await taskData?.groups?.map((v: any) => {
                return v.id;
            });
            this.taskDetailForm.controls['taskAssignee'].setValue(groups);
            this.taskDetail.assign_groups = groups;
            this.selectedAssigneesFromBE = {
                assignees: [],
                groups: groups,
                mainTask: true,
            };
            let allMember: any = [];
            await groups.forEach((element: any) => {
                this.groupList.forEach((ele: any) => {
                    if (ele.id === element) {
                        allMember.push(...ele.members);
                    }
                });
            });
            allMember = await allMember
                .filter(
                    (value: any, index: any, self: any) => self.indexOf(value) === index
                );
            this.assigneeFollowerCount = allMember.length;
        }

        if (this.selectedProjectId?.length > 0) {
            const foundObject = this.projectListData.find(
                (obj: any) => obj.id === this.selectedProjectId[0]
            );
            if (foundObject) {
                this.maxDateProject = foundObject.end_date
                    ? new Date(foundObject.end_date)
                    : null;
                this.minDateProject = foundObject.start_date
                    ? new Date(foundObject.start_date)
                    : new Date();
            }
            await this.projectSelection(this.selectedProjectId);
        }

        this.dueDateDataObj.startDate =
            this.isTimeSheetSetting && taskData?.start_date
                ? (this.dueDateDataObj.startDate = taskData?.start_date)
                : new Date(new Date().setHours(0, 0, 0, 0)).getTime();
        this.dueDateDataObj.endDate = taskData?.due_date;
        this.dueDateDataObj = {
            startDate:
                this.isTimeSheetSetting && taskData?.start_date
                    ? (this.dueDateDataObj.startDate = taskData?.start_date)
                    : new Date(new Date().setHours(0, 0, 0, 0)).getTime(),
            endDate: taskData?.due_date,
            startTime: moment(this.dueDateDataObj?.startDate).format('HH:mm')
                ? moment(this.dueDateDataObj?.startDate).format('HH:mm')
                : '00:00',
            endTime: moment(taskData?.due_date).format('HH:mm')
                ? moment(taskData?.due_date).format('HH:mm')
                : '00:00',
            time_sheet_estimated_hr: taskData?.time_sheet_estimated_hr,
            isUpdate: true,
        };
        this.dueDateDataObj.taskDueDate = !this.isTimeSheetSetting
            ? (this.dueDateDataObj.taskDueDate = new Date(taskData?.due_date))
            : '';
        this.dueDateDataObj.isTaskRecurring = this.utils.getBooleanValueWithFalse(
            this.taskData?.is_recurring_task
        );
        if (this.dueDateDataObj.isTaskRecurring) {
            this.dueDateDataObj.frequency =
                this.taskData?.recurringTask?.recurring_frequency;
            switch (this.dueDateDataObj?.frequency) {
                case 'weekly':
                    this.dueDateDataObj.weekDays =
                        this.taskData?.recurringTask?.recurring_value;
                    break;
                case 'monthly':
                    this.dueDateDataObj.onSelectedDay =
                        this.taskData?.recurringTask?.recurring_value[0];
                    break;
                case 'quarterly':
                case 'yearly':
                case 'halfyearly':
                    this.dueDateDataObj.recurringDate =
                        this.taskData?.recurringTask?.recurring_value[0];
                    const recurDate = new Date(
                        parseInt(this.dueDateDataObj?.recurringDate)
                    );
                    this.dueDateDataObj?.recurringDate
                        ? moment(recurDate).format('DD-MM-YYYY')
                        : '';
                    break;
                case 'custom':
                    this.dueDateDataObj.customOnDay =
                        this.taskData?.recurringTask?.recurring_value[0];
                    break;
            }
        }
        const follower = await taskData?.follower?.map((v: any) => {
            return v.id;
        });
        follower.forEach(async (ele: any) => {
            this.followerListData = this.originalFollowerListData.filter(
                (data: any) => {
                    if (ele === data.id) {
                        this.selectedFollower.push(data.id);
                        return (data.isCheck = true);
                    }
                }
            );
        });
        const followerIdList: any[] = [];
        if (taskData.follower) {
            taskData.follower.map((data: any) => followerIdList.push(data.id));
            this.taskService.removeAssignees(followerIdList);
        }

        this.selectedFollower.includes(this.userRolePermission.memberId)
            ? !this.userRolePermission?.task?.can_edit
            : '';
        this.assigneeFollowerCount =
            this.assigneeFollowerCount + this.selectedFollower.length;
        this.clientListData = this.originalClientListData?.filter((ele: any) => {
            if (taskData?.client?.id === ele.id) {
                this.selectedClient.push(ele.id);
                ele.isCheck = true;
            }
            return ele;
        });

        this.setMatPreview('client');
        this.setMatPreview('service');
        this.setMatPreview('follower');
        this.setMatPreview('project');
        this.taskCreatorInfo = taskData?.owner;
        this.createSubtask?.resetSubTaskList();
        if (taskData?.sub_task?.length) {
            this.taskService.subTaskDetails({
                isCheckListSubTask: false,
                data: [...taskData?.sub_task],
            });
        }
        this.resetCheckList();
        if (taskData?.check_lists?.length > 0) {
            let count = 0;
            await taskData?.check_lists.forEach(async (element: any) => {
                if (element?.status) {
                    count++;
                }
                await this.addCheckListRow(element);
            });
        }
        this.markAsMandatory = this.utils.getBooleanValueWithFalse(
            taskData?.check_list_mandatory
        )
            ? true
            : false;
        this.markAsSubtaskMandatory = this.utils.getBooleanValueWithFalse(
            taskData?.subtask_mandatory
        )
            ? true
            : false;
        this.taskService.getTotalCountMembers(this.assigneeFollowerCount);
        if(taskData?.assignees?.length > 0){
            await this.companyEmployees?.forEach((element: any) => {
                if ([...followerIdList,...this.selectedAssigneesFromBE.assignees].includes(element.id) || (taskData?.owner?.user_id ===element.user_id)) {
                    this.assigneeUser.push({
                        user_id: element.user_id,
                        id: element?.user_id,
                        avatar: this.utils.forShortName(
                            element?.first_name + ' ' + element?.last_name
                        ),
                        first_name: element?.first_name,
                        last_name: element?.last_name,
                        profile_image: element.profile_image || '#19B1BB',
                    });
                }
            });
        }    
        taskData['user_ids'] = this.assigneeUser;
        const groups: any = taskData.groups.map((ele: any) => ele?.id);
        const groupInfo: any = this.groupsInfo.map((ele: any) => {
            return { ...ele, group_id: ele.id, group_name: ele.group_name };
        });
        let followerAndTaskCreator: any = [];
        if (groups.length) {
            this.assigneeGroup = groupInfo.filter((ele: any) =>
                groups.includes(ele.group_id)
            );
            const groupMember: any = await taskData?.members?.map((ele: any) => {
                return {
                    ...ele,
                    avatar: this.utils.forShortName(
                        ele?.first_name + ' ' + ele?.last_name
                    ),
                };
            });
            await this.companyEmployees?.forEach((element:any)=>{
                if ([...followerIdList].includes(element.id) || (taskData?.owner?.user_id ===element.user_id)) {
                    followerAndTaskCreator.push({
                        user_id: element.user_id,
                        id: element?.user_id,
                        avatar: this.utils.forShortName(
                            element?.first_name + ' ' + element?.last_name
                        ),
                        first_name: element?.first_name,
                        last_name: element?.last_name,
                        profile_image: element.profile_image || '#19B1BB',
                    })
                }
                
            })

            taskData['group_ids'] = [...this.assigneeGroup, ...groupMember, ...followerAndTaskCreator ];
            this.assigneeGroup = [...this.assigneeGroup, ...groupMember, ...followerAndTaskCreator];
        }

        this.taskService.selectedTaskDetails.next(taskData);
    }

    getSubTaskData(subTaskData: any) {
        this.newSubTaskArr = [];
        if (subTaskData.length > 0) {
            this.numSubtask = subTaskData.length;
            this.taskDetailForm.controls['subTask'].clearValidators();
            this.taskDetailForm.controls['subTask'].setValue(null);
            this.taskDetailForm.controls['subTask'].setErrors(null);
            this.taskDetailForm.updateValueAndValidity();
            this.newSubTaskArr = [];
            subTaskData.forEach((data: any) => {
                const taskDetailObj: any = {
                    task_name: data.subTaskName,
                    due_date:
                        typeof data?.subTaskDueDate === 'object'
                            ? data?.subTaskDueDate.getTime()
                            : data?.subTaskDueDate,
                    status: data.subTaskStatus,
                    priority: data.subTaskPriority,
                    start_date:
                        typeof data?.subTaskStartDate === 'object'
                            ? data?.subTaskStartDate.getTime()
                            : data?.subTaskStartDate,
                };
                if (data.subTaskAssignee?.length > 0) {
                    taskDetailObj['assignees'] = data.subTaskAssignee.map(
                        (assigneeId: any) => {
                            const ele = this.company_all_members_with_deleted_members?.find(
                                (detail: any) => detail.id == assigneeId
                            );
                            return {
                                id: ele.id,
                                first_name: ele.first_name,
                                last_name: ele.last_name,
                                profile_image: ele.profile_image,
                            };
                        }
                    );
                    taskDetailObj['groups'] = [];
                }
                if (data.subTaskGroup?.length > 0) {
                    taskDetailObj['groups'] = data.subTaskGroup.map((groupId: any) => {
                        const ele = this.originalGroupListData.find(
                            (detail: any) => detail.id == groupId
                        );
                        return {
                            name: ele.name,
                            id: ele.id,
                            profile_image: ele.profile_image,
                        };
                    });
                    taskDetailObj['assignees'] = [];
                }
                if (data._id) {
                    taskDetailObj['_id'] = data._id;
                }
                if (!data._id) {
                    taskDetailObj['owner'] = this.loginUserData;
                }
                this.newSubTaskArr.push(taskDetailObj);
            });

            return;
        }

        if (!subTaskData?.validity) {
            this.taskDetailForm.controls['subTask'].setValidators(
                Validators.required
            );
            this.taskDetailForm.controls['subTask'].setErrors({ required: true });
            this.taskDetailForm.updateValueAndValidity();
            return;
        }

        if (subTaskData?.validity) {
            this.newSubTaskArr = [];
            this.numSubtask = 0;
            this.taskDetailForm.controls['subTask'].clearValidators();
            this.taskDetailForm.controls['subTask'].setErrors(null);
            this.taskDetailForm.updateValueAndValidity();
            return;
        }
    }
    async createTask(emailId?: any) {
        this.submitBtnDisable = true;
        const formData = new FormData();
        const customFieldData: any = {};
        if (this.is_task_active && this.is_active) {
            if (!this.customForm?.valid) {
                this.toastrService.error(
                    this.translate.instant('ALL_CUSTOM_FIELD_REQUIRED')
                );
                return;
            }
            await this.manageCustomFormData(this.customForm?.value);
            customFieldData['moduleType'] = MODULE_NAME.TASK;
            customFieldData['customFieldValues'] = this.tArray;
            formData.append('custom_fields_values', JSON.stringify(this.tArray));
        }
        const subTaskArr: any = [];

        const taskName = this.taskDetailForm.controls['taskName'].value;
        const taskDesc = this.taskDetailForm.controls['taskDesc'].value;
        const taskStartDate = this.dueDateDataObj?.startDate?.getTime();
        const statusId = JSON.stringify(
            this.taskDetailForm.controls['taskStatus'].value
        );
        const priority = this.taskDetailForm.controls['taskPriority'].value;
        delete priority['isCheck'];
        if (this.selectedAssignees.length > 0) {
            await this.companyEmployees.forEach((element: any) => {
                if ([...this.selectedFollower, ...this.selectedAssignees].includes(element.id)) {
                    this.assigneeUser.push(element.user_id);
                }
            });
            const assignees = Object.assign([], this.selectedMatPreview);
            assignees.forEach((element: any) => {
                element.profile_image = element.profile_image || '#DC3535';
                delete element.email;
                delete element.name;
                delete element.isCheck;
            });
            formData.append('assignees', JSON.stringify(assignees));
        } else {
            const groups = Object.assign([], this.selectedMatPreview);
            groups.forEach((element: any) => {
                delete element.members;
            });
            formData.append('groups', JSON.stringify(groups));
        }
        formData.append('task_name', taskName);
        formData.append('is_sync', 'true');
        formData.append('is_email', this.emailTask ? 1 : (0 as any));
        if(this.emailTask){
            formData.append('email_attechment', JSON.stringify(this.updateFiles));
        }
        taskDesc ? formData.append('description', taskDesc) : '';

        this.isTimeSheetSetting
            ? formData.append('start_date', this.dueDateDataObj?.startDate?.getTime())
            : formData.append(
                'start_date',
                this.dueDateDataObj?.taskstartDate?.getTime()
            );

        formData.append(
            'due_date',
            this.taskDetailForm.controls['taskDueDate'].value
        );
        formData.append('status', statusId);
        formData.append('priority', JSON.stringify(priority));
        formData.append('subtask', JSON.stringify(this.newSubTaskArr));
        formData.append('subtask_count', this.numSubtask ? this.numSubtask : 0);
        if (this.updateFiles?.length > 0) {
            let url:any[] = await this.fileUploadInS3Bucket(this.updateFiles);
            url = await this.utils.setThumbPathAndUpdateFilePath(url);
            formData.append('file', JSON.stringify(url));
        }
        formData.append('percentage', this.progressValue);
        if (this.selectedClient.length) {
            const client = Object.assign({}, this.clientMatPreview[0]);
            delete client.isCheck;
            formData.append('client', JSON.stringify(client));
        }
        if (this.selectedService.length) {
            const service = Object.assign({}, this.serviceMatPreview[0]);
            delete service.isCheck;
            formData.append('service', JSON.stringify(service));
        }
        if (this.selectedFollower.length) {
            const follower = Object.assign([], this.followerMatPreview);
            follower.forEach((element: any) => {
                delete element.email;
                delete element.name;
                delete element.isCheck;
            });
            formData.append('follower', JSON.stringify(follower));
        }
        let checkList = [];
        checkList = await this.appendChecklistData();
        if (checkList?.length > 0) {
            const mandatory_template = this.markAsMandatory ? true : false;
            formData.append(
                'check_list_mandatory',
                JSON.stringify(mandatory_template)
            );
            formData.append('check_lists', JSON.stringify(checkList));
        }
        formData.append(
            'subtask_mandatory',
            JSON.stringify(this.markAsSubtaskMandatory)
        );
        formData.append('owner', JSON.stringify(this.loginUserData));
        formData.append('company', JSON.stringify(this.companyData));
        let selectedProject: any;
        this.selectedProjectId.length
            ? (selectedProject = this.projectListData.find(
                (ele: any) => ele.id === this.selectedProjectId[0]
            ))
            : '';
        this.selectedProjectId.length
            ? formData.append(
                'project',
                JSON.stringify({
                    _id: selectedProject.id,
                    name: selectedProject.name,
                })
            )
            : '';
        formData.append('is_recurring_task', this.dueDateDataObj?.isTaskRecurring);
        const recurringData = await this.makeDataForRecurringTask();
        if (this.dueDateDataObj?.time_sheet_estimated_hr) {
            formData.append(
                'time_sheet_estimated_hr',
                this.dueDateDataObj?.time_sheet_estimated_hr
            );
        }
        this.dueDateDataObj?.isTaskRecurring
            ? formData.append('recurring_data', JSON.stringify(recurringData))
            : '';
        this.dueDateDataObj?.isTaskRecurring
            ? formData.append(
                'recurring_icon',
                await this.taskListData.recurringTaskShortName(
                    recurringData.recurring_frequency
                )
            )
            : '';
        this.attachment_count = this.updateFiles.length + this.newFiles.length;
        formData.append('attachment_count', this.attachment_count);

        (
            await this.taskService.createTask(
                formData,
                this.userId,
                this.selectedCompanyId
            )
        ).subscribe(async (res) => {
            this.task_id = res[0]?.taskId;
            this.reminderObj['task_id'] = res[0]?.taskId;
            this.submitBtnDisable = false;
            if (res[0].message) {
                this.toastrService.success(res[0].message);
                if (!this.data?.editTaskId) {
                    this.reminder();
                }
                if (this.emailTask) this.convertEmailToTask(emailId);
                this.taskService.subTaskDetails([]);
                const offlineCommentSub:Subscription =
                    this.discussionService.offlineTaskCommentArr.subscribe(
                        async (data: any) => {
                            if (data?.message?.length) {
                                const arr: any = await data.message.map((item: any) => {
                                    return { ...item, id: res[0].taskId };
                                });
                                for await (const ele of arr) {
                                    const fileData: any[] = [];
                                    if (ele?.message_type === 'document') {
                                        await Promise.all(
                                            ele.uploadFile.map(async (file: any) => {
                                                const uploaded: any = await this.sendFiles(
                                                    file,
                                                    res[0].taskId
                                                );
                                                uploaded['image_type'] = IMAGE_UPLOAD_TYPE.WEB
                                                fileData.push(uploaded);
                                            })
                                        );
                                        delete ele.uploadFile;
                                        ele['uploadFile'] = [...fileData];
                                    }
                                    if (ele?.message_type === 'audio') {
                                        await Promise.all(
                                            ele.audioFiles.map(async (file: any) => {
                                                const uploaded: any = await this.sendFiles(
                                                    file.files,
                                                    res[0].taskId
                                                );
                                                fileData.push(uploaded);
                                            })
                                        );
                                        delete ele.uploadFile;
                                        ele['uploadFile'] = [...fileData];
                                    }
                                } 
                                await setTimeout(async () => {
                                    await this.taskService.offlineCommentSave(
                                        [...arr]
                                            .sort((a, b) => a.comment_id - b.comment_id)
                                            .map((item) => {
                                                return item;
                                            })
                                    );

                                    offlineCommentSub.unsubscribe();
                                    this.discussionService.offlineTaskCommentArr.next([]);
                                }, 300);
                            }
                        }
                    );
                offlineCommentSub.unsubscribe();
                localforage.removeItem('tasklocalcomment');
                this.notificationService.setNotification(true);
            }
            this.taskService.selectedTaskDetails.next('');
        });
    }

    async createMultipleTask() {
        this.submitBtnDisable = true;
        if (this.is_task_active && this.is_active) {
            if (!this.customForm?.valid) {
                this.toastrService.error(
                    this.translate.instant('ALL_CUSTOM_FIELD_REQUIRED')
                );
                return;
            }
        }
        this.apiService.showLoading();
        if (this.emailTask) {
            if (this.taskUsers?.length > 1) {
                for (const element of this.taskUsers) {
                    this.taskDetailForm.controls['taskName'].setValue(element.taskName);
                    this.taskDetailForm.controls['taskDesc'].setValue(element.taskDesc);
                    await this.createTask(element.id);
                }
            } else {
                await this.createTask(this.taskUsers[0].id);
            }
        } else {
            await this.createTask();
        }

        setTimeout(() => {
            this.dialogRef.close(true);
            this.apiService.hideLoading();
        }, 1000);
    }

    async sendFiles(file: any, taskId: any) {
        const formData = new FormData();
        if (taskId) {
            formData.append('task_id', taskId);
        }
        if (this.selectedProjectId.length) {
            formData.append('project_id', this.selectedProjectId[0]);
        }
        formData.append('type', 'TASK');

        if (taskId) {
            formData.append('id', taskId);
        }
        if (this.taskDetailForm.controls['taskName'].value) {
            formData.append(
                'folder_name',
                this.taskDetailForm.controls['taskName'].value
            );
        }

        this.assigneeUser.length
            ? formData.append('user_ids', JSON.stringify(this.assigneeUser))
            : formData.append('group_ids', JSON.stringify(this.selectedGroup));


        let url:any = await this.fileUploadInS3Bucket([file]);
        url = await this.utils.setThumbPathAndUpdateFilePath(url);
        formData.append('attachments', JSON.stringify(url[0]));

        const { data }: any = await this.dmsService.uploadFiles(formData);

        return {
            _id: data?._id,
            thumb_path: data?.thumb_path,
            file_path: data?.file_path,
            type: data?.type,
            size: data?.size,
            name: data?.name,
        };
    }

    async updateTask() {
        this.submitBtnDisable = true;
        this.updateStatusChange = false;
        const formData = new FormData();
        const customFieldData: any = {};
        if (
            (this.userRolePermission?.task?.can_edit ||
                this.userRolePermission?.owner ||
                this.userRolePermission?.superAdmin) &&
            this.data?.editTaskId
        ) {
            if (this.is_task_active && this.is_active) {
                if (!this.customForm?.valid) {
                    this.toastrService.error(
                        this.translate.instant('ALL_CUSTOM_FIELD_REQUIRED')
                    );
                    return;
                }
                await this.manageCustomFormData(this.customForm.value);
                customFieldData['moduleType'] = MODULE_NAME.TASK;
                customFieldData['customFieldValues'] = this.tArray;
                this.customFieldNewArr = this.tArray.flatMap(
                    (innerArray: any) => innerArray
                );
                this.customFieldNewArr = this.customFieldNewArr.map((obj: any) => {
                    return {
                        ...obj,
                        _id: obj.custom_field_id,
                    };
                });
                formData.append('custom_fields_values', JSON.stringify(this.tArray));
            }
        }
        formData.append(
            'task_name',
            this.taskDetailForm.controls['taskName'].value
        );
        formData.append('is_sync', 'true');
        if (this.taskDetailForm.controls['taskDesc'].value) {
            formData.append(
                'description',
                this.taskDetailForm.controls['taskDesc'].value
            );
        }
        formData.append(
            'due_date',
            this.taskDetailForm.controls['taskDueDate'].value
        );
        const selectedStatus = Object.assign({}, this.selectedStatus);
        delete selectedStatus?.hide_filter;
        selectedStatus?.is_active
            ? delete selectedStatus?.is_active
            : selectedStatus?.is_active;
        delete selectedStatus?.company_id;
        delete this.selectedStatus?.is_default;
        const priority = this.taskDetailForm.controls['taskPriority'].value;
        formData.append('priority', JSON.stringify(priority));

        this.isTimeSheetSetting
            ? formData.append(
                'start_date',
                typeof this.dueDateDataObj?.startDate === 'object'
                    ? this.dueDateDataObj?.startDate?.getTime()
                    : this.dueDateDataObj?.startDate
            )
            : formData.append(
                'start_date',
                typeof this.dueDateDataObj?.startDate === 'object'
                    ? this.dueDateDataObj?.startDate?.getTime()
                    : this.dueDateDataObj?.startDate
            );
        if (this.selectedAssignees.length > 0) {
            const assignees = Object.assign([], this.selectedMatPreview);
            assignees.forEach((element: any) => {
                delete element.email;
                delete element.name;
                delete element.isCheck;
            });
            formData.append('assignees', JSON.stringify(assignees));
        } else {
            const groups = Object.assign([], this.selectedMatPreview);
            groups.forEach((element: any) => {
                delete element.members;
            });
            formData.append('groups', JSON.stringify(groups));
        }
        formData.append('owner', JSON.stringify(this.taskData?.owner));
        formData.append('company', JSON.stringify(this.companyData));
        formData.append('subtask', JSON.stringify(this.newSubTaskArr));
        formData.append(
            'subtask_mandatory',
            JSON.stringify(this.markAsSubtaskMandatory)
        );
        let selectedProject: any;
        this.selectedProjectId.length
            ? (selectedProject = this.projectListData.find(
                (ele: any) => ele.id === this.selectedProjectId[0]
            ))
            : '';
        this.selectedProjectId.length
            ? formData.append(
                'project',
                JSON.stringify({
                    _id: selectedProject.id,
                    name: selectedProject.name,
                })
            )
            : '';
        this.primaryTask
            ? formData.append(
                'subtask_count',
                (this.numSubtask || this.numSubtask == 0) ? this.numSubtask : this.subTasks?.length
            )
            : '';
        formData.append('reminder_on', this.reminderValue.toString());
        if (this.selectedClient?.length) {
            const client = Object.assign({}, this.clientMatPreview[0]);
            delete client.isCheck;
            formData.append('client', JSON.stringify(client));
        }
        formData.append('service', JSON.stringify(this.taskData?.service || {}));

        const follower = Object.assign([], this.followerMatPreview);
        follower.forEach((element: any) => {
            delete element.email;
            delete element.name;
            delete element.isCheck;
        });
        formData.append('follower', JSON.stringify(follower));
        this.checkListData = [];
        this.checkListData = await this.appendChecklistData();
        if (this.checkListData?.length > 0) {
            formData.append(
                'check_list_mandatory',
                JSON.stringify(this.markAsMandatory ? true : false)
            );
            formData.append('check_lists', JSON.stringify(this.checkListData));
        }
        formData.append(
            'changedFieldValues',
            JSON.stringify(await this.detectChanges())
        );
        !this.taskData?.primary_task && this.taskData?.parent_id
            ? formData.append('parent_id', this.taskData?.parent_id)
            : '';

            if (this.dueDateDataObj?.time_sheet_estimated_hr) {
                formData.append(
                    'time_sheet_estimated_hr',
                    this.dueDateDataObj?.time_sheet_estimated_hr
                    );
                }
                
        if(!this.taskData.is_recurring_task){
            formData.append('is_recurring_task', this.dueDateDataObj?.isTaskRecurring);
            const recurringData = await this.makeDataForRecurringTask();

            this.dueDateDataObj?.isTaskRecurring
                ? formData.append('recurring_data', JSON.stringify(recurringData))
                : '';
            this.dueDateDataObj?.isTaskRecurring
                ? formData.append(
                    'recurring_icon',
                    await this.taskListData.recurringTaskShortName(
                        recurringData.recurring_frequency
                    )
                )
                : '';
        }
        this.attachment_count = this.updateFiles.length + this.newFiles.length;
        formData.append('attachment_count', this.attachment_count);
        let status:number
        if(this.taskData?.owner?.user_id==this.decryptedUserId || this.userRolePermission?.task?.can_edit){
            status=1
        }
        (await this.taskService.editTask(formData, this.data.editTaskId,status)).subscribe(
            (res) => {
                if (res.message) {
                    this.toastrService.success(res.message);
                    this.taskService.subTaskDetails([]);
                    this.taskService.selectedTaskDetails.next('');
                    this.discussionSocketService.emit(discussionSocketEvents.LEAVE_ROOM, {
                        id: this.taskData.task_id,
                    });
                    this.notificationService.setNotification(true);
                }
                this.submitBtnDisable = false;
            }
        );
        this.dialogRef.close(true);
    }

    async detectChanges() {
        const client = Object.assign({}, this.clientMatPreview[0]);
        const service = Object.assign({}, this.serviceMatPreview[0]);
        delete client.isCheck;
        delete service.isCheck;
        let oldSubtask: any = [];

        if (
            this.taskData?.task_name !==
            this.taskDetailForm.controls['taskName'].value
        ) {
            this.changeValueObj['task_name'] = this.addChangePropertyValue(
                this.taskData?.task_name,
                this.taskDetailForm.controls['taskName'].value
            );
        }
        if (
            this.taskData?.description !==
            this.taskDetailForm.controls['taskDesc'].value
        ) {
            this.changeValueObj['description'] = this.addChangePropertyValue(
                this.taskData?.description,
                this.taskDetailForm.controls['taskDesc'].value
            );
        }

        if (
            !isEqual(
                this.taskDetailForm.controls['taskPriority'].value,
                this.taskData.priority
            )
        ) {
            this.changeValueObj['priority'] = this.addChangePropertyValue(
                this.taskData.priority,
                this.taskDetailForm.controls['taskPriority'].value
            );
        }

        if (
            !isEqual(
                this.selectedAssignees,
                this.taskData?.assignees.map((ele: any) => ele.id)
            )
        ) {
            isEqual(
                this.selectedAssignees,
                this.taskData?.assignees.map((ele: any) => ele.id)
            )
                ? (this.changeValueObj['isAssigneesChange'] = false)
                : (this.changeValueObj['isAssigneesChange'] = true);
            const newAssigneesIds = this.selectedAssignees;
            const oldAssignees = this.taskData?.assignees.map((ele: any) => ele.id);
            this.changeValueObj['assignees'] = {
                assigneeAdded: this.arrayDifference(newAssigneesIds, oldAssignees),
                assigneeRemove: this.arrayDifference(oldAssignees, newAssigneesIds),
            };
        }
        if (
            !isEqual(
                this.selectedGroup,
                this.taskData.groups.map((ele: any) => ele.id)
            )
        ) {
            isEqual(
                this.selectedGroup,
                this.taskData.groups.map((ele: any) => ele.id)
            )
                ? (this.changeValueObj['isGroupChange'] = false)
                : (this.changeValueObj['isGroupChange'] = true);
            const newGroupIds = this.selectedGroup;
            const oldGroups = this.taskData.groups.map((ele: any) => ele.id);
            this.changeValueObj['groups'] = {
                groupsAdded: this.arrayDifference(newGroupIds, oldGroups),
                groupsRemove: this.arrayDifference(oldGroups, newGroupIds),
            };
        }

        const follower = Object.assign([], this.followerMatPreview);
        await follower.forEach((element: any) => {
            delete element.email;
            delete element.name;
            delete element.isCheck;
        });

        if (!isEqual(follower, this.taskData?.follower)) {
            isEqual(follower, this.taskData.follower)
                ? (this.changeValueObj['isFollowerChange'] = false)
                : (this.changeValueObj['isFollowerChange'] = true);
            const newFollowerIds = follower.map((ele: any) => ele.id);
            const oldFollower = this.taskData.follower.map((ele: any) => ele.id);
            this.changeValueObj['follower'] = {
                followerAdded: this.arrayDifference(newFollowerIds, oldFollower),
                followerRemove: this.arrayDifference(oldFollower, newFollowerIds),
            };
        }

        if (!isEqual(client, this.taskData?.client) && this.taskData?.client) {
            this.changeValueObj['client'] = this.addChangePropertyValue(
                this.taskData.client,
                client
            );
        }
        this.taskData['service'] =
            this.taskData?.service != undefined ? this.taskData?.service : {};
        if (
            !isEqual(service, this.taskData?.service) &&
            this.taskData?.service?.id != 0 &&
            service
        ) {
            this.changeValueObj['service'] = this.addChangePropertyValue(
                this.taskData?.service,
                service
            );
        }

        if (
            !isEqual(
                this.taskDetailForm.controls['taskDueDate'].value,
                this.taskData.due_date
            )
        ) {
            this.changeValueObj['due_date'] = this.addChangePropertyValue(
                moment(
                    new Date(this.taskData.due_date).setHours(23, 59, 59, 59)
                ).valueOf(),
                moment(
                    new Date(this.taskDetailForm.controls['taskDueDate'].value).setHours(
                        23,
                        59,
                        59,
                        59
                    )
                ).valueOf()
            );
        }

        if (
            !isEqual(this.dueDateDataObj?.startDate, this.taskData.start_date) &&
            this.isTimeSheetSetting
        ) {
            this.changeValueObj['start_date'] = this.addChangePropertyValue(
                moment(
                    new Date(this.taskData.start_date).setHours(23, 59, 59, 59)
                ).valueOf(),
                moment(
                    new Date(this.dueDateDataObj?.startDate).setHours(23, 59, 59, 59)
                ).valueOf()
            );
        }


        if (this.taskData?.sub_task) {
            oldSubtask = await this.taskData?.sub_task.map((data: any) => {
                return {
                    _id: data._source?.task_id,
                    task_name: data?._source?.task_name,
                    due_date: data._source?.due_date,
                    start_date: data._source?.start_date ? data._source?.start_date : '',
                    priority: data._source?.priority,
                    status: data._source?.status,
                    assignees: data._source?.assignees,
                    groups: data._source?.groups,
                };
            });
        }
        const oldSubtasksId: any = oldSubtask.map((ele: any) => ele._id);
        const newSubTasks: any = this.newSubTaskArr.filter(
            (data: any) => !oldSubtasksId.includes(data._id)
        );
        if (newSubTasks.length) {
            this.changeValueObj['newSubTasks'] = newSubTasks;
        }

        if (!isEqual(oldSubtask, this.newSubTaskArr)) {
            const changesArr: any = this.compareObjectsPropertyInArrays(
                oldSubtask,
                this.newSubTaskArr,
                'subtask'
            );
            this.changeValueObj['oldSubtaskChanges'] = changesArr;
        }

        if (!isEqual(this.checkListData, this.taskData?.check_lists)) {
            const oldCheckListId: any = this.taskData?.check_lists?.map(
                (ele: any) => ele?._id
            );
            const newCheckList: any = this.checkListData?.filter(
                (data: any) => !oldCheckListId.includes(data._id)
            );
            if (newCheckList?.length) {
                this.changeValueObj['newCheckList'] = newCheckList;
            }
            const changesCheckListArr: any = this.compareObjectsPropertyInArrays(
                this.taskData?.check_lists,
                this.checkListData,
                'checklist'
            );
            changesCheckListArr.length
                ? (this.changeValueObj['oldCheckListChanges'] = changesCheckListArr)
                : '';
        }

        if (this.checklistTitle.length) {
            this.changeValueObj['checklistTitle'] = this.checklistTitle;
        }
        if (!isEqual(this.customFieldOldArr, this.customFieldNewArr)) {
            const changesArr: any = this.compareObjectsPropertyInArrays(
                this.customFieldOldArr,
                this.customFieldNewArr,
                'customfield'
            );
            this.changeValueObj['oldCustomFieldChanges'] = changesArr;
            const oldCustomfieldId: any = this.customFieldOldArr.map(
                (item: any) => item.custom_field_id
            );
            const newCustomfield: any = this.customFieldNewArr?.filter(
                (data: any) => !oldCustomfieldId.includes(data.custom_field_id)
            );
            newCustomfield.length
                ? (this.changeValueObj['newCustomFieldChanges'] = newCustomfield)
                : '';
        }
        if (
            !isEqual(
                this.dueDateDataObj?.time_sheet_estimated_hr,
                this.taskData?.time_sheet_estimated_hr
            )
        ) {
            this.changeValueObj['time_sheet_estimated_hr'] =
                this.addChangePropertyValue(
                    this.taskData?.time_sheet_estimated_hr,
                    this.dueDateDataObj?.time_sheet_estimated_hr
                );
        }
        return this.changeValueObj;
    }

    addChangePropertyValue(old_value: any, new_value: any) {
        return { old_value: old_value, new_value: new_value };
    }

    compareObjectsPropertyInArrays(oldArr: any, newArr: any, type: string) {
        const changes = [];
        for (let i = 0; i < oldArr.length; i++) {
            const oldObj = oldArr[i];
            const newObj = newArr.find((item: any) => item._id === oldObj._id);

            if (newObj) {
                const objChanges: any = {
                    _id: oldObj._id,
                    changes: {},
                };
                type === 'subtask' ? (objChanges['task_name'] = oldObj.task_name) : '';
                type === 'checklist' ? (objChanges['label_name'] = oldObj.label) : '';

                for (const key in newObj) {
                    if (newObj.hasOwnProperty(key)) {
                        if (!isEqual(oldObj[key], newObj[key])) {
                            objChanges.changes[key] = {
                                old_value: oldObj[key],
                                new_value: newObj[key],
                            };
                        }
                    }
                }

                for (const key in oldObj) {
                    if (oldObj.hasOwnProperty(key) && !newObj.hasOwnProperty(key)) {
                        objChanges.changes[key] = {
                            old_value: oldObj[key],
                            new_value: null,
                        };
                    }
                }
                changes.push(objChanges);
            }
        }
        return changes;
    }

    arrayDifference(array1: any, array2: any) {
        return difference(array1, array2);
    }
    arraysAreEqual(arr1:any, arr2:any) {
        if (arr1.length !== arr2.length) {
            return false;
          }
        
          // Sort arrays to ensure order consistency
          arr1.sort((a:any, b:any) => JSON.stringify(a) > JSON.stringify(b) ? 1 : -1);
          arr2.sort((a:any, b:any) => JSON.stringify(a) > JSON.stringify(b) ? 1 : -1);
        
          // Compare each object in the arrays
          for (let i = 0; i < arr1.length; i++) {
            const obj1 = arr1[i];
            const obj2 = arr2[i];
            
            // Check if keys match
            const keys1 = Object.keys(obj1).sort();
            const keys2 = Object.keys(obj2).sort();
            if (keys1.join(',') !== keys2.join(',')) {
              return false;
            }
        
            // Check if values match
            for (const key of keys1) {
              if (obj1[key] !== obj2[key]) {
                return false;
              }
            }
          }
        
          return true;
    }
    
    async appendChecklistData() {
        if (this.checkList.value.length > 0) {
            const checkList = [];
            let finalCheckList;
            for (const element of this.checkList.value) {
                if (this.data?.editTaskId) {
                    finalCheckList = {
                        _id: element._id ? element?._id : null,
                        label: element.label,
                        due_date: element?.due_date?.getTime()
                            ? element?.due_date?.getTime()
                            : 0,
                        status: element.status ? true : false,
                    };
                } else {
                    finalCheckList = {
                        label: element.label,
                        due_date: element?.due_date?.getTime()
                            ? element?.due_date?.getTime()
                            : 0,
                        status: element.status ? true : false,
                    };
                }
                checkList.push(finalCheckList);
            }
            return checkList;
        }
    }

    async appendSubtask() {
        const subTaskArr: any = [];
        if (this.subtaskFormArr?.value?.length > 0) {
            await this.subtaskFormArr?.value?.forEach((ele: any) => {
                const subTaskObj: any = {
                    task_name: ele?.subTaskName,
                    due_date:
                        typeof ele?.subTaskDueDate === 'object'
                            ? ele?.subTaskDueDate.getTime()
                            : ele?.subTaskDueDate,
                    status: ele?.subTaskStatus,
                    priority: ele?.subTaskPriority,
                    start_date:
                        typeof ele?.subTaskStartDate === 'object'
                            ? ele?.subTaskStartDate.getTime()
                            : ele?.subTaskStartDate,
                };
                if (ele?.subTaskGroup?.length > 0) {
                    subTaskObj['groups'] = ele?.subTaskGroup.filter(
                        (data: any, index: number) =>
                            index === ele?.subTaskGroup.indexOf(data)
                    );
                } else if (ele?.subTaskAssignee?.length > 0) {
                    subTaskObj['assignees'] = ele?.subTaskAssignee.filter(
                        (data: any, index: number) =>
                            index === ele?.subTaskAssignee.indexOf(data)
                    );
                }
                if (ele._id) {
                    subTaskObj['_id'] = ele._id;
                }
                subTaskArr.push(subTaskObj);
            });
            return subTaskArr;
        }
    }

    toggleCustomFieldBlock() {
        this.isActiveContent = !this.isActiveContent;
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
            document.body.classList.toggle('custom-field-closed');
        }, 0);
    }

    async getManageFieldDetail(companyId: any) {
        (await this.customFieldService.getManageFieldDetail(companyId)).subscribe(
            async (resData: any) => {
                this.is_task_active = resData?.result?.is_task_active;
                this.is_active = resData?.result?.is_active;
            }
        );
    }

    async getModuleData() {
        (await this.clientCustomList.getModuleDetail()).subscribe((res: any) => {
            if (res) {
                this.moduleId = res.result.find(
                    (moduleData: any) => moduleData.module_name === MODULE_NAME.TASK
                ).id;
                this.moduleName = res.result.find(
                    (moduleData: any) => moduleData.module_name === MODULE_NAME.TASK
                ).module_name;

            }
        });
        // Remove above api call and uncomment below code when data correct in prodution data set TASKID = 2
        // this.moduleId = MODULE_NAME.MODULE_ID_TASK;
        // this.moduleName = MODULE_NAME.TASK;
    }

    async getCustomField() {
        (
            await this.clientCustomList.getCustomFieldList(
                this.moduleId,
                this.selectedCompanyId,
                this.isCustomize,
                this.moduleName
            )
        ).subscribe(async (resData: any) => {
            await this.getCountries();
            resData.result.forEach(async (element: any) => {
                element.field['name'] = element.field.label.replace(/\s/g, '');
                element.field['option'] = element.optionDetails;
                this.fields.push(element.field);
                await this.customFieldMain();
            });
        });
    }

    async customFieldMain() {
        await this.customFieldInitialize();
        await this.customFieldInitializeFirst();
        await this.customFieldInitializeSecond();
        await this.customFieldInitializeThird();
    }

    async markAsCompleteTask(completed: any) {
        let taskData: any = await this.getTaskData(this.data?.editTaskId, this.selectedCompanyId);
        if (taskData?.status?.status_name === this.statusNameFormConst.CLOSED  && this.taskData?.status?.status_name !== this.statusNameFormConst.CLOSED) {
            this.toastrService.warning('Task is already closed');
            this.dialogRef.close(true);
            return;
        }

        if(taskData?.status?.status_name === this.statusNameFormConst.UNDER_REVIEW){
            this.toastrService.warning('Task is already in under review');
            this.dialogRef.close(true);
            return;
        }
      
        if(taskData?.status?.status_name !== this.statusNameFormConst.CLOSED && this.taskData?.status?.status_name === this.statusNameFormConst.CLOSED){
            this.toastrService.warning('Task is already reopen');
            this.dialogRef.close(true);
            return;
        }

        if (!this.isReopen) {
            this.markAsCompleted = completed;
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                data: {
                    message: this.translate.instant('MARK_COMPLETED'),
                    buttonText: {
                        ok: 'Yes',
                        cancel: 'No',
                    },
                    showCloseButton: true,
                },
            });
            dialogRef.afterClosed().subscribe(async (confirmed: boolean) => {
                if (confirmed) {
                    this.allStatusList.filter((data: any) => {
                        if (data.status_name === status_name.CLOSED) {
                            this.statusObj = {
                                id: data.id,
                                status_name: data.status_name,
                                status_color: data.status_color,
                            };
                        }
                    });
                    const completeData: any = new FormData();
                    completeData.append('taskId', this.data?.editTaskId);
                    completeData.append('status', JSON.stringify(this.statusObj));
                    completeData.append('isComplete', true);
                    completeData.append('percentage', 100);
                    this.dialogCloseForTaskCompleted(completeData);
                } else {
                    this.markAsCompleted = false;
                }
            });
        } else {
            this.markAsCompleted = completed;
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                data: {
                    message: this.translate.instant('REOPEN_TASK'),
                    buttonText: {
                        ok: 'Yes',
                        cancel: 'No',
                    },
                    showCloseButton: true,
                },
            });
            dialogRef.afterClosed().subscribe(async (confirmed: boolean) => {
                if (confirmed) {
                    this.allStatusList.filter(
                        (data: any) => {
                            if (data.status_name === status_name.PENDING) {
                                this.statusObj = {
                                    id: data.id,
                                    status_name: data.status_name,
                                    status_color: data.status_color,
                                };
                            }
                        }
                    );
                    const completeData: any = new FormData();
                    completeData.append('taskId', this.data?.editTaskId);
                    completeData.append('status', JSON.stringify(this.statusObj));
                    completeData.append('percentage', 0);
                    completeData.append('isComplete', false);
                    await this.dialogCloseForTaskCompleted(completeData);
                } else {
                    this.markAsCompleted = true;
                }
            });
        }
    }

    async customFieldInitialize() {
        for (const f of this.fields) {
            if (f.field_type === 'numeric' && f.sub_type === 'decimal') {
                this.fieldsCtrl[f.name] = new FormControl(
                    '',
                    f.is_mandatory
                        ? [Validators.required, Validators.pattern(RegexEnum.decimal)]
                        : [Validators.pattern(RegexEnum.decimal)]
                );
            } else if (f.field_type === 'numeric' && f.sub_type === 'percentage') {
                this.fieldsCtrl[f.name] = new FormControl(
                    '',
                    f.is_mandatory
                        ? [Validators.required, Validators.pattern(RegexEnum.percentage)]
                        : [Validators.pattern(RegexEnum.percentage)]
                );
            }
        }
    }

    async customFieldInitializeFirst() {
        for (const f of this.fields) {
            if (f.field_type === 'phone') {
                await this.getFinalCode(f?.country_id);
                f['country_short_code'] = this.selectedCountry[0];
                this.fieldsCtrl[f.name] = new FormControl(
                    '',
                    f.is_mandatory ? Validators.required : Validators.nullValidator
                );
            }
        }
    }

    async getFinalCode(country_id: any) {
        const code = this.countries.find((codeName: any) => {
            if (codeName.id === country_id) {
                return codeName.iso3;
            }
        });
        Object.entries(this.preferredCountries).forEach(([key, val]) => {
            if (key === code?.name?.replace(/\s/g, '')) {
                this.finalCode = val;
            }
        });
        this.selectedCountry.push(this.finalCode);
    }

    async customFieldInitializeSecond() {
        for (const f of this.fields) {
            if (f.field_type === 'currency') {
                this.fieldsCtrl[f.country_id] = new FormControl(f.country_id);
                this.fieldsCtrl[f.name] = new FormControl(
                    '',
                    f.is_mandatory
                        ? [Validators.required, Validators.pattern(RegexEnum.currency)]
                        : [Validators.pattern(RegexEnum.currency)]
                );
            } else if (
                f.field_type === 'text' ||
                f.field_type === 'textarea' ||
                f.field_type === 'date' ||
                f.field_type === 'url' ||
                f.field_type === 'checklist' ||
                f.field_type === 'radio'
            ) {
                this.fieldsCtrl[f.name] = new FormControl(
                    '',
                    f.is_mandatory ? Validators.required : Validators.nullValidator
                );
            }
        }
    }

    async customFieldInitializeThird() {
        for (const f of this.fields) {
            if (f.field_type === 'email') {
                this.fieldsCtrl[f.name] = new FormControl(
                    '',
                    f.is_mandatory
                        ? [Validators.required, Validators.pattern(RegexEnum.email)]
                        : [Validators.pattern(RegexEnum.email)]
                );
            } else if (
                f.field_type === 'numeric' &&
                (f.sub_type === 'number' || f.sub_type === 'autonumber')
            ) {
                this.fieldsCtrl[f.name] = new FormControl(
                    '',
                    f.is_mandatory ? Validators.required : Validators.nullValidator
                );
            }
        }
        this.customForm = new FormGroup(this.fieldsCtrl);
        this.customForm.get(this.fields);
    }
    async manageCustomFormData(event: any) {
        for (const [key, value] of Object.entries(event)) {
            let tObject: any = {};
            for (const element of this.fields) {
                if (element.name === key) {
                    switch (element.field_type) {
                        case 'radio':
                            tObject = await this.getValueForRadio(key, value, element);
                            break;
                        case 'checklist':
                            tObject = await this.getValueForChecklist(key, value, element);
                            break;
                        case 'phone':
                        case 'currency':
                            tObject = await this.getValuePhoneCurrency(key, value, element);
                            break;
                        case 'date':
                            tObject = await this.getValueDate(key, value, element);
                            break;
                        case 'numeric':
                            tObject = await this.getValueNumeric(key, value, element);
                            break;
                        default:
                            tObject = await this.getValueRemainType(key, value, element);
                            break;
                    }
                }
            }
            if (
                tObject &&
                Object.keys(tObject).length !== 0 &&
                tObject.value !== ''
            ) {
                this.tArray.push(tObject);
            }
        }
    }

    async getValueForRadio(_key: any, value: any, element: any) {
        let Data = {};
        for (const elementRadio of element.option) {
            if (elementRadio.id === value) {
                Data = {
                    custom_field_id: element.id,
                    value: elementRadio.option,
                    custom_field_option_id: value,
                };
            }
        }
        return Data;
    }

    async getValueForChecklist(key: any, value: any, element: any) {
        if (!element.is_single_select) {
            await this.getValueForChecklistSingle(key, value, element);
        } else if (element.is_single_select) {
            let Data = {};
            for (const elementSingle of element.option) {
                if (elementSingle.id === value) {
                    Data = {
                        custom_field_id: element.id,
                        value: elementSingle.option,
                        custom_field_option_id: elementSingle.id,
                    };
                }
            }
            return Data;
        }
    }

    async getValueForChecklistSingle(_key: any, value: any, element: any) {
        let Data = {};
        for (const elementMulti of element.option) {
            for (const elementValue of value) {
                if (elementMulti.id === elementValue) {
                    Data = {
                        custom_field_id: element.id,
                        value: elementMulti.option,
                        custom_field_option_id: elementValue,
                    };
                    this.tArray.push(Data);
                }
            }
        }
    }

    async getValuePhoneCurrency(_key: any, value: any, element: any) {
        return {
            custom_field_id: element.id,
            value: value,
            custom_field_country_id:
                this.customForm.controls[element.country_id]?.value,
        };
    }

    async getValueDate(_key: any, value: any, element: any) {
        let returnValue;
        if(value != '' ){
            returnValue=moment(value).endOf('day').valueOf().toString()
        }  else{
            returnValue=value.toString();
        }
        return {
            custom_field_id: element?.id,
            value: returnValue,
        };
    }

    async getValueNumeric(_key: any, value: any, element: any) {
        let Data = {};
        if (element.field_type === 'numeric' && element.sub_type === 'autonumber') {
            Data = {
                custom_field_id: element.id,
            };
            return Data;
        } else if (
            element.field_type === 'numeric' &&
            element.sub_type !== 'autonumber'
        ) {
            Data = {
                custom_field_id: element.id,
                value: value,
            };
            return Data;
        }
    }

    async getValueRemainType(_key: any, value: any, element: any) {
        let Data = {};
        Data = {
            custom_field_id: element.id,
            value: value,
        };
        return Data;
    }

    close(e: any) {
        if (e) {
            this.isClose = e;
            this.alarmmenu?.closeMenu();
        }
    }

    async reminderData(eve: any) {
        this.reminderOn = eve.is_on;
        this.reminderObj = {
            reminder_date: eve.reminder_date,
            is_repeat: eve.is_repeat,
            reminder_audience: eve.reminder_audience,
            remind_via: eve.remind_via,
            is_on: eve.is_on,
            repeat_value: eve.repeat_value,
            repeat_frequency: eve.repeat_frequency,
            next_reminder_date_time: eve.next_reminder_date_time,
            task_id: this.task_id || this.data?.editTaskId,
        };

        if (this.reminderObj.is_on) {
            this.reminderValue = true;
        } else {
            this.reminderValue = false;
        }
    }

    async reminder() {
        if (this.reminderValue) {
            (await this.taskService.addReminder(this.reminderObj)).subscribe(
                (res: any) => {
                    this.updateFlag();
                }
            );
        }
    }

    async updateFlag() {
        const updateObj: any = {
            reminder_on: this.reminderObj.is_on,
            task_id: this.task_id || this.data?.editTaskId,
        };
        (await this.taskService.editReminderFlag(updateObj)).subscribe();
    }

    async getCountries() {

        // (await this.customFieldService.getCurrencyWithCountries()).subscribe(
        //     async (res: any) => {
                
        //         this.countries = res.result;
        //         this.store
        //             .select('taskState')
        //             .pipe(take(1))
        //             .subscribe((ele) => {
        //                 const old = JSON.parse(JSON.stringify(ele.taskState));
        //                 old['country'] = res?.result;
        //                 this.store.dispatch(taskState({ data: old }));
        //             });
        //     }
        // );

        this.countries = COUNTRY_ISD_CODE
    }

    async getReminderDetail() {
        if (this.isReminder === 'true' || this.isReminder) {
            (await this.taskService.getReminder(this.data.editTaskId)).subscribe(
                (res: any) => {
                    this.reminderDetail = res.result;
                }
            );
        }
    }

    get checkList() {
        return this.taskDetailForm.get('checkList') as FormArray;
    }

    resetCheckList() {
        const checkListArray = this.taskDetailForm.get('checkList') as FormArray;
        checkListArray.clear();
    }

    async getCheckTemplateList(companyId: any, userId: any) {
        (
            await this.checklistService.getCheckTemplateList(companyId, userId)
        ).subscribe((data: any) => {
            this.store
                .select('taskState')
                .pipe(take(1))
                .subscribe((ele) => {
                    const old = JSON.parse(JSON.stringify(ele.taskState));
                    old['checkList'] = data;
                    this.store.dispatch(taskState({ data: old }));
                });
            this.isShowCheckList = data.checkListManage[0]?.is_enable;
            this.checklistData = data.checkLists;
        });
    }

    importChecklist(event: any) {
        this.trigger.closeMenu();
        this.selectedChecklistData = event;
        this.checklistTitle.push(event.map((ele: any) => ele.title));
        this.selectedChecklistData?.forEach((element: any) => {
            element?.check_list_label.forEach((element1: any) => {
                this.checkList.push(
                    this.formBuilder.group({
                        label: [element1, Validators.required],
                        due_date: new Date(),
                        status: [false, Validators.required],
                        mandatory_template: [this.markAsMandatory ? true : false],
                    })
                );
            });
        });
    }

    subTaskCheckList(event: any) {
        const allSubTask: any = [];
        const allCheckListLable = event[0].check_list_label;
        event[0].check_list_label.forEach((ele: any) => {
            const dataObj: any = {
                _id: '',
                subTaskName: ele,
                subTaskDueDate: '',
                subTaskPriority: this.priority.find(
                    (data: any) => data?.priority_name === priority_name.low
                ),
                subTaskStatus: this.statusList.find(
                    (data: any) => data?.status_name === status_name.PENDING
                ),
                subTaskAssignee: [],
                subTaskGroup: [],
                subTaskStartDate: '',
            };
            allSubTask.push(dataObj);
        });
        this.taskService.subTaskDetails({
            isCheckListSubTask: true,
            data: [...allSubTask],
        });
    }

    addCheckListRow(checkListData?: any) {
        this.checkList.push(
            this.formBuilder.group({
                label: [
                    checkListData?.label ? checkListData.label : '',
                    Validators.required,
                ],
                due_date: [
                    checkListData?.due_date
                        ? new Date(parseInt(checkListData?.due_date))
                        : null,
                ],
                status: [checkListData?.status ? true : false, Validators.required],
                userId: [this.userId],
                _id: [checkListData?._id ? checkListData._id : null],
                mandatoryTemplate: [this.markAsMandatory ? true : false],
            })
        );
        this.isEnableDropdown.isCheckListEnable.push(false);
    }

    changeMandatory(event: any) {
        this.markAsMandatory = event;
    }

    async updateCheckListStatusAndDelete(data: any, type: any, index: any) {
        if (type === 'status') {
            this.deleteOrCompleteChecklist(data, type, index);
        } else {
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                data: {
                    message: this.translate.instant('REMOVE_CHECKLIST'),
                    buttonText: {
                        ok: 'Yes',
                        cancel: 'No',
                    },
                },
            });
            dialogRef.afterClosed().subscribe(async (confirmed: boolean) => {
                if (confirmed) {
                    this.deleteOrCompleteChecklist(data, type, index);
                }
            });
        }
    }

    async deleteOrCompleteChecklist(data: any, type: any, index: any) {
        if (this?.data?.editTaskId) {
            if (data?.value?._id) {
                const checkListId = data?.value?._id;
                const taskId = this.data.editTaskId;
                const isDeleted = type === 'status' ? false : true;
                (
                    await this.taskService.updateStatusAndDeleteChecklist(
                        checkListId,
                        taskId,
                        isDeleted
                    )
                ).subscribe((res: any) => {
                    if (res) {
                        this.toastrService.success(res.msg);
                        if (type === 'delete') {
                            this.checkList.removeAt(index);
                        }
                    }
                });
            } else {
                if (type === 'delete') {
                    this.checkList.removeAt(index);
                }
            }
        } else {
            if (type === 'delete') {
                this.checkList.removeAt(index);
            }
        }
    }

    async addProgress(progress: any) {
        const formData = new FormData();
        this.progressValue = progress.value;
        if (this.data?.editTaskId) {
            formData.append('percentage', this.progressValue);
            formData.append(
                'changedFieldValues',
                JSON.stringify({
                    percentage: {
                        old_value: this.taskData?.percentage
                            ? this.taskData?.percentage
                            : 0,
                        new_value: this.progressValue,
                    },
                    changeFromTaskList: true,
                })
            );
            (
                await this.taskService.editTask(formData, this.data.editTaskId, 1)
            ).subscribe((res) => {
                if (res.message) {
                    this.toastrService.success(res.message);
                }
            });
        }
    }

    async dialogCloseForTaskCompleted(completeData: any) {
        (await this.taskService.taskMarkAsCompleted(completeData, 1)).subscribe(
            (res: any) => {
                if (res[0].msg) {
                    this.apiService.showSuccess(res[0].msg);
                    this.dialogRef.close(true);
                } else {
                    if (!this.isReopen) {
                        this.markAsCompleted = false;
                    } else {
                        this.markAsCompleted = true;
                    }
                }
            }
        );
    }

    async getGeneralSetting() {
        if(this.taskState?.generalMasterConfig){
            this.isShowClientList = this.taskState?.generalMasterConfig?.companies_detail?.is_having_clients;
            this.isShowService = this.taskState?.generalMasterConfig?.companies_detail?.is_having_services;
            this.progressVisible = this.taskState?.generalMasterConfig?.is_percentage;
        }else{
            (
                await this.organizationSettings.getGeneralSettings(
                    this.selectedCompanyId,
                    this.userId
                )
            ).subscribe((res: any) => {
                if (res) {
                    this.isShowClientList = res?.companies_detail?.is_having_clients;
                    this.isShowService = res?.companies_detail?.is_having_services;
                    this.progressVisible = res?.is_percentage;
                }
            });
        }

    }
    // async getCompanyDetails(id: any) {
    //     (await this.companyDetailService.getCompanyDetails(id)).subscribe(
    //         async (res: any) => {
    //             this.companyData = { id: res.id, name: res.company_name, profile_image: res.profile_image };
    //             if (res) {
    //                 this.isShowClientList = res?.is_having_clients;
    //                 this.isShowService = res?.is_having_services;
    //             }
    //         }
    //     );
    // }

    async getMarsAsApprovalFlowData() {
        this.markAsApprovalData = this.taskState?.markAsApprove;
    }

    async markAsApprovalFlow(approved: any) {
        let taskData: any = await this.getTaskData(this.data?.editTaskId, this.selectedCompanyId);

        if (taskData?.status?.status_name === this.statusNameFormConst.CLOSED || taskData?.status?.status_name === this.statusNameFormConst.UNDER_REVIEW) {
            taskData?.status?.status_name === this.statusNameFormConst.CLOSED ? this.toastrService.warning('Task is already closed') : this.toastrService.warning('Task is already in under review');
            this.toastrService.warning('Task is already closed');
            this.dialogRef.close(true);
            return;
        }

        this.markAsApproval = approved;
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                message: this.translate.instant('MARK_UNDER_REVIEW'),
                buttonText: {
                    ok: 'Yes',
                    cancel: 'No',
                },
                showCloseButton: true,
            },
        });
        dialogRef.afterClosed().subscribe(async (confirmed: boolean) => {
            if (confirmed) {
                this.allStatusList.filter((data: any) => {
                    if (data.status_name === status_name.UNDER_REVIEW) {
                        this.statusObj = {
                            id: data.id,
                            status_name: status_name.UNDER_REVIEW,
                            status_color: data.status_color,
                        };
                    }
                });
                const completeData: any = new FormData();
                completeData.append('taskId', this.data?.editTaskId);
                completeData.append('status', JSON.stringify(this.statusObj));
                await this.dialogCloseForTaskUnderReview(completeData);
            } else {
                this.markAsApproval = false;
            }
        });
    }

    async taskApprovalReject(approved: any) {
        let taskData: any = await this.getTaskData(this.data?.editTaskId, this.selectedCompanyId);
        if (taskData?.status?.status_name === this.statusNameFormConst.CLOSED) {
            this.toastrService.warning('Task is already closed');
            this.dialogRef.close(true);
            return;
        }

        if(taskData?.status?.status_name === this.statusNameFormConst.UNDER_REVIEW && this.taskData?.status?.status_name !== this.statusNameFormConst.UNDER_REVIEW){
            this.toastrService.warning('Task is already in under review');
            this.dialogRef.close(true);
            return;
          }

        this.markAsApproval = approved;
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                message: this.translate.instant('REVIEWED_TASK_MSG'),
                buttonText: {
                    ok: 'Approve',
                    cancel: 'Reject',
                },
                showCloseButton: true,
            },
        });
        dialogRef.afterClosed().subscribe(async (confirmed: any) => {
            if (confirmed === true) {
                this.allStatusList.filter((data: any) => {
                    if (data.status_name === status_name.CLOSED) {
                        this.statusObj = {
                            id: data.id,
                            status_name: data.status_name,
                            status_color: data.status_color,
                        };
                    }
                });
                const completeData: any = new FormData();
                completeData.append('taskId', this.data?.editTaskId);
                completeData.append('status', JSON.stringify(this.statusObj));
                completeData.append('isApproved', true);
                await this.dialogCloseForApproveReject(completeData);
            } else if (confirmed === false) {
                this.allStatusList.filter((data: any) => {
                    if (data.status_name === status_name.REJECTED) {
                        this.statusObj = {
                            id: data.id,
                            status_name: data.status_name,
                            status_color: data.status_color,
                        };
                    }
                });
                const completeData: any = new FormData();
                completeData.append('taskId', this.data?.editTaskId);
                completeData.append('status', JSON.stringify(this.statusObj));
                completeData.append('isApproved', false);
                await this.dialogCloseForApproveReject(completeData);
            } else {
                this.markAsApproval =
                    this.selectedStatus?.status_name === status_name.UNDER_REVIEW
                        ? true
                        : false;
            }
        });
    }

    async dialogCloseForTaskUnderReview(completeData: any) {
        (
            await this.markAsApprovalService.taskUnderReview(completeData, 1)
        ).subscribe((res: any) => {
            if (res[0].message) {
                this.apiService.showSuccess(res[0].message);
                this.dialogRef.close(true);
            } else {
                if (!this.canMarkAsApprove) {
                    this.markAsApproval = false;
                } else {
                    this.markAsApproval = true;
                }
            }
        });
    }

    checkTimer() {
        if (
            this.timeLogData?.is_enabled === 1 ||
            this.timeLogData?.is_enabled === true
        ) {
            this.timerToggle = true;
        }
        if (
            this.timeLogData?.process_type === 'both' ||
            this.timeLogData?.process_type === 'auto'
        ) {
            this.timerShow = true;
        }
    }

    toggleTimer() {
        this.isTimerRunning = !this.isTimerRunning;
        if (this.isTimerRunning) {
            this.eventType = 'start';
            this.setTimerId = null;
            this.timerInterval = setInterval(() => {
                this.timeSpent++;
                this.timeSpentHMS = new Date(this.timeSpent * 1000)
                    .toISOString()
                    .substring(11, 19)
                    .split(':');
            }, 1000);
        } else {
            this.eventType = 'end';
            this.setTimerId = this.timerId;
            clearInterval(this.timerInterval);
            this.timeSpent = 0;
            this.timeSpentHMS = ['00', '00', '00'];
        }
        this.addTimer();
    }

    async addTimer() {
        this.timerObj = {
            task_id: this.data?.editTaskId,
            comment: null,
            project_id: null,
            event: this.eventType,
            company_id: this.decryptedCompanyId,
            user_id: this.decryptedUserId,
            _id: this.setTimerId,
        };
        (await this.taskService.onOffTimer(this.timerObj)).subscribe((res: any) => {
            if (!res.result) {
                this.apiService.showError(res.msg);
            } else {
                if (this.eventType === 'end') this.onTimerEvent.emit();
                this.timerId = res.result?._id;
            }
        });
    }

    async dialogCloseForApproveReject(completeData: any) {
        (
            await this.markAsApprovalService.taskApproveReject(completeData)
        ).subscribe((res: any) => {
            if (res[0].message) {
                this.apiService.showSuccess(res[0].message);
                this.dialogRef.close(true);
            }
        });
    }

    async getAttachmentDetail() {
        (
            await this.attachmentService.getDocumentList(this.data.editTaskId)
        ).subscribe((res: any) => {
            if (res.data) {
                res?.data?.taskAttachment?.sort((a: any, b: any) => {
                    const dateA: any = new Date(a.created_at);
                    const dateB: any = new Date(b.created_at);
                    return dateB - dateA;
                });
                res?.data?.taskAttachment?.forEach(async (doc: any) => {
                    const lastDotIndex = doc.file_path.lastIndexOf('.');
                    if (lastDotIndex !== -1) {
                        const stringAfterLastDot = doc.file_path.slice(lastDotIndex);
                        doc['type'] = stringAfterLastDot;
                        doc['icon'] = await this.utils.getDocumentIcon(doc, false);
                        this.attatchmentDetail.push(doc);
                    }
                });
            }
        });
    }

    getKanbanStatus() {
        this.statusSubscription = this.emitTaskService.statusDataKanban$.subscribe(
            (data: any) => {
                if (data) {
                    this.selectedStatus = data;
                }
            }
        );
    }

    ngOnDestroy(): void {
        this.taskService?.subTaskDetails([]);
        this.createSubtask?.resetSubTaskList();
        this.taskService?.selectedTaskDetails.next('');
        this.statusSubscription?.unsubscribe();
        this.emitTaskService?.setStatusKanban(null);
        this.statusSubscription?.unsubscribe();
        this.taskService?.projectIdEvent([]);
        this.subscription?.unsubscribe();
        this.discussionSocketService.emit(discussionSocketEvents.LEAVE_ROOM, {
            id: this.taskData?._id,
        });
    }

    async addTaskDiscussion(data: any) {
        let taskData: any = await this.getTaskData(this.data?.editTaskId, this.selectedCompanyId);
        if (!taskData?.discussion_id) {
            const user_ids: any = [];
            this.companyEmployees.forEach((element: any) => {
                if (this.selectedAssigneesFromBE.assignees.includes(element.id)) {
                    user_ids.push(element.user_id);
                }
            });

            const objDiscussion = {
                title: this.taskDetail?.task_name,
                project_id: this.taskDetail?.project?._id,
                task_id: this.taskDetail?.task_id,
                user_ids: user_ids,
                group_ids: this.selectedAssigneesFromBE.groups,
                description: '',
                type: DISCUSSION_TYPES.TASK,
            };
            (await this.discussionService.addDiscussion(objDiscussion)).subscribe(
                (sharResponse: any) => {
                    this.toastrService.success(sharResponse.message, 'Shared');
                    this.router.navigate(['/discussion'], {
                        queryParams: {
                            discussion_id: sharResponse.data._id,
                            discussionType: DISCUSSION_TYPES['TASK'],
                        },
                        skipLocationChange: true,
                    });
                    this.dialogRef.close(true);
                }
            );
        } else {
            this.router.navigate(['/discussion'], {
                queryParams: {
                    discussion_id: this.discussion_id,
                    discussionType: DISCUSSION_TYPES['TASK'],
                },
                skipLocationChange: false,
            });
            this.dialogRef.close(true);
        }
    }

    makeDataForRecurringTask() {
        const dataToSend: any = {};
        dataToSend['recurring_frequency'] = this.dueDateDataObj?.frequency;
        switch (this.dueDateDataObj?.frequency) {
            case 'weekly':
                this.weekValue = [];
                const daysSelect: any = Object.entries(this.dueDateDataObj?.weekDays);
                daysSelect.forEach((x: any, i: number) => {
                    if (x.includes(true)) {
                        this.weekValue.push(i);
                    }
                });
                dataToSend['recurring_value'] = this.dueDateDataObj?.weekDays;
                break;
            case 'monthly':
                dataToSend['recurring_value'] = [
                    typeof this.dueDateDataObj?.onSelectedDay === 'number'
                        ? this.dueDateDataObj?.onSelectedDay
                        : this.dueDateDataObj?.onSelectedDay?.getTime(),
                ];
                break;
            case 'quarterly':
            case 'yearly':
            case 'halfyearly':
                dataToSend['recurring_value'] = [
                    typeof this.dueDateDataObj?.recurringDate === 'number'
                        ? this.dueDateDataObj?.recurringDate
                        : this.dueDateDataObj?.recurringDate?.getTime(),
                ];
                break;
            case 'custom':
                dataToSend['recurring_value'] = [this.dueDateDataObj?.customOnDay];
                break;
        }
        return dataToSend;
    }

    checkTimeLog() {
        if (this.timeLogData?.is_enabled == 1) {
            this.timerToggle = true;
        }
        if (
            this.timeLogData?.process_type === TIME_LOG_TYPE.BOTH ||
            this.timeLogData?.process_type === TIME_LOG_TYPE.AUTO
        ) {
            this.timerShow = true;
        }
    }

    async submitTimer(event: any) {
        this.eventName = event;
        if (!this.data?.editTaskId) {
            return;
        }
        this.timerEvent = event;
        const startTime = moment(new Date()).format('DD-MM-YYYY HH:mm:ss');
        const epoch = new Date(startTime);
        if (event === 'start') {
            this.setTimerId = null;
            this.disableAllFunctionality = false;
            this.apiService.showLoading();
            this.timerObj = {
                project_id:
                    this.selectedProjectId.length > 0 ? this.selectedProjectId[0] : null,
                task_id: this.data?.editTaskId,
                hours: null,
                start_time: new Date().getTime(),
                end_time: null,
                comment: null,
                event: this.timerEvent,
                type: 'task',
            };
            (await this.timeSheetService.addTimeLogEntry(this.timerObj)).subscribe(
                async (res: any) => {

                    if (res) {
                        if (res?.error?.msg === 'Time sheet data lock.') {
                            this.timerResponseObj = {};
                            this.timerValue = '00:00:00';
                            this.clearTimer();
                            this.setTimerId = null;
                            this.timerEvent = 'end';
                            this.isAnyTaskOngoingOfUser = false;
                        } else {
                            await this.getTimer();
                            this.clearTimer();
                            this.timerValue = this.timerResponseObj?.current_timmer;
                            this.setTimerValue();
                            this.setTimerId = this.timerResponseObj?._id;
                        }
                    } else {
                        this.clearTimer();
                        this.timerValue = '00:00:01';
                        this.setTimerValue();
                    }
                }
            );
        } else if (event === 'end') {
            this.disableAllFunctionality = true;
            this.clearTimer();
            this.openNoteModal();
            this.setTimerValue();
        }
        this.apiService.hideLoading();
    }

    async isTaskOngoingInOther() {
        (
            await this.taskService.getTaskDetailById(
                this.data.editTaskId,
                this.selectedCompanyId,
                this.moduleId
            )
        ).subscribe((res: any) => {
            this.disableAllFunctionality = res._source?.on_going ? false : true;
        });
    }

    setTimerValue() {
        this.intervalId = setInterval(() => {
            this.timerValue = this.utils.addTimesTasks(this.timerValue, '00:00:01');
        }, 1000);
    }

    clearTimer() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    async getTimer() {
        this.timerResponseObj = null;
        this.timerEvent = null;
        this.timerValue = '00:00:00';
        this.clearTimer();
        this.apiService.showLoading();
        (
            await this.timeSheetService.getTimer(
                this.data?.editTaskId,
                new Date().getTime()
            )
        ).subscribe((res: any) => {
            this.timerResponseObj = res.result;
            if (this.timerResponseObj && this.timerResponseObj.event === 'start') {
                this.setTimerId = this.timerResponseObj?._id;
                this.timerEvent = 'start';
                this.clearTimer();
                this.timerValue = this.timerResponseObj.current_timmer;
                this.setTimerValue();
            }
        });

        this.apiService.hideLoading();
    }

    openNoteModal() {
        const myCompDialog = this.dialog.open(TimerNoteModalComponent, {
            disableClose: true,
            panelClass: ['custom-modal', 'custom-modal-md'],
        });
        myCompDialog.afterClosed().subscribe(async (_res: any) => {
            if (_res.isConfirm) {
                this.apiService.showLoading();
                this.timerObj = {
                    time_sheet_id: this.setTimerId ? this.setTimerId : null,
                    project_id:
                        this.selectedProjectId.length > 0
                            ? this.selectedProjectId[0]
                            : null,
                    task_id: this.data?.editTaskId,
                    hours: null,
                    start_time: this.timerResponseObj?.start_time,
                    end_time: new Date().getTime(),
                    comment: _res.notes ? _res.notes : null,
                    event: this.timerEvent,
                    type: 'task',
                };
                (await this.timeSheetService.addTimeLogEntry(this.timerObj)).subscribe(
                    async (res: any) => {
                        if (res.msg) {
                            this.apiService.showSuccess(res.msg);
                            this.timerResponseObj = {};
                            this.timerValue = '00:00:00';
                            this.clearTimer();
                            this.timerEvent = 'end';
                            this.setTimerId = null;
                            this.getTimeLogData();
                            this.isAnyTaskOngoingOfUser = false;
                        } else {
                            if (res.status == 400) {
                                this.timerResponseObj = {};
                                this.timerValue = '00:00:00';
                                this.clearTimer();
                                this.setTimerId = null;
                                this.timerEvent = 'end';
                                this.isAnyTaskOngoingOfUser = false;
                            }
                        }
                        await this.isTaskOngoingInOther();
                        this.apiService.hideLoading();
                    }
                );
            } else {
                this.timerEvent = 'start';
            }
        });
    }

    async getTimeLogData() {
        const data: any = {
            taskId: this.data?.editTaskId,
            selectUserId: null,
            selectedTaskId: null,
            isPrimary: true,
        };
        (await this.timeSheetService.getTimeLog(data)).subscribe((res: any) => {
            this.timeLogDetail = res.result;
            this.ActualHours = this.timeLogDetail?.totalHours;
        });
    }

    checkListComment(checklistId: any, checkListName: any) {
        this.selectedRoom = {
            _id: checklistId,
            user_id: this.taskData.owner.user_id,
            title: this.taskDetailForm.controls['taskName'].value,
            task_id: this.taskData._id,
            color: '#DC3535',
            created_by: this.taskData.owner,
            accesses:
                this.assigneeUser?.length > 0
                    ? this.assigneeUser?.length
                        ? [...this.assigneeUser]
                        : []
                    : this.assigneeGroup?.length
                        ? [...this.assigneeGroup]
                        : [],
            type: DISCUSSION_COMMENT_TYPE.CHACKLIST,
            avatar: this.utils.forShortName(checkListName),
            main_avatar: this.utils.forShortName(checkListName),
            profile_image: '#DC3535',
            checkListName: checkListName,
        };

        this.discussionSocketService.emit(discussionSocketEvents.JOIN_ROOM, {
            id: this.selectedRoom._id,
        });
        this.discussionSocketService.emit(discussionSocketEvents.LEAVE_ROOM, {
            id: this.taskData._id,
        });

        this.checklistCommentComponent = this.matDialog.open(
            ChecklistCommentComponent,
            {
                disableClose: true,
                panelClass: ['custom-modal', 'custom-modal-md'],
                data: this.selectedRoom,
            }
        );

        this.checklistCommentComponent.afterClosed().subscribe(async (res: any) => {
            if (res) {
                this.discussionSocketService.emit(discussionSocketEvents.LEAVE_ROOM, {
                    id: this.selectedRoom._id,
                });            

                this.discussionSocketService.emit(discussionSocketEvents.JOIN_ROOM, {
                    id: this.taskData._id,
                });
            }
        });
    }

    async previousNextTask(type: any) {
        if (type == 'isPrevious') {
            this.currentSubTaskId = null;
            this.customForm.reset();
            this.getTaskDetailById(this.parentId);
            return;
        }

        let current = this.subTasks.findIndex((task: any) => {
            return task._id == this.currentSubTaskId;
        });
        current++;
        this.currentSubTaskId = this.subTasks[current]?._id;
        this.customForm.reset();
        this.getTaskDetailById(this.currentSubTaskId);
    }

    isDisable(type: string) {
        if (!this.subTasks) return true;
        const index = this.subTasks.findIndex((task: any) => {
            return task?._id == this.currentSubTaskId;
        });
        if (type == 'isNext') {
            return index == this.subTasks.length - 1;
        } else {
            return index == -1;
        }
    }

    async stopRecurringTask(taskId: any) {
        (await this.taskService.stopRecurringTask(taskId)).subscribe(
            async (res: any) => {
                this.apiService.showSuccess(res.msg);
                this.taskData.is_recurring_task = false;
                await this.getTaskDetailById();
            }
        );
    }

    convertTaskFromEmail() {
        this.newFiles = [];
        if (this.data.email.length > 1) {
            this.multipleEmailTask = true;
        }
        for (const element of this.data.email) {
            this.emailTask = true;
            this.taskDetailForm.controls['taskName'].setValue(element.subject);
            // Need if htmlToText is not work! 
            // const desc = new DOMParser().parseFromString(
            //     element.body_sort,
            //     'text/html'
            // );
            // const description = desc.querySelector('body').textContent;
            // this.taskDetailForm.controls['taskDesc'].setValue(description);

            const result = htmlToText(element.body_sort, {
                selectors: [
                  { selector: 'a', options: { linkBrackets: false } },
                  { selector: 'a', options: { hideLinkHrefIfSameAsText: true } },
                  { selector: 'a', options: { ignoreHref: true } },
                  { selector: 'img', format: 'skip' },
                ],
              });

            this.taskDetailForm.controls['taskDesc'].setValue(result);

            this.taskUsers.push({
                id: element.id,
                taskName: this.taskDetailForm.controls['taskName'].value,
                taskDesc: this.taskDetailForm.controls['taskDesc'].value,
            });
            this.updateFiles = element.email_attachments;
            this.updateFiles.forEach((doc: any) => {
                doc['name'] = doc.file_name;
                doc['file_path'] = doc.path;
            });
        }
    }

    async convertEmailToTask(emailId?: any) {
        const emailObj = {
            task_id: this.task_id,
            email_id: emailId,
        };
        this.subscription = (await this.emailService.taskCreateFromEmail(emailObj)).subscribe();
    }
    ngAfterViewInit() {
        this.adjustTextareaHeight();
        this.dynamicWidth = this.elementRef.nativeElement.offsetWidth - 30;
        this.dynamicClientWidth =
            this.elementRefDivClient?.nativeElement.offsetWidth - 30;
    }
    ngOnChanges(changes: SimpleChanges) {
        if (changes['content']) {
            this.adjustTextareaHeight();
        }
    }
    adjustTextareaHeight(): void {
        const textarea = this.textareaRef.nativeElement;
        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight + 'px';
    }
    async getQuickReplyList() {
        (await this.quickReplyService.quickReplyList()).subscribe((data: any) => {
            this.quickReplyList = data?.quickReplyList;
        });
    }


    async quickReplyCheck(event: any, modelEvent: any, index?: any) {
        this.quickReplyFilter = [];
        if (event.target.value.charAt(0) === '/') {
            if (!this.quickReplyList.length) {
                await this.getQuickReplyList();
            }
            if (modelEvent === 'taskName') {
                this.isEnableDropdown.isTaskNameEnable = true;
            } else if (modelEvent === 'taskDesc') {
                this.isEnableDropdown.isTaskDescEnable = true;
            } else if (modelEvent === 'subTask') {
                this.isEnableDropdown.isSubTaskEnable[index] = true;
            } else if (modelEvent === 'checkList') {
                this.isEnableDropdown.isCheckListEnable[index] = true;
            }
            const quickReplyString = event.target.value
                .replace('/', '')
                .toLocaleLowerCase();
            if (quickReplyString) {
                this.quickReplyList.filter((it: any) => {
                    if (it.title) {
                        const value = it.title
                            .toLocaleLowerCase()
                            .includes(quickReplyString);
                        const hasMessage = it.message
                            .toLocaleLowerCase()
                            .includes(quickReplyString);
                        if (value || hasMessage) {
                            this.quickReplyFilter.push(it);
                        }
                    }
                });
            } else {
                this.quickReplyFilter = this.quickReplyList;
            }
        } else {
            if (modelEvent === 'taskName') {
                this.isEnableDropdown.isTaskNameEnable = false;
            } else if (modelEvent === 'taskDesc') {
                this.isEnableDropdown.isTaskDescEnable = false;
            } else if (modelEvent === 'subTask') {
                this.isEnableDropdown.isSubTaskEnable[index] = false;
            } else if (modelEvent === 'checkList') {
                this.isEnableDropdown.isCheckListEnable[index] = false;
            }
        }
    }

    public handleStaticResultSelected(result: any, modelEvent: any, index?: any) {
        if (result.message) {
            if (modelEvent === 'taskName') {
                this.taskNameValue = result.message;
                this.taskDetailForm.controls['taskName'].setValue(this.taskNameValue);
                this.isEnableDropdown.isTaskNameEnable = false;
            } else if (modelEvent === 'taskDesc') {
                this.taskDescValue = result.message;
                this.taskDetailForm.controls['taskDesc'].setValue(this.taskDescValue);
                this.isEnableDropdown.isTaskDescEnable = false;
            } else if (modelEvent === 'checkList') {
                this.checkList.at(index).get('label').setValue(result.message);
                this.isEnableDropdown.isCheckListEnable[index] = false;
            }
        }
    }

    openClientModel() {
        const dialogRef = this.dialog.open(AddClientComponent, {
            panelClass: ['custom-modal', 'custom-modal-xl', 'add-client-modal'],
            data: {
                editClient: false,
                companyId: this.selectedCompanyId,
                isOpenFromTaskModule: true,
            },
        });
        dialogRef.afterClosed().subscribe(async (data: any) => {
            if (data) {
                await this.updateStoreData();
                await this.getclientList();
                await this.clientListData.forEach((ele: any) => {
                    if (
                        ele.name ===
                        data?.data?.client_name + ' - ' + data?.data?.companyName
                    ) {
                        ele.isCheck = true;
                        this.selectedClient.push(ele.id);
                    }
                });
            }
        });
    }

    openServiceModel() {
        const matDialogRef = this.matDialog.open(AddServiceComponent, {
            panelClass: ['custom-modal', 'custom-modal-lg'],
            data: {
                isOpenFromTaskModule: true,
                companyId: parseInt(this.decryptedCompanyId),
                encryptedCompanyId: this.selectedCompanyId,
            },
        });
        matDialogRef.afterClosed().subscribe(async (data) => {
            if (data?.data) {
                await this.updateStoreData();
                await this.getRegularService();
                this.selectedService = [];
                await this.serviceListData.forEach((ele: any) => {
                    if (
                        ele.name.toLowerCase() === data?.data?.service_name.toLowerCase()
                    ) {
                        ele.isCheck = true;
                        this.selectedService.push(ele.id);
                    }
                });
                this.setMatPreview('service');
            }
        });
    }

    async updateStoreData() {
        (await this.reportService.filterReport()).subscribe(async (res: any) => {
            const userKeyByValue = _.keyBy(res.result, 'id');
            res.result?.group_details.map((ele: any) => userKeyByValue[ele]);
            this.store
                .select('taskState')
                .pipe(take(1))
                .subscribe((ele) => {
                    const old = JSON.parse(JSON.stringify(ele.taskState));
                    old['reportConfig'] = res?.result;
                    this.store.dispatch(taskState({ data: old }));
                });
        });
    }

    async recurringParentRedirect(data: any) {
        this.apiService.showLoading();
        await this.getTaskDetailById(data);
        setTimeout(() => {
            this.isUpdateTaskid.emit(this.taskData);
            this.apiService.hideLoading();
        }, 2000);
    }
    knowType(kt: any) {
        return typeof kt;
    }
    async readOnNav() {
        let task_data = localStorage.getItem('task_id');
        let readVar: any;

        if (task_data) {
            task_data = atob(task_data);
            let isIdInArray: any = true;
            const formData = new FormData();
            const foundObject = this.allMemberListData.find(
                (obj) => obj.member_id === this.memberId
            );
            if (!(foundObject && foundObject.user_id === this.taskCreatorInfo.user_id)) {
                isIdInArray = this.setReadStatus.some(
                    (obj: any) => obj.id === this.memberId
                );
            }
            if (!isIdInArray) {
                const currentUTC = new Date().toISOString();
                const loginUserData: any =
                    await this.userDetailService.getLoginUserData();
                const trimmedString = loginUserData?.user_name.replace(/\s+/g, ' ');
                const name = trimmedString?.split(' ');
                const dataObj = {
                    first_name: name[0].trim(),
                    last_name: name[1].trim(),
                    id: this.memberId,
                    profile_image:
                        loginUserData?.profile_image?.charAt(0) == 'h'
                            ? loginUserData?.profile_image
                            : '#FAB222',
                    created_at: currentUTC,
                };
                formData.append('user', JSON.stringify(dataObj));
                (await this.taskListData.taskReadUnread(task_data, formData)).subscribe(
                    (_res: any) => {
                        this.emitTaskService.readByUserList(this.setReadStatus);
                    }
                );
            }
        }
    }

    changeSubtaskMandatory(event: any) {
        this.markAsSubtaskMandatory = event;
    }

    scrollDownOnAddSubtask() {
        const element: any = document.getElementById('mat-card-content') as HTMLElement
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }

    async getTaskData(taskId: any, companyId: any) {
        let taskData: any;
        (
            await this.taskListData.getTaskDetailById(
                taskId,
                companyId,
                this.moduleId
            )
        ).subscribe(async (res: any) => {
            return taskData = res?._source;
        })
        return taskData;
    }
    receiveClientData(data: any) {
        this.clientDataPreview=data;
        this.clientMatPreview=data;
        if(data.length>0){
            data.forEach((element:any) => {
                this.selectedClient.push(element.id)
            });
        }
        else{
            this.selectedClient.length=0;
        }
        this.cdr.detectChanges();
      }
}
