export const environment = {
    DOMAIN:'dev-ms.taskopad.com',
    MEDIA_BUCKET_NAME:'s3.ap-south-1.amazonaws.com/ms-taskopad.com',
    production: false,
    API_URL: 'https://ms-bff.taskopad.com/',
    url: 'https://ms-bff.taskopad.com/',
    userUrl: 'https://ms-user.taskopad.com/',
    subscriptionUrl: 'https://ms-sub.taskopad.com/',
    discussionURL: 'https://ms-discussion.taskopad.com/',
    socketUrl: 'https://ms-discussion.taskopad.com/',
    RAZOR_PAY_KEY: 'rzp_test_cv1yezr6ERzQ7s',
    RECAPTCHA_V3_SITE_KEY: '6LcZRDMnAAAAAEcsehsK5KAFft-1qDBXD_wdjlI_',
    RECAPTCHA_V2_SITE_KEY: '6LcTbeAcAAAAAGPYWbevOiIDQuvOjKp7HUPVxHSq',
    buildVersion: '2.0.1',
    firebase: {
        apiKey: 'AIzaSyAK4D6IWoau37BfWWDHeKHn6vKsquyu5m0',
        authDomain: 'task0pad-stage.firebaseapp.com',
        projectId: 'task0pad-stage',
        storageBucket: 'task0pad-stage.appspot.com',
        messagingSenderId: '163883225284',
        appId: '1:163883225284:web:affd684d14a74375f37991',
        measurementId: 'G-XD3RZTNGSH',
    },


    

    MS_AUTH: 'https://ms-auth.taskopad.com/',
    MS_ORG: 'https://ms-org.taskopad.com/',
    MS_BFF: 'https://ms-bff.taskopad.com/',
    MS_TIMESHEET: 'https://ms-timesheet.taskopad.com/',
    MS_REPORT: 'https://ms-report.taskopad.com/',
    MS_USER: 'https://ms-user.taskopad.com/',
    MS_DISCUSSION: 'https://ms-discussion.taskopad.com/',
    MS_DMS: 'https://ms-dms.taskopad.com/',
    MS_NOTE: 'https://ms-notes.taskopad.com/',
    MS_TASK: 'https://ms-task.taskopad.com/',
    MS_NOTIFICATION: 'https://ms-notification.taskopad.com/',

    // Remove below URL after local testing code 

    // MS_AUTH: 'http://192.168.10.142:5000/',
    // MS_ORG: 'http://192.168.10.142:5002/',
    // MS_BFF: 'http://192.168.10.142:5010/',

    // API_URL: 'http://192.168.10.142:5010/',
    // url: 'http://192.168.10.142:5010/',
    // subscriptionUrl: 'http://192.168.10.142:5003/',
    // userUrl: 'http://192.168.10.142:5001/',


    // MS_TIMESHEET: 'http://192.168.10.142:5009/',
    // MS_REPORT: 'http://192.168.10.142:3005/',
    // MS_USER: 'http://192.168.10.142:5001/',
    // MS_DISCUSSION: 'http://192.168.10.142:5011/',
    // MS_DMS: 'http://192.168.10.142:5099/',
    // MS_NOTE: 'http://192.168.10.142:5062/',
    // MS_TASK: 'http://192.168.10.142:5004/',
    // MS_NOTIFICATION: 'http://192.168.10.142:5014/',

};